import React from 'react';
import './index.scss';
import { Icon } from 'semantic-ui-react';
import ButtonLink from 'dyl-components/atoms/ButtonLink';

const OfficeViewLeftPanel = ({ pages = [], selectedTab, setSelectedTab }) => {
    const onChangeTab = (id, onChange) => {
        onChange();
        setSelectedTab(id);
    }

    return (
       <div className='OfficeViewLeftPanel'>
            {pages.map(({ id, icon, onChange = () => {} }) => {
                const active = selectedTab === id;
                return (
                    <ButtonLink onClick={() => onChangeTab(id, onChange)} >
                        <Icon className={`OfficeViewLeftPanel__icon${active ? "--active" : ""} ${icon}`} size={"large"} />
                    </ButtonLink>
                )
            })}
       </div>
    )
};

export default OfficeViewLeftPanel;