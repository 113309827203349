import React from 'react'
import { Segment } from 'semantic-ui-react'

import './index.scss'

const InnerRuleContainer = ({ children, isLast }) => (
        <Segment
            className={`InnerRuleContainer__Wrapper ${isLast ? "InnerRuleContainer__Wrapper--last" : ""}`}>
            {children}
        </Segment>
)

export default InnerRuleContainer;
