import React from 'react';
import { Popup, Button } from 'semantic-ui-react';

import './index.scss';

export default class ControlledPopup extends React.Component {
    state = {
        open: false,
        interaction: 'hovered',
        action: ''
    }

    onOpen({ type }, _) {
        this.setState({
            open: true,
            action: type,
            interaction: type === 'mouseenter' ? 'hovered' : 'clicked'
        });
    }

    onClose({ type }, _) {
        const { action } = this.state;
        if (action === 'mouseenter' && type === 'click') {
            this.onOpen({ type }, _);
        }
        if ((action === 'click' && type === 'click') || (action === 'mouseenter' && type === 'mouseleave')) {
            this.setState({
                open: false
            })
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    onUnmount() {
        this.setState({
            open: false
        });
    }

    onConfirm() {
        this.setState({ open: false });
        if (this.props.onConfirm) {
            this.props.onConfirm();
        }
    }

    render() {
        const on = ['click'];
        if (this.props.content) {
            on.push('hover');
        }
        return (
            <Popup
                trigger={this.props.trigger}
                on={on}
                open={this.state.open}
                onOpen={this.onOpen.bind(this)}
                onClose={this.onClose.bind(this)}
                onUnmount={this.onUnmount.bind(this)}
                inverted={this.props.content && this.state.interaction === 'hovered'}
                position={this.props.position || 'top left'}
                hideOnScroll
                disabled = {this.props.disabled || false}
            >
                {this.state.interaction === 'hovered' &&
                    <Popup.Content>{this.props.content}</Popup.Content>
                }
                {
                    this.state.interaction === 'clicked' &&
                    <React.Fragment>
                        <Popup.Header>{this.props.header || 'Are you sure?'}</Popup.Header>
                        <Popup.Content className='Popup__Selection'>
                            <Button inverted onClick={this.onClose.bind(this)} icon='times' className='Popup__cancel' />
                            <Button inverted onClick={this.onConfirm.bind(this)} icon='check' className='Popup__confirm' />
                        </Popup.Content>
                    </React.Fragment>
                }
            </Popup>
        )
    }
}
