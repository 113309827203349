import React from 'react';
import { Icon } from 'semantic-ui-react';

const StatusTag = ({ tag }) => {
    let color = '';
    switch (tag) {
      case 'active':
        color = 'green';
        break;
      case 'idle':
        color = 'pink';
        break;
      default:
        break;
    }
    return <Icon name={color !== '' ? 'circle' : 'circle outline'} color={color} />;
};

export default StatusTag;
