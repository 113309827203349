import React from 'react';
import { Grid, Loader, Transition } from 'semantic-ui-react';
import FormTab from './subcomponents/FormTab';
import TimelineTab from './subcomponents/TimelineTab';

import './index.scss';

const TimelineTabForm = ({
    color = 'blue',
    timelineHeader,
    timelineSubHeader,
    timelineBody,
    timelineFooter,
    formHeader,
    formBody,
    formFooter,
    loading,
    maximizable,
    isMaximized = false,
    setIsMaximized = () => { },
    timelineSectionWidth = 8
}) => (
    <Grid>
        <Grid.Row className='TimelineTab__header' columns='equal'>
            <Grid.Column verticalAlign='middle'>
                <div className='TimelineTab__header-text'>
                    {timelineHeader}
                </div>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row className='TimelineTab__body' columns={'equal'}>
            {maximizable ? (
                <Transition.Group duration={200} animation={'slide right'}>
                    {!isMaximized && <Grid.Column width={timelineSectionWidth}>
                        <Loader active={loading} />
                        <TimelineTab
                            color={color}
                            timelineSubHeader={timelineSubHeader}
                            timelineBody={timelineBody}
                            timelineFooter={timelineFooter}
                        />
                    </Grid.Column>}
                    <Grid.Column>
                        <FormTab
                            color={color}
                            formHeader={formHeader}
                            formBody={formBody}
                            formFooter={formFooter}
                            isMaximized={isMaximized}
                            onMaximize={() => {
                                setIsMaximized(!isMaximized);
                            }}
                            maximizable
                        />
                    </Grid.Column>
                </Transition.Group>
            ) : (
                <>
                    <Grid.Column width={timelineSectionWidth}>
                        <Loader active={loading} />
                        <TimelineTab
                            color={color}
                            timelineSubHeader={timelineSubHeader}
                            timelineBody={timelineBody}
                            timelineFooter={timelineFooter}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <FormTab
                            color={color}
                            formHeader={formHeader}
                            formBody={formBody}
                            formFooter={formFooter}
                            isMaximized={isMaximized}
                        />
                    </Grid.Column>
                </>
            )}
        </Grid.Row>
    </Grid>
);

export { TimelineTabForm };
