import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { ButtonLink, LocationsForm } from 'dyl-components';

const AddLocationsSection = ({
    locations,
    onChangeArray,
    onRemoveFromArray,
    onToggleMain,
    onCloseAddLocationsSection,
    onAddLocations,
    onAddToArray,
    isCreating,
    dataTabLink,
    location_type
}) => (

    <div>
        <LocationsForm
            location_type={location_type}
            locations={locations}
            onChange={onChangeArray}
            onRemove={onRemoveFromArray}
            onToggleMain={onToggleMain}
            onCreateMode={true}
            DataTabLink={dataTabLink}
            AddLocationButton={
                <ButtonLink className='AddLocationsSection__buttonLinks' onClick={() => onAddToArray('location', {
                    additional_street: '',
                    city: '',
                    state: 'OH',
                    zip: '',
                    label: '',
                    street: '',
                    main: false
                })}>
                    <Icon name='plus circle' /> <span style={{ fontSize: 10 }}> Add additional {location_type} location </span>
                </ButtonLink>
            }
        />
        <div style={{ paddingLeft: 45 }}>
            {!isCreating && (
                <Button basic color='red' onClick={onCloseAddLocationsSection}>
                    Cancel
                </Button>
            )}
            <Button primary onClick={onAddLocations} loading={isCreating}>
                Add
            </Button>
        </div>
    </div>
);

export default AddLocationsSection;
