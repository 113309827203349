import React from 'react';
import {Icon} from 'semantic-ui-react';
import './index.scss';
import DYLAvatar from '../UserAvatar/DYLAvatar';


const ContactCard = ({userInfo, toNavigate}) => {
    return (
        <div className='contactCard__container'>
            <div className='contactCard__header'>
                <DYLAvatar 
                name={userInfo.first_name + " " + userInfo.last_name} 
                disabled
                size='45px'/>
                <div> 
                    <div className='contactCard__header__name'>{userInfo.first_name + " " + userInfo.last_name} </div>
                    <a className='contactCard__header__link' onClick={() => {toNavigate ? toNavigate() : console.log("not defined")}} >View full profile</a>
                </div>
            </div>

            {/* TODO: Add actions */}
            <div className='contactCard__actionBar'> 
                <hr className='contactCard__actionBar__divider'/>
                <div className='contactCard__actionBar__icons'>
                    <div className='contactCard__roundContainer'>
                        <Icon name="phone" style={{marginLeft: "3px"}} className='contactCard__iconGrey'/>
                    </div>

                    <div className='contactCard__roundContainer'>
                        <Icon name="comment" style={{marginLeft: "3px"}} className='contactCard__iconGrey'/>
                    </div>

                    <div className='contactCard__roundContainer'>
                        <Icon name="envelope" style={{marginLeft: "3px"}} className='contactCard__iconGrey'/>
                    </div>
                </div>
            </div>

            <div className='contactCard__contactInfo__container'>
                <div className='contactCard__contactInfo__title'> Contact Info </div> 
                { userInfo.phone ? <div className='contactCard__contactInfo__text'>
                    <Icon name="phone" size="small" style={{marginRight: "10px"}} className='contactCard__iconGrey'/>
                    {userInfo.phone}
                </div> : null}
                <div className='contactCard__contactInfo__text'>
                    <Icon name="envelope" size="small"  style={{marginRight: "10px"}}className='contactCard__iconGrey'/>
                    <div className='contactCard__contactInfo__text__email'>{userInfo.email}</div>
                </div>
            </div>
        </div>
    )
}
export default ContactCard;