import React from 'react';
import { Icon, Input } from 'semantic-ui-react';

import './index.scss';

const Editable = ({
    value,
    isReadLoading = false,
    isEditing,
    onEdit,
    children,
    submitDisabled,
    onConfirmEdit,
    onCancelEdit,

    size,
    underline
}) => (
    !isEditing ? <Input {...size ? { size } : {}} value={value} readOnly className={`Editable${underline ? ' Editable--underlined' : ''}`} onClick={onEdit} loading={isReadLoading} /> : (
        <React.Fragment>
            {children}
            <Icon style={{ marginLeft: '1em', display: 'inline-block' }} {...size ? { size } : {}} className='fas fa-times' color='red' link onClick={onCancelEdit} />
            <Icon
                {...size ? { size } : {}}
                style={{ display: 'inline-block' }}
                disabled={submitDisabled}
                className='fas fa-check'
                color='blue'
                link
                onClick={onConfirmEdit}
            />
        </React.Fragment>
    )
);

export default Editable;
