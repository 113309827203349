import React from 'react';

import Avatar from 'react-avatar';
import { Popup, List, Icon } from 'semantic-ui-react';

import './index.scss';

const getClassName = (index, total) => {
    const baseClassName = "AvatarGroup__avatar";
    if (index === 0 && total > 1) return `${baseClassName}__first`;         // first item
    if (total - index === 1 && total > 1) return `${baseClassName}__last`;  // last item
    return baseClassName;                                                   // unique and middle items
};

const AvatarList = ({ persons, onClick }) => {
    const onPersonClick = onClick || (() => {});
    return (
        <List>
            {
                persons.map((person) => (
                    <List.Item key={person.id}>
                        <List.Icon verticalAlign='middle' style={{ cursor: `${onClick ? 'pointer' : 'auto'}`}} >
                            <Avatar
                                round={true}
                                name={`${person.first_name} ${person.last_name}`}
                                email={person.email}
                                size={'3em'}
                                maxInitials={3}
                                title={" "}
                                onClick={() => onPersonClick(person)}
                            />
                        </List.Icon>
                        <List.Content>
                            <List.Header>
                                <span>{person.first_name} {person.last_name}</span>
                            </List.Header>
                            <List.Description>
                                <Icon name="mail outline" /><a href={`mailto:${person.email}`}>{person.email}</a>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                ))
            }
        </List>
    )
}

export default function AvatarGroup({ persons, limit = 3, size = "2em", onClick = null }) {
    const not_displaying = persons.length - limit;            // we are showing <limit> number of items
    const newLimit = not_displaying > 0 ? limit - 1 : limit;  // that includes the +more element at the end
    const total = persons.length;
    const onPersonClick = onClick || (() => {});

    return (
        <div className={"AvatarGroup"} style={{ "--size": `${size}`, "--limit": `${limit}` }}>
            {persons.slice(0, newLimit).map((person, index) => (
                <Popup
                    on="hover"
                    basic
                    position="bottom"
                    trigger={
                        <a style={{ zIndex: `${total - index}`, cursor: `${onClick ? 'pointer' : 'auto'}` }}>
                            <Avatar
                                className={getClassName(index, total)}
                                round={true}
                                name={`${person.first_name} ${person.last_name}`}
                                email={person.email}
                                size={size}
                                maxInitials={3}
                                title={" "}
                                onClick={()=>onPersonClick(person)}
                            />
                        </a>
                    }
                    content={
                        <AvatarList
                            persons={[person]}
                            onClick={onClick}
                        />
                    }
                />
            ))}
            {not_displaying > 0 && (
                <Popup
                    on="click"
                    basic
                    position="bottom"
                    trigger={
                        <a style={{ zIndex: `${total + 1}`, cursor: 'pointer' }}>
                            <Avatar
                                className={"AvatarGroup__avatar__last"}
                                value={`+${not_displaying + 1}`}
                                round={true}
                                size={size}
                                maxInitials={3}
                                title={" "}
                            />
                        </a>
                    }
                    content={
                        <AvatarList
                            persons={persons.slice(newLimit)}
                            onClick={onClick}
                        />
                    }
                />
            )}
        </div>
    );
}
