import React from 'react';
import { Button } from 'semantic-ui-react';

import './index.scss';

const StyledButton = props => (
    <Button 
        {...props} 
        className={`Button 
            ${!props.color && props.status ? `Button--${props.status}` : ''}
            ${!props.color && !props.status ? 'Button--default' : ''}
            ${props.color ? `Button--${props.color}` : ''}
        `} 
    />
)

StyledButton.Group = props => (
    <Button.Group {...props} />
)

export default StyledButton;