import React from 'react';
import { Button } from 'semantic-ui-react';

import './index.scss';

const ShortcodeButton = ({ disabled, onAddShortcode, children }) => (
    <Button className='ShortcodeButton' size='mini' color='blue' disabled={disabled} onClick={onAddShortcode}>
        {children}
    </Button>
)

export default ShortcodeButton;
