import React from 'react';
import './index.scss';

const TwoLinedContent = ({ firstLine, secondLine, className = '' }) => (
    <span className={`TwoLinedContent ${className}`}>
        <span className='TwoLinedContent__line' >
            {firstLine}
        </span>
        <span className='TwoLinedContent__line'>
            {secondLine}
        </span>
    </span>
)

export default TwoLinedContent
