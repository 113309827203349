import React from "react";
import { Icon } from "semantic-ui-react";

import "./index.scss";

export const MessagingIcon = ({
    icon,
    onClick,
    size,
    corner,
    className = "",
    ...otherProps
}) => {
    return typeof icon === "string" ? (
        <Icon
            link
            name={icon}
            size={size}
            {...otherProps}
            className={`MessagingIcon ${className}`}
            onClick={onClick}
            corner={corner}
        />
    ) : (
        icon
    );
};

export const StyledIcon = props => (
    <Icon
        {...props}
        className={`Icon ${props.className} ${
            props.color ? `Icon--${props.color}` : ""
        }`}
    />
);

export const MenuIcon = ({ name }) => {
    let propName;

    if (name === "call") {
        propName = "call";
    } else if (name === "text") {
        propName = "conversation";
    } else if (name === "email") {
        propName = "mail";
    } else if (name === "notes") {
        propName = "sticky note outline";
    } else if (name === "fax") {
        propName = "fax";
    } else if (name === "appointment") {
        propName = "calendar check outline";
    } else if (name === "task") {
        propName = "tasks";
    } else if (name === "upload") {
        propName = "cloud upload";
    } else if (name === "integration") {
        propName = "cubes";
    } else if (name === "admin") {
        propName = "cog"
    }

    return <Icon name={propName} className={`Icon Icon--${name}`} />;
};
