import React from 'react';
import { Accordion, Header, Icon, Grid } from 'semantic-ui-react';
import './index.scss';

const TabAccordion = ({ header = '', items = [], active, onChangeTab }) => {
    return (
        <React.Fragment>
            <Header as='h2'>{header}</Header>
            <Accordion className='TabAccordion'>
                {items.map(({ title, description, content }, index) => (
                    <React.Fragment>
                        <Accordion.Title
                            active={active === index}
                            index={index}
                            onClick={onChangeTab}
                        >
                            <Grid>
                                <Grid.Row columns='equal'>
                                    <Grid.Column style={{ padding: 20 }}>
                                        <div>
                                            <Header as='h3' className='TabAccordion__title'>
                                                {title}
                                            </Header>
                                        </div>
                                        <div>
                                            <span className='TabAccordion__description'>
                                                {description}
                                            </span>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={1}>
                                        <Icon name='dropdown' className='TabAccordion__dropdown' />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Accordion.Title>
                        <Accordion.Content active={active === index}>
                            {content}
                        </Accordion.Content>
                    </React.Fragment>
                ))}
            </Accordion>
        </React.Fragment>
    )
};

export default TabAccordion;
