import React from 'react';
import { Header, Form } from 'semantic-ui-react';

import './index.scss';


const BlueCheckBox = ({ topLabel, ...props }) => (
    <div className='BlueCheckBox'>
        { topLabel &&
            <Header as='h5' className={`LabelHeader${ props.disabled ? '--disabled' : ''}`}>
                { topLabel } 
            </Header>
        }
        <Form.Checkbox
            {...props}
        />
    </div>
);


const BlueToggleSwitch = ({ ...props }) => (
    <Form.Checkbox
        { ...props }
        toggle
        className='BlueToggleSwitch'
    />
);

export { BlueCheckBox, BlueToggleSwitch}