import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { FieldValue, ControlledPopup } from 'dyl-components';

const DeletableElement = ({ field, value, isBeingDeleted, onDelete, deleteable = true }) => (
    <Grid columns='equal' verticalAlign='middle'>
        <Grid.Column width={13}><FieldValue field={field} value={value} /></Grid.Column>
        <Grid.Column width={1}>
            {deleteable && (
                <ControlledPopup
                    trigger={<Icon color='red' link loading={isBeingDeleted} basic name='times' />}
                    onConfirm={onDelete}
                    content='Delete'
                />
            )}
        </Grid.Column>
    </Grid>
)

export default DeletableElement;
