import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import './index.scss';

const TimelineItem = ({ date, time, hasMarkers, markerColor, itemComponent, icon }) => (
    <Grid.Row style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign='middle' columns='equal'>
        <Grid.Column width={4}>
            <div><strong>{date}</strong></div>
            <div><small>{time}</small></div>
        </Grid.Column>
        {hasMarkers && (<Grid.Column width={1} className='Timeline__marker-line'>
            {icon ? (
                <div className='Timeline__iconContainer' style={{ borderColor: markerColor }} >
                    {icon}
                </div>
            ) : (
                <div className='Timeline__marker' style={{ borderColor: markerColor }} />
            )}
        </Grid.Column>)}
        <Grid.Column style={{ paddingLeft: 0, paddingRight: 5 }}>
            {itemComponent}
        </Grid.Column>
    </Grid.Row>
)

const Timeline = ({ items = [{ date: 'DATE/TIME', markerColor: 'grey', itemComponent: <React.Fragment /> }], hasMarkers = true, segmented = false, className = "", icon }) => (
    <Grid className={`Timeline ${className}`}>
        {items.map(({ date, time, markerColor, itemComponent, highlighted }, index) => {
            const timelineItem = <TimelineItem {...{ index, date, time, hasMarkers, markerColor, itemComponent, icon }} />;
            return (
                segmented ? (
                    <Grid.Row key={index} style={{ paddingTop: 5, paddingBottom: 5 }}>
                        <Grid.Column>
                            <Segment className={`TimelineItem ${highlighted && 'TimelineItem--highlighted'}`} padded>
                                <Grid>
                                    {timelineItem}
                                </Grid>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                ) : (
                        timelineItem
                    )
            )
        })}
    </Grid>
);

export default Timeline;
