import React from 'react';
import { Grid } from 'semantic-ui-react';

import './index.scss';

const LeftPaddedColumn = ({children, ...otherProps}) => (
    <Grid.Column {...otherProps} className="LeftPaddedColumn">
        {children}  
    </Grid.Column>
);

export default LeftPaddedColumn;
