import React from 'react'
import { Tab, Segment } from 'semantic-ui-react';
import './index.scss';

const Tabs = ({
    activeTab,
    onChangeTab,
    panes,
    isReading
}) => {
    const panesToDisplay = panes.slice(0);
    
    const activeIndex = panesToDisplay.findIndex(({ menuItem }) => {
        let tab;
        if(typeof menuItem === 'string'){
            tab = menuItem
        }else{
            tab = menuItem.key;
        }
        return (tab.toLowerCase() === activeTab.toLowerCase())
    });

    return (
        <Segment loading={isReading}>
            <Tab
                onTabChange={(_, { activeIndex }) => { onChangeTab(panesToDisplay[activeIndex].menuItem) }}
                activeIndex={activeIndex}
                menu={{ color: 'blue', secondary: true, pointing: true }}
                panes={panesToDisplay}
                className='AccountTabs'
            />
        </Segment>
    )
}

export default Tabs;
