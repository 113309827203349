import React, { useState } from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';
import { Table, TimestampFilters, Icon } from 'dyl-components';

const SortActions = ({ direction = false, onToggleSort = () => { } }) => {
    return (
        <div
            onClick={onToggleSort}
            style={{ display: "inline-flex", cursor: "pointer" }}
        >
            <Icon
                name="fa-solid fa-arrow-down-long"
                style={{
                    marginRight: -7,
                    opacity: !direction ? "100%" : "50%",
                }}
            />
            <Icon
                name="fa-solid fa-arrow-up-long"
                style={{ opacity: direction ? "100%" : "50%" }}
            />
        </div>
    );
};

const GroupedTimelineHeader = ({ useDateFunctions, useRangeCalendar, onToggleTimeUnit, getTimeData, sortDirection, onToggleSort, children }) => {
    const [areTimeUnitOptionsOpen, setTimeUnitOptionsOpen] = useState(false);

    if (!useDateFunctions) useRangeCalendar = {};
    const {
        timestamp,
        setTimestamp,
        isCustomDateRangeOpen,
        setCustomDateRangeOpen,
        dateRange,
        setDateRange,
    } = useRangeCalendar;

    return (
        <Table.Row>
            <SemanticTable.HeaderCell width={2}>
                <span style={{ marginRight: 5 }}>Created</span>
                {useDateFunctions && (
                    <>
                        <TimestampFilters
                            timestamp={timestamp}
                            onToggleTimeUnit={(timestamp) => {
                                onToggleTimeUnit(timestamp, setTimestamp, setTimeUnitOptionsOpen);
                            }}
                            dateRange={dateRange}
                            onDateRangeChange={(_, { value }) => {
                                setDateRange(value);
                            }}
                            getData={() => {
                                getTimeData(setCustomDateRangeOpen, dateRange);
                            }}
                            areTimeUnitOptionsOpen={areTimeUnitOptionsOpen}
                            onOpenTimeUnitOptions={() => {
                                setTimeUnitOptionsOpen(true);
                            }}
                            onCloseTimeUnitOptions={() => {
                                setTimeUnitOptionsOpen(false);
                            }}
                            isCustomDateRangeOpen={isCustomDateRangeOpen}
                            onOpenCustomDateRange={() => {
                                setCustomDateRangeOpen(true);
                            }}
                            onCloseCustomDateRange={() => {
                                setCustomDateRangeOpen(false);
                            }}
                        />
                        <SortActions
                            direction={sortDirection}
                            onToggleSort={() => onToggleSort()}
                        />
                    </>
                )}
            </SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell width={1}></SemanticTable.HeaderCell>
            {children}
        </Table.Row>
    );
}

export default GroupedTimelineHeader;
