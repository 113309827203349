import React from 'react';

import './index.scss';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import { PhoneUtil } from 'utils';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClippedContent } from 'dyl-components/atoms/ClippedContent';
import EmptyListPlaceholder from '../EmptyListPlaceholder';
import PHONE_ICON from 'dyl-components/utils/DateTimeUtils/PHONE_ICON';

const ACCOUNT_ICONS = {
    household: "fa-solid fa-house-chimney",
    business: "fa-solid fa-building",
}

const OfficeViewSearchResults = ({ onPersonSelect }) => {
    const { contacts_searched, contacts_searched_count, isSearchingContacts } = useSelector((state) => state.contacts);

    return (
        <div className='OfficeViewResults__container'>
            <Segment>
                {contacts_searched_count > 0 ? (
                    <Grid>
                        {contacts_searched.map((element, idx) => {
                            const person = element.person;
                            const account = element.account;
                            const main_number = person ? person?.phone?.find((phone) => phone.main) : account?.phone?.find((phone) => phone.main);
                            return (
                                <Grid.Row
                                    verticalAlign='middle'
                                    className='OfficeViewResults_rowContainer'
                                    key={idx}
                                    columns='equal'
                                    stretched
                                    onClick={() => onPersonSelect(person ? person.id : account.id)}
                                >
                                    <Grid.Column>
                                        <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                                            <Icon className={`fa-solid ${person ? "fa-user" : account?.account_type === "household" ? "fa-house-chimney" : "fa-building"} OfficeViewResults__userIcon`} size={"large"} />
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <ClippedContent>
                                                    <strong>{person ? `${person.first_name} ${person.last_name}` : account.name}</strong>
                                                </ClippedContent>
                                                <ClippedContent>
                                                    <span className='OfficeViewResults__email'>
                                                        {person ? person.email : account.email}
                                                    </span>
                                                </ClippedContent>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column>
                                        {main_number && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon name={PHONE_ICON[main_number.phone_type]} className="OfficeViewResults__userIcon" size={"large"} />
                                                <ClippedContent>
                                                    <span>
                                                        {PhoneUtil.formatPhoneNumber(main_number.phone)}
                                                    </span>
                                                </ClippedContent>
                                            </div>
                                        )}
                                    </Grid.Column>
                                    <Grid.Column>
                                        {person && account && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon className={`${ACCOUNT_ICONS[account.account_type]} OfficeViewResults__userIcon`} size={"large"} />
                                                <ClippedContent>
                                                    <span>
                                                        {account.name}
                                                    </span>
                                                </ClippedContent>
                                            </div>
                                        )}
                                    </Grid.Column>
                                    <div style={{ display:'flex', alignItems: 'center' }}>
                                        <Link to={person ? `/contact/${person.id}` : `/account/${account.id}`} target='_blank' rel='noopener noreferrer' onClick={(event) => event.stopPropagation()}>
                                            <Icon className='fa-solid fa-arrow-up-right-from-square OfficeViewResults__linkIcon' size={"large"} />
                                        </Link>
                                    </div>
                                </Grid.Row>
                            )
                        })}
                    </Grid>
            ) : (
                isSearchingContacts ? (
                    <Segment basic loading />
                ) : (
                    <EmptyListPlaceholder
                        title={"No results found"}
                        subtitle={' '}
                    />
                )
            )}
            </Segment>
        </div>
    )
}

export default OfficeViewSearchResults;
