import React from 'react';
import { Segment } from 'semantic-ui-react';
import LeftNavBar from './subcomponents/LeftNavBar';
import { Messages } from './subcomponents/Messages';


import './index.scss';

/** TODO:  
 * 1. make side by side components resizable with debounce and throttle e.g. https://codepen.io/lestat319/pen/BaLevNj
 * 2. Add loading gif when mounting 
 * 
 */


/** Temp mock data until we get the chat hooked up */
import { dummyChannels, dummyDirectMessage, dummyGroupMessage, dummyChannelFeed, tagOptions } from './mockData';
export default class ChatRoom extends React.Component {

    render() {
        const approvedColors=['#2979FF',  '#FC6E51', '#FF9654', '#00BFA5', '#6200EZ', '#5CD1E6', '#2DC270', '#ED5565'];
        const rawChatData = {
            channelFeed: dummyChannelFeed,
            defaultAvatarColor: '#00bfa5',
            navInfo: {
                channels: dummyChannels,
                tagOptions: tagOptions,
                directMessage: dummyDirectMessage,
                groupMessages: dummyGroupMessage,
            },
            approvedColors: approvedColors
        };

        return (
            <Segment.Group horizontal className="Chat">
                <Segment className="Chat__LeftNavBar__Wrapper" >
                    <LeftNavBar info={rawChatData.navInfo}
                    approvedColors={approvedColors} />
                </Segment>
                <Segment className="Chat__ChatWindow__Wrapper">
                    <Messages messages={rawChatData}
                    approvedColors={approvedColors} />
                </Segment>
            </Segment.Group>
        )
    }
}



