import React from 'react';
import { Grid } from 'semantic-ui-react';

const GridListHeader = ({ children, basic = false, style = {} }) => (
    <Grid.Row columns='equal' style={{ marginBottom: '1em', backgroundColor: basic ? '' : 'rgba(230, 230, 230, 0.4)', ...style }} >
        {children}
    </Grid.Row>
);

export default GridListHeader;


