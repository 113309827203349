import React from 'react';
import { DividingHeader } from 'dyl-components';
import { Loader, Label, Icon } from 'semantic-ui-react';

const InterestsSection = ({ areInterestsShown, header, active, interests, onDelete }) => (
    <React.Fragment>
        <DividingHeader compact content={header} />
        {areInterestsShown &&
            <React.Fragment>
                <Loader active={active} />
                <div style={{ paddingBottom: 6 }}></div>
                {(interests || []).map(interest => (
                    <Label icon='tag' basic size='mini'> {interest}  <Icon name='delete' link onClick={() => {onDelete(interest)}} /> </Label>
                ))}
            </React.Fragment>
        }
    </React.Fragment>
);

export default InterestsSection;
