import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

import './index.scss';

const PageTitleCard = ({ title, children }) => (
    <Segment className="PageTitleCard">
        <Header className="PageTitleCard__header">
            <Header.Content>{title}</Header.Content> 
        </Header>
        {children}
    </Segment>
);

PageTitleCard.withPageTitle = (Page, { title, otherContents }) => class extends React.Component {
    render() {
        return (
            <React.Fragment>
                <PageTitleCard title={title}>
                    {otherContents}
                </PageTitleCard>
                <Page 
                    {...this.props}
                />
            </React.Fragment>
        )
    }
}

export default PageTitleCard;
