import React from 'react';
import { Modal, Button, STATUS_TYPES, DatesRangeInput } from 'dyl-components';

const CustomRangeDateModal = ({ open, onClose, onDateRangeChange, dateRange, getData, initialDate, minDate }) => (
    <Modal open={open} onClose={onClose}>
        <Modal.Header>
            Custom Range
        </Modal.Header>
        <Modal.Content>
            <DatesRangeInput
                name="dateRange"
                inline
                initialDate={initialDate}
                minDate={minDate}
                onChange={onDateRangeChange}
                value={dateRange}
            />
        </Modal.Content>
        <Modal.Actions>
            <Button status={STATUS_TYPES.DANGER} onClick={onClose}>Cancel</Button>
            <Button status={STATUS_TYPES.PRIMARY} onClick={getData}>Apply</Button>
        </Modal.Actions>
    </Modal>
)

export default CustomRangeDateModal;
