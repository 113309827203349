import React from 'react';
import './index.scss';

import { MessageBox } from 'react-chat-elements'

const ChatMessage = ({ position = 'right', type = 'text', text = '', data = {} }) => {
    return (
        <MessageBox
            className='ChatMessage'
            position={position}
            type={type}
            text={text}
            data={data}
        />
    )
}

export default ChatMessage;
