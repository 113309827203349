import React, { Children, cloneElement } from "react";
import { Menu } from "semantic-ui-react";

const SettingsFooter = ({
    leftOptions,
    rightOptions,
    className = "Webform__menu",
    maxElements = 3,
    style = {}
}) => {
    const rightArray = Children.toArray(rightOptions);
    const leftArray = Children.toArray(leftOptions);
    return (
        <Menu style={style} borderless className={className}>
            <Menu.Menu position="right" vertical={false}>
                {Children.map(leftArray, (child, index) => {
                    if (index <= maxElements - 1) {
                        const isLastElement = index === maxElements - 1 || index === leftArray.length - 1;
                        return (
                            <div className={`SettingsFooter__control${isLastElement && ' SettingsFooter__control--last-element'}`}>
                                <Menu.Item>
                                    {cloneElement(child)}
                                </Menu.Item>
                            </div>
                        )
                    }
                })}
                {Children.map(rightArray, (child, index) => {
                    if (index <= maxElements - 1) {
                        return <Menu.Item>{cloneElement(child)}</Menu.Item>;
                    }
                })}
            </Menu.Menu>
        </Menu>
    );
};

export default SettingsFooter;
