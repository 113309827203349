import React, { useState } from 'react';
import { Icon, Header } from 'semantic-ui-react';


import './index.scss';

const PhoneButton = ({ onClick, text, icon, iconColor, disabled, isClicked, ...otherProps}) => {
    return (
        <button {...otherProps} onClick={onClick} className={`PhoneButton${isClicked ? "--active" : ""}${iconColor === "orange" && isClicked ? "__orange": ""}`} disabled={disabled}>
            <div><Icon size="large" className={`${icon} color--${iconColor}`} /><Header className={`${isClicked ? "Header--active" : ""}${iconColor === "orange" && isClicked ? "__orange": ""}`} style={{fontWeight: 300, marginLeft: 10, marginTop: 0, display: 'inline-block', verticalAlign: 'bottom'}} as="h3">{text}</Header></div>
        </button>
    );
}

export default PhoneButton;
