import React from 'react';
import { Grid, GridColumn, Header, Form } from 'semantic-ui-react';
import { BlueToggleSwitch } from 'dyl-components';
import './index.scss';
import { Controller } from 'react-hook-form';

const OwnerRuleSettingsOption = ({ option, control }) => {
  return (
    <Grid>
      <GridColumn width='1'>
        <Controller
          name={option.fieldname}
          control={control}
          render={({ field: { value, name, onChange } }) => (
              <Form.Field
                  control={BlueToggleSwitch}
                  name={name}
                  checked={value}
                  onChange={(_, { checked }) => { onChange({ target: { name, value: checked } }); }}
              />
          )} 
        />
      </GridColumn>
      <GridColumn width='15'>
        <Header as='h3'>{option.title}</Header>
        <span>{option.description}</span>
      </GridColumn>
    </Grid>
  );
};

const OwnerRuleSettings = ({ options, control }) => {
  return (
    <React.Fragment>
      {options.map((option, index) => (
        <OwnerRuleSettingsOption
          key={index}
          option={option}
          control={control}
        />
      ))}
    </React.Fragment>
  );
};

export default OwnerRuleSettings;