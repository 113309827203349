import React from 'react';
import { Table as SemanticTable, Header, Segment, Pagination } from 'semantic-ui-react';
import { Table, TableLoader } from 'dyl-components';

import GroupedTimelineHeader from './subcomponents/header';
import TableTimelineGroup from './subcomponents/TableTimelineGroup';

import './index.scss';

const GroupedTimeLine = ({
    headers,
    isLoading,
    additionalColumnNumber,
    groups = [],
    useDateFunctions = true,
    useRangeCalendar,
    onToggleTimeUnit,
    getTimeData,
    sortDirection,
    onToggleSort,

    usePagination = false,
    recordsCount,
    recordsLimit = 25,
    activePage,
    onPageChange
}) => {
    return (
        <div className='GroupedTimelineComponent'>
            <Table striped={false}>
                <SemanticTable.Header className='Table__header--primary'>
                    <GroupedTimelineHeader
                        useDateFunctions={useDateFunctions}
                        useRangeCalendar={useRangeCalendar}
                        onToggleTimeUnit={onToggleTimeUnit}
                        getTimeData={getTimeData}
                        sortDirection={sortDirection}
                        onToggleSort={onToggleSort}
                    >
                        {headers}
                    </GroupedTimelineHeader>
                </SemanticTable.Header>
                <Table.Body>
                    {isLoading ?
                        <TableLoader isLoading colspan={additionalColumnNumber + 2} /> :
                        groups.length > 0 ?
                            groups.map(element => (
                                <TableTimelineGroup header={element.name} timeline={element.dateTimes} />
                            )) :
                            <Table.Row>
                                <Table.Cell colspan={additionalColumnNumber + 2}><Header as='h3'>No Results Found</Header></Table.Cell>
                            </Table.Row>
                    }
                </Table.Body>
            </Table>
            {(usePagination && recordsCount !== 0) && (
                <Segment className='GroupedTimelineComponent__Pagination' textAlign='right'>
                    <Pagination
                        boundaryRange={0}
                        activePage={activePage || 1}
                        ellipsisItem={null}
                        siblingRange={2}
                        totalPages={Math.ceil(recordsCount / recordsLimit)}
                        onPageChange={onPageChange}
                        disabled={isLoading}
                    />
                </Segment>
            )}
        </div>
    )
}

export default GroupedTimeLine;
