import React, { useState } from 'react';
import { Dropdown, Icon, Label, Menu, Popup } from 'semantic-ui-react';

import './index.scss';

const MultipleNestedDropdown = ({
    onChange,
    nested_options,
    loading,

    values,
    placeholder,
    onClickParent
}) => {
    const [open, setOpen] = useState(false);

    const [categoryBeingHovered, setCategoryBeingHovered] = useState(null);

    return (
        <Dropdown
            scrolling
            className='MultipleNestedDropdown'
            {...values?.length ? {
                text: values.map((value, idx) => {
                    return <Label key={idx}>{value} <Icon className='fas fa-times' link onClick={
                        (e) => {
                            onChange(e, { value: values.filter((selected) => selected !== value) })
                        }
                    }
                    />
                    </Label>
                })
            } : { text: placeholder }
            }
            value={values}
            onOpen={() => { setOpen(true) }}
            onClose={() => { setOpen(false); setCategoryBeingHovered(null) }}
            open={open}
            loading={loading}
            multiple
            icon={<Icon size='small' className='fa-sharp fa-solid fa-caret-down NestedDropdown__icon' />}
            closeOnBlur={false}
            onBlur={() => {setOpen(false); setCategoryBeingHovered(null)}}
        >
            <Dropdown.Menu>
                {nested_options.map(category => (
                    <Popup
                        className='MultipleNestedDropdown__child-options'
                        style={{ position: 'absolute', top: '-1em' }}
                        position='right center'
                        trigger={(
                            <Dropdown.Item onClick={e => {e.preventDefault()}} selected={categoryBeingHovered === category.key} key={category.key}>
                                <Dropdown
                                    simple
                                    fluid
                                    key={category.key}
                                    text={category.text}
                                    disabled={category.disabled}
                                    open={false}
                                    loading={category.loading} />
                            </Dropdown.Item>
                        )}
                        open={!category.disabled && categoryBeingHovered === category.key}
                        onOpen={() => { if (!category.disabled && onClickParent) { setCategoryBeingHovered(category.key); onClickParent(category.key); } }}
                        {...(category?.options?.length ? {
                            content: (
                                <Menu secondary borderless vertical>
                                    {(category.options || []).map(option => {
                                        return (
                                            <Menu.Item onClick={(e) => {
                                                e.stopPropagation();
                                                if (!option.disabled && !values.includes(option.value)) {
                                                    onChange(e, { value: [...values, option.value] });
                                                    setCategoryBeingHovered(null);
                                                    setOpen(false);
                                                }
                                            }} key={option.id} disabled={option.disabled}>
                                                {option.text}
                                            </Menu.Item>
                                        );
                                    })}
                                </Menu>
                            )
                        } : {})} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default MultipleNestedDropdown;
