import React from 'react';
import { Divider, Popup } from 'semantic-ui-react';

const DottedDivider = ({content}) => {

return (
        <Divider horizontal style={{ paddingBottom: '5em' }}>
            <Popup
                style={{ padding: 0 }}
                trigger={<Icon size="big" color='primary' className="fas fa-plus-circle" link />}
                content={content}
                position="bottom center"
                hoverable
            />
        </Divider>    
    );
}

export default DottedDivider;
