import React from 'react';
import { Radio } from 'semantic-ui-react';

import './index.scss';

const AndOrToggle = ({ options, value, onChange }) => (
    <React.Fragment>
        {options.map((option) => (
            <Radio
                className={`AndOrToggle__ToggleButton`}
                key={option.key}
                label={option.text}
                name="radioGroup"
                value={option.value}
                checked={value === option.value}
                onChange={() => onChange(option.value)}
            />
        ))}
    </React.Fragment>
)

export default AndOrToggle;
