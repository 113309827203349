import React from 'react';
import { Icon } from 'semantic-ui-react';

const DataVisibilityTogglerContent = ({ isMoreDataVisible }) => {
    let content = {}
    content.text = isMoreDataVisible ? 'Show less' : 'Show more';
    content.iconName = isMoreDataVisible ? 'caret down' : 'caret right';
    return (
        <React.Fragment>
            {content.text} <Icon name={content.iconName} />
        </React.Fragment>
    )
}

export default DataVisibilityTogglerContent;
