import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import './index.scss';

const CollapsibleContent = React.forwardRef(({ content, isCollapsed, onCollapse, onExpand, width = 80 }, ref) => (
    <div>
        <span ref={ref} className={`CollapsibleContent ${isCollapsed ? `CollapsibleContent--collapsed` : ''}`} style={{ width: isCollapsed ? content && content.length > 19 ? width : '100%' : width }}>{content}</span> {
            content && content.length > 19 && <Popup
                trigger={<Icon size='small' color='blue' name={`chevron ${isCollapsed ? 'right' : 'down'}`} onClick={isCollapsed ? onExpand : onCollapse} />}
                content={`Show ${isCollapsed ? 'More' : 'Less'}`}
                inverted
            />
        }
    </div>
));

export default class CollapsibleContentContainer extends React.Component {
    ref = React.createRef();

    state = {
        isCollapsed: true
    }

    onCollapse = () => {
        this.ref.current.scrollTop = 0;
        this.setState({ isCollapsed: true });
    }

    onExpand = () => {
        this.setState({ isCollapsed: false });
    }

    render() {
        return (
            <CollapsibleContent
                width={this.props.width}
                content={this.props.content}
                isCollapsed={this.state.isCollapsed}
                onCollapse={this.onCollapse}
                onExpand={this.onExpand}
                ref={this.ref}
            />
        )
    }
}
