import React from 'react';
import { PhoneButton } from 'dyl-components';
import {  Grid } from 'semantic-ui-react';

const OfficeViewActionButtons = ({ 
    hangup,
    hangupDisabled = false,
    vmDrop,
    vmDropDisabled = false,
    transfer,
    transferDisabled = false,
    hold,
    holdDisabled = false,
    mute,
    muteDisabled = false,
    inboundCall,
    actionButtons,
 }) => {
    return (
        <Grid className="OfficeViewActionButtons" columns="equal">
            <Grid.Row>
                <Grid.Column>
                    <PhoneButton text="Hang Up" icon="fa-solid fa-phone-hangup" iconColor="red" onClick={hangup} disabled={hangupDisabled} isClicked={actionButtons?.hangup} />
                </Grid.Column>
                {!inboundCall &&
                    <Grid.Column>
                        <PhoneButton text="VM Drop" icon="fa-solid fa-voicemail" iconColor="blue" onClick={vmDrop} disabled={vmDropDisabled} isClicked={actionButtons?.vmDrop} />
                    </Grid.Column>
            }
                {inboundCall &&
                    <Grid.Column>
                        <PhoneButton text="Transfer" icon="fa-solid fa-right-left" iconColor="blue" onClick={transfer} disabled={transferDisabled} isClicked={actionButtons?.transfer} />
                    </Grid.Column>
                }
            </Grid.Row>
            <Grid.Row>
                {!inboundCall &&
                    <Grid.Column>
                        <PhoneButton text="Transfer" icon="fa-solid fa-right-left" iconColor="blue" onClick={transfer} disabled={transferDisabled} isClicked={actionButtons?.transfer} />
                    </Grid.Column>
                }
                <Grid.Column>
                    <PhoneButton text={actionButtons?.hold ? "Resume" : "Hold"} icon={actionButtons?.hold ? "fa-solid fa-play" : "fa-solid fa-pause"} iconColor="orange" onClick={hold} disabled={holdDisabled} isClicked={actionButtons?.hold} />
                </Grid.Column>
                <Grid.Column>
                    <PhoneButton text={actionButtons?.mute ? "Unmute" : "Mute"} icon={actionButtons?.mute ? "fa-solid fa-microphone-slash" : "fa-solid fa-microphone"} iconColor="grey" onClick={mute} disabled={muteDisabled} isClicked={actionButtons?.mute} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default OfficeViewActionButtons;