import React from 'react';
import { Grid } from 'semantic-ui-react';
import { LeftPaddedColumn, EditableContent } from 'dyl-components';

const EditableField = ({ label, name, value, isEditMode, control, fixedWidth, ...otherProps }) => (
    <React.Fragment>
        <Grid.Column style={{width:fixedWidth}} width={3} as={LeftPaddedColumn}>
            {label}
        </Grid.Column>
        <Grid.Column>
            <EditableContent name={name} value={value} {...otherProps} isEditMode={isEditMode} control={control} />
        </Grid.Column>
    </React.Fragment>
)

export default EditableField;
