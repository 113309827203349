import React from 'react';
import { Card } from 'semantic-ui-react';
import './index.scss';

const CardDataItem = ({ children }) => {
    return (
        <div className='CardDataItem'>
            {children}
        </div>
    )
}

const CardData = ({ children, active = false, onClick = null }) => {
    return (
        <Card onClick={active ? () => { } : onClick} className={`CardData CardData${active ? '--active' : ''} CardData${onClick && !active ? '--clickable' : ''} `}>
            {children}
        </Card>
    )
}

export { CardData, CardDataItem };
