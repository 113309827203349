import React from 'react';
import { Icon } from 'semantic-ui-react';
import './index.scss';

const Step = ({ children, completed, title, disabled, active, size }) => (
    <div className={`
        Step${completed ? ' Step--completed' : ''}
        ${disabled ? ' Step--disabled' : ''}
        ${active ? ' Step--active' : ''}
    `}>
        <div className={`Step__circle`}>
            {completed ? <Icon name='check' /> : children}
        </div>
        <div className='Step__title'>
            <span>{title}</span>
        </div>
    </div>
);

Step.Group = ({ children, horizontal, size }) => (
    <div className={`${horizontal ? 'StepGroup--horizontal' : 'StepGroup'}${size ? ` StepGroup--${size}` : ''}`}>
        {children}
    </div>
)

export default Step;
