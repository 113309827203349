export default {
    PRIMARY: 'primary',
    DEFAULT: 'default',
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger',
    ERROR: 'error',
    DISABLED: 'disabled',
    INVERSE: 'inverse',
    INACTIVE: 'inactive'
}
