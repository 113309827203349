
import React from 'react';
import { Accordion, Grid, GridColumn, Header, Segment, SegmentGroup, Icon, Menu } from 'semantic-ui-react';

import { CheckboxControlSettings, BlueToggleSwitch } from 'dyl-components';

import './index.scss';

const sortOrder = (inputArray, orderList, sortKey) => {
    inputArray.sort( (a, b) => {
      let A = a[sortKey], B = b[sortKey]; 
       return ((orderList.indexOf(A) > orderList.indexOf(B))) ? 1 : -1      
    });
    
    return inputArray;
  };

const defaultPermissionOrder = {
    "read" : "View",
    "assign": "Assign",
    "create" : "Add",
    "update": "Edit",
    "delete": "Delete"
};

const permissionsOrder = Object.values(defaultPermissionOrder) //["View" , "Assign", "Add", "Edit", "Delete"];

const PanelTitle = ({title, description, idx, toggleAll, allSelected, viewOnly}) => {

    const toggleButton = (e, idx) =>{
        e.stopPropagation();
        toggleAll(idx)

    }

    return (
        <Grid>
            <GridColumn width='1' >
                <BlueToggleSwitch disabled={viewOnly} checked={allSelected[idx]} onClick={(e)=>{toggleButton(e, idx)}}/> 
            </GridColumn>
            <GridColumn width='15'>
                <Header as='h3'> {title || ''} </Header>
                {description || ''}
            </GridColumn>
        </Grid>
    )
}

const PanelContent = ({ permissionSets, countSelectedGroupBoxes, groupID, toggleAll, allSelected, viewOnly }) => {

    //load toggle check here ... update only it that ID cahnges.

    let panels = (permissionSets)?

        permissionSets.map((panel, idx) => {
            const defaultPermissions = []; 
            const outlierPermissions = [];
            panel.permissions.filter((option, idx) => {
                if(!permissionsOrder.includes(option.name)) {
                    outlierPermissions.push(panel.permissions[idx])
                } else {
                    defaultPermissions.push(panel.permissions[idx])
                }
            });
            const sortedDefault = sortOrder(defaultPermissions, permissionsOrder, 'name');
            outlierPermissions.sort((a, b) => (a.name > b.name) ? 1 : -1);
            const sortedPermissions = [...sortedDefault, ...outlierPermissions];

            return (
                <Grid key={idx}>
                <GridColumn width='1'> </GridColumn>
                <GridColumn width='3'>
                    <Header as='h4'>
                        {panel.category}
                        <Header.Subheader>
                            {panel.label}
                        </Header.Subheader>
                    </Header>
                </GridColumn>
                <GridColumn width='10'>
                    {<CheckboxControlSettings permissions={sortedPermissions} outlierPermissions={outlierPermissions} permissionOrder={defaultPermissionOrder} key={idx} countSelectedGroupBoxes={countSelectedGroupBoxes} groupID={groupID} toggleAll={toggleAll} allSelected={allSelected} disabled={viewOnly}/>}
                </GridColumn>
            </Grid>)
        })
        :
        ( <Grid key="idx">
                <GridColumn width='1'> </GridColumn>
                <GridColumn width='5'>
                    <Header.Subheader>
                        There are no permissions returned for this section
                    </Header.Subheader>
                </GridColumn>
                <GridColumn width='10'> </GridColumn>
            </Grid>
        )

    return panels;
}

const Panel = (permissions, toggleAll, countSelectedGroupBoxes, allSelected, viewOnly) => {

    

    let dataCheck = permissions[0]?.title || null;

    const panel = (dataCheck) ? 
        permissions.map((setting, idx) => {
            return(
                {
                    "key": idx,
                    "title": { "content": <PanelTitle idx={idx} title={setting.title} description={setting.description} toggleAll={toggleAll} setting={setting} allSelected={allSelected} viewOnly={viewOnly}/> },
                    "content": { "content": <PanelContent  groupID={idx} permissionSets={setting.permissionSets} countSelectedGroupBoxes={countSelectedGroupBoxes} toggleAll={toggleAll} allSelected={allSelected} viewOnly={viewOnly}/> }
                }
            )
        }) : dataCheck 

    return panel;
}

class ManageSettings extends React.Component {
    state = {
        permission_roles: this.props.permissionRoles,
        toggleAll: {},
        allSelected: {}
    }

    countSelectedGroupBoxes = (id,update=true) => {
       
        let checkedBoxes = 0, totalBoxes = 0;
        const testGroup =this.state.permission_roles[id]?.permissionSets || [] ;
        let allSelected = this.props.allSelected;

        if(testGroup.length > 0) {
            testGroup.map(items => {
                totalBoxes += items.permissions.length;
                items.permissions.map(item => {
                    if(item.checked) {
                        checkedBoxes++;
                    }
                });
            });
        }



        const results =  {checkedBoxes, totalBoxes};
        allSelected[id] = (checkedBoxes > 0);

        if(this.props.checkUpdateButton){
            this.props.checkUpdateButton(checkedBoxes);
        }
        if(update){this.setState({ allSelected });}
    }

    toggleAll = (id) => {
        let toggleState = this.state.toggleAll;
        toggleState[id] = (toggleState[id]) ? !toggleState[id] : true;
        const newPermissions = this.state.permission_roles;

        const updatedPremissions = newPermissions[id]?.permissionSets;
        if(updatedPremissions){
            updatedPremissions.map(permissionSet => {
                const { permissions } = permissionSet;
                permissions.map(permission =>{
                    permission.checked = toggleState[id]
                    if(!toggleState[id]){permission.disabled = false}
                })
            })
        }

        newPermissions[id].permissionSets = updatedPremissions;
        this.countSelectedGroupBoxes(id, false);
        this.setState({
            toggleAll : toggleState,
            permission_roles: newPermissions
        });

      
    }

    render() {
        const permissions = this.state.permission_roles || null;
        const { allSelected } = this.props;
        const viewOnly = this.props.disabled || false;
        const panels = Panel(permissions, this.toggleAll, this.countSelectedGroupBoxes, allSelected, viewOnly);

        return ( panels ?
            <Accordion
                className='PermissionSets'
                panels={panels}
                exclusive={false}
                fluid
                styled
            /> 
            :
            <Segment className="ManageSettings">
                    <Header as="h4" content="No Permissions were found "/>
            </Segment>
        );
    }

}

export default ManageSettings;
