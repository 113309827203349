import React from 'react';
import { DateTimeUtils, Table } from 'dyl-components';

const TableTimelineGroup = ({ header, timeline }) => {
    return (
        <React.Fragment>
            <Table.Row>
                <Table.HeaderCell className='GroupHeader' colSpan={7}>
                    {header}
                </Table.HeaderCell>
            </Table.Row>
            {
                timeline.map(({ ts, icon, columns = [] }, index, array) => {
                    const lineType = index === 0 ? (array.length - 1 === 0 ? '' : 'TimelineIconCell--first-element') : index === array.length - 1 ? 'TimelineIconCell--last-element' : 'TimelineIconCell--middle-element';

                    return (
                        <Table.Row>
                            <Table.Cell>
                                <div><b>{DateTimeUtils.formatEpoch(ts, DateTimeUtils.WORD_DATE_FORMAT)}</b></div>
                                <div>{DateTimeUtils.formatEpoch(ts, DateTimeUtils.TIME_FORMAT)}</div>
                            </Table.Cell>
                            <Table.Cell className={`TimelineIconCell ${lineType}`} verticalAlign='top'>
                                <div className="TimelineIconCell__TimelineIcon">
                                    {icon}
                                </div>
                            </Table.Cell>
                            {columns}
                        </Table.Row>
                    );
                })
            }
        </React.Fragment>
    )
}

export default TableTimelineGroup;
