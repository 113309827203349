import React from 'react';
import { TableWithHeader } from 'dyl-components';
import { Icon, Grid, List } from 'semantic-ui-react';
import { LeftPaddedColumn } from 'dyl-components';

import './index.scss';

const InstructionsPanel = ({ mainTitle, additionalInstructions, leftPaneTitle, leftPaneInstructions, rightPaneTitle, rightPaneInstructions }) => (
    <TableWithHeader 
        header={<span><Icon name='idea' /> {mainTitle}</span>}
        table={
            <Grid style={{margin: 0}}>
                {additionalInstructions && (
                    <Grid.Row as={LeftPaddedColumn} style={{ width: '100%' }}>
                        <Grid.Column>
                            {additionalInstructions}
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row columns='equal' className="InstructionsPanelGrid">
                    <Grid.Column>
                        <List ordered>
                            <List.Header as='h5'>{leftPaneTitle}</List.Header>
                            {leftPaneInstructions.map((instruction, index) => (
                                <List.Item key={index}>{instruction}</List.Item>
                            ))}
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <List ordered>
                            <List.Header as='h5'>{rightPaneTitle}</List.Header>
                            {rightPaneInstructions.map((instruction, index) => (
                                <List.Item key={index}>{instruction}</List.Item>
                            ))}
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        }
    />
);

export default InstructionsPanel;
