import React from 'react'
import { Segment } from 'semantic-ui-react'

import './index.scss'

const NumberedContainer = ({children, count=0}) => (
        <Segment className='NumberedRuleContainer__Wrapper'>
            <span className="NumberedRuleContainer__count">{count + 1}</span>
            {children}
        </Segment>
)

export default NumberedContainer;
