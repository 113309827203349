import React, { useState } from 'react';
import { Form, Icon, Header, Button, Divider, Ref } from 'semantic-ui-react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { ControlledPopup } from 'dyl-components';

import './index.scss';

const fieldRef = React.createRef();

const LabelInput = ({ labelId, name, index, control, value, placeholder, remove, editable, hasItems, checkDuplicate = () => { }, isExisting = false, isCheckingForDuplicate = false }) => (
    <Controller
        key={labelId}
        name={`${name}.${index}.value`}
        control={control}
        defaultValue={value}
        rules={{
            required: {
                value: hasItems,
                message: 'This field should not be empty'
            },
            maxLength: {
                value: 41,
                message: 'This field should contain at most 41 characters'
            },
            pattern: {
                value: /^[ -~]+$/,
                message: 'This field should not contain emojis'
            },
            validate: {
                no_excessive_whitespaces: (value) => {
                    return ((value === undefined || value.length === 0) && !hasItems) ||
                        (value.length !== 0 && !!value.trim()) ||
                        'This field cannot only contain white spaces'
                }
            }
        }}
        render={({ field, fieldState: { error } }) => (
            <Form.Group key={labelId}>
                <Form.Input
                    {...field}
                    key={labelId}
                    onBlur={() => { checkDuplicate(field.value, `${name}.${index}.value`, index, isExisting) }}
                    className='EventLabels__input'
                    placeholder={placeholder}
                    readOnly={isCheckingForDuplicate || !editable}
                    error={error && error.message && {
                        content: error.message,
                        pointing: 'below'
                    }}
                />
                <Form.Group>
                    {isExisting ? <ControlledPopup trigger={
                        <Form.Field>
                            <Icon
                                link
                                name='times'
                            />
                        </Form.Field>} onConfirm={remove} /> :
                        hasItems ?
                            <Form.Field>
                                <Icon
                                    link
                                    name={isCheckingForDuplicate ? 'spinner' : 'times'}
                                    loading={isCheckingForDuplicate}
                                    onClick={isCheckingForDuplicate ? () => { } : remove}
                                />
                            </Form.Field> :
                            isCheckingForDuplicate &&
                            <Form.Field>
                                <Icon
                                    link
                                    name={'spinner'}
                                    loading={true}
                                />
                            </Form.Field>

                    }
                </Form.Group>
            </Form.Group>
        )}
    />
);

const EventLabels = ({ items = [], header = '', size = 'small', addLabel = 'Add', name, placeholder = '', editable, onSave, onRemove, onCheckDuplicate, isLoading }) => {
    const { control, formState: { isValid, isDirty, errors }, watch, setError, reset, trigger, getValues, handleSubmit, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            [`existing_${name}`]: items,
            [`new_${name}`]: [{ value: '' }]
        }
    });

    const { fields: existingFields, remove: removeFromExisting, replace: replaceExisting } = useFieldArray({
        control,
        name: `existing_${name}`,
        keyName: 'labelId'
    });

    const { fields: newFields, append, remove, replace } = useFieldArray({
        control,
        name: `new_${name}`,
        keyName: 'labelId'
    });

    const watchNewFields = watch(`new_${name}`);
    const [fieldsBeingChecked, setFieldsBeingChecked] = useState([]);

    const onCheckLabelDuplicate = async (value, fieldName, fieldIndex, isFieldFromExisting) => {
        if (value && value.trim()) {
            setFieldsBeingChecked(arr => [...arr, fieldIndex]);

            //Check in the existing record
            if (onCheckDuplicate) {
                await onCheckDuplicate(value.replace(/\s+/g, ' ').trim()).then(() => {
                    setError(fieldName, { type: 'manual', message: 'This field has a duplicate in the existing record' });
                    setFieldsBeingChecked(arr => arr.filter(field => field !== fieldIndex));
                    return;
                }, () => { })
            }

            // Checking for duplicated fields within the list of items being added
            const values = isFieldFromExisting ? watchNewFields.map(({ value }) => value.toLowerCase()) :
                watchNewFields.filter((_, index) => index !== fieldIndex).map(({ value }) => value.toLowerCase());

            if (values.includes(value.replace(/\s+/g, ' ').toLowerCase().trim())) {
                setError(fieldName, { type: 'manual', message: 'This field has a duplicate in the list of items being added' });
            }
            setTimeout(() => {
                setFieldsBeingChecked(arr => arr.filter(field => field !== fieldIndex));
            }, 500)
        }
    }

    const onRemoveExistingLabel = (id, index) => {
        if (onRemove) {
            const tempNewFields = [...getValues()[`new_${name}`]];
            const tempExistingFields = [...getValues()[`existing_${name}`]];
            onRemove(id).then(() => {
                removeFromExisting(index);

                reset({
                    [`existing_${name}`]: items.filter(item => item.id !== id),
                    [`new_${name}`]: [{ value: '' }]
                });

                setValue(`existing_${name}`, tempExistingFields.filter(item => item.id !== id));
                replaceExisting(tempExistingFields.filter(item => item.id !== id));
                setValue(`new_${name}`, tempNewFields);
                replace(tempNewFields);

                if (isDirty) {
                    setTimeout(() => {
                        trigger();
                    }, 60)
                }

            }, () => { })
        }
    }


    const onSubmit = (data) => {
        if (onSave) {
            onSave([...data[`existing_${name}`], ...data[`new_${name}`]].filter(label => label.value));
        }
    }

    const hasExistingLabels = existingFields.length > 0;

    return (
        <Ref innerRef={fieldRef}>
            <Form className='EventLabels' size={size} loading={isLoading}>
                {hasExistingLabels && <Header>Existing {header}</Header>}
                {existingFields.map(({ labelId, id, value }, index) => (
                    <LabelInput labelId={labelId} name={`existing_${name}`} index={index} control={control}
                        value={value} placeholder={placeholder} remove={() => onRemoveExistingLabel(id, index)}
                        checkDuplicate={editable ? onCheckLabelDuplicate : () => { }} hasItems={true} isExisting={true} editable={editable} />
                ))}
                {hasExistingLabels && <Divider />}
                {<Header>Add New {header}</Header>}
                {newFields.map(({ labelId, value }, index) => (
                    <LabelInput labelId={`new_${labelId}`} name={`new_${name}`} index={index} control={control}
                        value={value} placeholder={placeholder} remove={() => remove(index)} hasItems={newFields.length !== 1}
                        checkDuplicate={onCheckLabelDuplicate} isCheckingForDuplicate={fieldsBeingChecked.includes(index)} editable={true}
                    />
                ))}
                <div className='EventLabels__addButton' onClick={() => { append({ value: '' }) }}>
                    <Icon name='plus' /> {addLabel}
                </div>
                <Button className='EventLabels__saveButton'
                    disabled={Object.keys(errors).length !== 0 || !isValid || !isDirty || fieldsBeingChecked.length > 0}
                    type='button' content='Save' primary onClick={handleSubmit(onSubmit)} />
            </Form>
        </Ref>
    )
};

export default EventLabels;
