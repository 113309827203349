import React from 'react';
import { Card, Icon, Popup, Segment } from "semantic-ui-react";
import { HoverControls, HTMLPreview, ControlledPopup } from "dyl-components";

import './index.scss';

const Template = ({
    previewContent,
    name,
    onFavorite,
    isFavorite,

    onPreview,
    onEdit,
    onCopy,
    onDelete,

    favoriteIcon = 'heart'
}) => (
    <Segment className='Template' basic>
        <Card
            className='Template__preview'
            image={(
                <HoverControls
                    controls={(
                        <div className="Template__controls">
                            <Icon onClick={onPreview} name='eye' link />
                            <Icon onClick={onEdit} name='pencil' link />
                            <Icon onClick={onCopy} name='fas fa-copy' link />
                            <ControlledPopup
                                trigger={<Icon name='fas fa-trash' link />}
                                onConfirm={onDelete}
                            />
                        </div>
                    )}
                >
                    <HTMLPreview
                        content={previewContent}
                    />
                </HoverControls>
            )}
        />
        <div className='Template__favorite'>
            <h5>
                <Icon
                    onClick={onFavorite}
                    name={!isFavorite ? `${favoriteIcon} outline` : `${favoriteIcon}`}
                    link color='blue'
                />
                <Popup
                    trigger={
                        <span>{name}</span>
                    }
                    inverted
                    position='top center'
                    content={name}
                    basic
                />
            </h5>
        </div>
    </Segment>
);

export default Template;
