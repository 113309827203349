import { DateTimeUtils } from "dyl-components";
import React, { useEffect, useState, useRef } from "react";
import {
    DateInput,
    TimeInput,
    DatesRangeInput,
} from "semantic-ui-calendar-react";
import { Popup, Segment, Input, Dropdown, Icon, Form, Header } from "semantic-ui-react";
import "./index.scss";

const generateTimeOptions = (interval, minTime) => {
    const time_options = [];
    for (let hour = 0; hour < 24; hour++) {
        const hourString = `${
            hour === 0 ? 12 : hour !== 12 ? hour % 12 : 12
        }`.padStart(2, "0");
        for (let multiplier = 0; multiplier * interval < 60; multiplier++) {
            const midday = hour < 12 ? "AM" : "PM";
            const time = `${hourString}:${`${multiplier * interval}`.padStart(
                2,
                0
            )} ${midday}`;
            time_options.push({ key: time, value: time, text: time });
        }
    }

    if (minTime) {
        let indexOfMinTime = time_options.findIndex(
            ({ value }) => value === minTime
        );
        return time_options.slice(indexOfMinTime);
    }

    return time_options;
};

const DYLDateInput = ({ ...props }) => {
    return (
        <DateInput
            {...props}
            minDate={props.minDate || null}
            maxDate={props.maxDate || null}
            popupPosition={props.popupPosition || "bottom center"}
            animation={"none"}
            dateFormat={props.dateFormat || DateTimeUtils.DATE_FORMAT}
            closable={props.closable || true}
        />
    );
};

const DYLTimeInput = ({ ...props }) => {
    return (
        <TimeInput
            {...props}
            popupPosition={props.popupPosition || "bottom center"}
            animation={"none"}
            timeFormat={props.timeFormat || "ampm"}
            closable={props.closable || true}
        />
    );
};

const DYLDatesRangeInput = ({ ...props }) => {
    return (
        <DatesRangeInput
            {...props}
            minDate={props.minDate || null}
            maxDate={props.maxDate || null}
            popupPosition={props.popupPosition || "bottom center"}
            dateFormat={props.dateFormat || DateTimeUtils.DATE_FORMAT}
            animation={"none"}
            closable={props.closable || true}
        />
    );
};


const MiniCalendar = ({children, value, onChange, markToday, dateFormat = DateTimeUtils.DATE_FORMAT}) => {
    const [currentMonth, setCurrentMonth] = useState();
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const getMonthAndYear = (text) => {
        const separatedYear = text.split(" ");
        const month = separatedYear[0].slice(0, 3);
        return `${month} ${separatedYear[1]}`;
    }

    const onMonthSpanMutation = (mutations) => {
        const mutationValue = mutations[0].target.data;
        setCurrentMonth(getMonthAndYear(mutationValue));
    }

    const observer = new MutationObserver(onMonthSpanMutation)
    const observerOptions = {
        characterData: true,
        subtree: true,
    }

    const handleLeftClick = () => {
        prevRef.current.click();
    }

    const handleRightClick = () => {
        nextRef.current.click();
    }

    useEffect(() => {
        const theadArray = Array.from(
            document.getElementsByClassName('suicr-day-view-header')
        )
        const thead = theadArray[0]
        const firstTr = thead.children[0];
        const monthSpan = firstTr.childNodes[1];

        const leftTh = firstTr.childNodes[0];
        const leftIcon = leftTh.childNodes[0];
        const rightTh = firstTr.childNodes[2];
        const rightIcon = rightTh.childNodes[0];

        prevRef.current = leftIcon;
        nextRef.current = rightIcon;

        const spanContent = monthSpan.firstChild.firstChild.nodeValue;
        setCurrentMonth(getMonthAndYear(spanContent))
        
        observer.observe(monthSpan, observerOptions);
        return () => observer.disconnect();
    }, [])

    return (
        <div className="MiniCalendar">
            <div className="MiniCalendar__HeaderContainer">
                <div className="MiniCalendar__MonthHeader">
                    <Header as={'h3'} className='MiniCalendar__Header'>{currentMonth}</Header>
                    <Icon name="chevron left" link onClick={handleLeftClick} />
                    <Icon name="chevron right" link onClick={handleRightClick}/>
                </div>
                <div className="MiniCalendar__Button">
                    {children}
                </div>
            </div>
            <DateInput
                name={"date"}
                value={value}
                onChange={onChange}
                dateFormat={dateFormat}
                marked={markToday ? DateTimeUtils.getCurrentDate() : null}
                inline
            />
        </div>
    )
}

const DropdownTimeInput = ({
    style,
    onChange,
    interval = "15",
    value,
    placeholder,
    name,
    error,
    label,
    minTime = null,
    onClick,
    onFocus
}) => (
    <Dropdown
        text={
            <div>
                <Icon className="fas fa-clock" style={{ color: "#7f8c8d" }} />{" "}
                {value || placeholder || "Select Time"}
            </div>
        }
        style={style}
        label={label}
        error={error}
        search
        onChange={onChange}
        name={name}
        value={value}
        selection
        options={generateTimeOptions(interval, minTime)}
        scrolling
        onClick={onClick}
        onFocus={onFocus}
        selectOnBlur={false}
    />
);

class DYLDateTimeInput extends React.Component {
    state = {
        isDateTimeInputOpen: false,
        isTimeInputOpen: this.props.isTimeInputOpen,
        counter: 0,
    };

    onOpenDateTimeInput = () => {
        this.setState({
            isDateTimeInputOpen: true,
        });
    };

    onCloseDateTimeInput = () => {
        if (!this.state.isTimeInputOpen) {
            this.setState({
                isDateTimeInputOpen: false,
                counter: 0,
            });
        }
    };

    onCloseDateTimeCheck = () => {
        if (this.state.isDateTimeInputOpen) {
            this.setState(
                (prevState) => ({
                    counter: prevState.counter + 1,
                }),
                () => {
                    if (this.state.counter === 2) {
                        this.setState({
                            isDateTimeInputOpen: false,
                            isTimeInputOpen: false,
                            counter: 0,
                        });
                    }
                }
            );
        }
    };

    onOpenTimeInput = () => {
        this.setState({
            isTimeInputOpen: true,
        });
    };

    onCloseTimeInput = () => {
        this.setState({
            isTimeInputOpen: false,
        });
    };

    render() {
        const {
            dateName,
            minDate = null,
            maxDate = null,
            date = "",
            timeName,
            time = "",
            onChange,
            timeTrigger,
            dateTimeTrigger,
            dateFormat = "DD MMM YYYY",
            style,
            offset = [0, 5],
            position = "bottom center",
            errorFlag = false,
            errorMessage = ""
        } = this.props;
        return (
            <Popup
                content={
                    <React.Fragment>
                        <DYLDateInput
                            inline
                            name={dateName}
                            value={date}
                            onChange={onChange}
                            dateFormat={dateFormat}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                        <Popup
                            trigger={
                                timeTrigger || (
                                    <Segment size="small">
                                        <Input
                                            transparent
                                            placeholder="Set Time"
                                            icon="clock"
                                            iconPosition="left"
                                            value={time}
                                            className="DYLDateTime__TimeInput"
                                        />
                                    </Segment>
                                )
                            }
                            content={
                                <DYLTimeInput
                                    inline
                                    iconPosition="left"
                                    placeholder="Set Time"
                                    name={timeName}
                                    value={time}
                                    onChange={(e, { name, value }) => {
                                        onChange(e, { name, value });
                                        this.onCloseDateTimeCheck();
                                    }}
                                />
                            }
                            on="click"
                            onOpen={this.onOpenTimeInput}
                            onClose={this.onCloseTimeInput}
                            open={this.state.isTimeInputOpen}
                            basic
                            position="right center"
                        />
                    </React.Fragment>
                }
                trigger={
                    dateTimeTrigger || (
                        <Form.Input
                            style={{
                                width: "100%"
                            }}
                            icon="calendar"
                            iconPosition="left"
                            placeholder="Set Date/Time"
                            value={
                                date === "" && time === ""
                                    ? ""
                                    : `${date} ${time}`
                            }
                            error={errorFlag && errorMessage}
                        />
                    )
                }
                on="click"
                basic
                open={this.state.isDateTimeInputOpen}
                onOpen={this.onOpenDateTimeInput}
                onClose={this.onCloseDateTimeInput}
                position={position}
                offset={offset}
                style={style}
            />
        );
    }
}

export {
    DYLDateInput as DateInput,
    DYLTimeInput as TimeInput,
    DYLDatesRangeInput as DatesRangeInput,
    DYLDateTimeInput as DateTimeInput,
    DropdownTimeInput,
    MiniCalendar
};
