import React from 'react';
import { Icon } from 'semantic-ui-react';
import './index.scss';

const getTemperatureClassName = (type) => {
    switch (type) {
        case 'cold':
            return `far fa-thermometer-empty temperature-${type}`;
        case 'warm':
            return `far fa-thermometer-quarter temperature-${type}`;
        case 'hot':
            return `far fa-thermometer-three-quarters temperature-${type}`;
        default:
            return 'far fa-thermometer-empty temperature-cold';
    }
}

const DYLTemperatureIcon = ({ ...props }) => {
    return (
        <Icon className={getTemperatureClassName(props.type)} {...props} />)
}

const getPriorityClassName = (type) => {
    switch (type) {
        case 'low':
            return `fas fa-circle priority-${type}`;
        case 'medium':
            return `fas fa-circle priority-${type}`;
        case 'high':
            return `fas fa-circle priority-${type}`;
        default:
            return 'fas fa-circle priority-low';
    }
}

const DYLPriorityIcon = ({ ...props }) => {
    return (
        <Icon className={getPriorityClassName(props.type)} {...props} />)
}


export { DYLTemperatureIcon as TemperatureIcon, DYLPriorityIcon as PriorityIcon }
