import React from 'react';

import './index.scss';

const OfficeViewContainer = ({
    children,
    header,
    leftPanel,
    rightPanel
}) => (
    <div className='OfficeViewContainer'>
        <div className='OfficeViewContainer__headerContainer'>
            {header}
        </div>
        <div className='OfficeViewContainer__content'>
            <div className='OfficeViewContainer__leftPanelContainer'>
                {leftPanel}
            </div>
            <div className='OfficeViewContainer__midContainer'>
                <div style={{ height: '100%' }}>
                    {children}
                </div>
            </div>
            <div className='OfficeViewContainer__rightPanelContainer'>
                {rightPanel}
            </div>
        </div>
    </div>
);

export default OfficeViewContainer;
