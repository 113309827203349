import React, { useState } from 'react';
import { Icon, Dropdown, Portal } from 'semantic-ui-react';
import './index.scss';

const EllipsisDropdownPortal = ({ children, reference, action }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isEllipsisOpen, setIsEllipsisOpen] = useState(false);

    return (
        <Dropdown
            {...(action ? { open: false } : {})}
            className='EllipsisHorizontal'
            open={false}
            onClick={() => {
                if (reference.current) {
                    const position = reference.current.getBoundingClientRect();
                    const scrollY = window.scrollY;
                    setPosition({ x: position.left - 60, y: position.top + scrollY });
                    setIsEllipsisOpen(!isEllipsisOpen)
                }
            }}
            onBlur={() => setIsEllipsisOpen(false)}
            button
            basic
            direction='left'
            icon={null}
            text={<Icon name='ellipsis horizontal' size='large' />}
        >
            <Portal open={true}>
                <Dropdown open={isEllipsisOpen} style={{ position: 'absolute', zIndex: 999, left: position.x, top: position.y, visibility: 'hidden' }} onClose={() => { setIsEllipsisOpen(false) }}>
                    <Dropdown.Menu onClick={event => { event.stopPropagation() }}>
                        {children}
                    </Dropdown.Menu>
                </Dropdown>
            </Portal>
        </Dropdown>
    )
}

export default EllipsisDropdownPortal;
