import React, { useState, useRef } from 'react';
import { Dropdown, Icon, Menu, Popup, Ref } from 'semantic-ui-react';

import './index.scss';

const ToggableDropdown = ({
    onChange,
    nested_options,
    loading,

    value,
    placeholder,
    onHoverParent,
    searchValue,
    resetSearchValue,
    onSearchChange
}) => {
    const [open, setOpen] = useState(false);
    const [categoryBeingHovered, setCategoryBeingHovered] = useState(null);
    const dropdownRef = useRef(null)

    const onRemoveFocus = () => {
        const childNodes = dropdownRef.current.childNodes;
        let inputNode;
        for (let i = 0; i < childNodes.length; i++) {
            if (childNodes[i].nodeName === 'INPUT') {
                inputNode = childNodes[i];
                break;
            }
        }
        inputNode.blur();
    }

    return (
        <Ref innerRef={dropdownRef}>
            <Dropdown
                scrolling
                className='ToggableDropdown'
                text={value ? (
                        <div><span style={{margin: 0}} className='ToggableDropdown__value'>{value.name}</span></div>
                    ) : (
                        <span className='NestedDropdown__placeholder'>{placeholder}</span>
                    )
                }
                onOpen={() => { setOpen(true) }}
                onClose={() => { setOpen(false); setCategoryBeingHovered(null) }}
                value={value}
                open={open}
                loading={loading}
                search
                icon={<Icon size='small' className='fa-sharp fa-solid fa-caret-down NestedDropdown__icon' />}
                closeOnBlur={true}
                onSearchChange={onSearchChange}
                ref={dropdownRef}
            >
                <Dropdown.Menu style={{maxHeight: 150, overflowY: 'auto'}}>
                    {nested_options.map(category => {
                        return (searchValue ? (
                            <Dropdown.Item
                                text={category.text}
                                value={category.value}
                                key={category.key}
                                disabled={false}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    resetSearchValue();
                                    onChange(e, { value: {value: category.value, name: category.parent ? `${category.parent}/${category.text}` : category.text} });
                                    setOpen(false);
                                    setCategoryBeingHovered(null);
                                    onRemoveFocus();
                                }}
                            />
                        ) : (
                            <Popup
                                className='ToggableDropdown__child-options'
                                style={{ position: 'absolute', top: '-1em', right: '-5em', maxHeight: 150 }}
                                position='right center'
                                trigger={(
                                    <Dropdown.Item 
                                        onMouseDown={e => {
                                            e.stopPropagation();
                                            onChange(e, { value: {value: category.value, name: category.text }});
                                            setCategoryBeingHovered(null);
                                            setOpen(false);
                                        }} 
                                        selected={categoryBeingHovered === category.key}
                                        key={category.key}
                                        disabled={false}
                                    >
                                        {category.hasOptions ? (
                                            <Dropdown
                                                simple
                                                fluid
                                                key={category.key}
                                                text={category.text}
                                                open={false}
                                                loading={category.loading} 
                                                disabled={false}
                                            /> 
                                        ) : category.text}
                                    </Dropdown.Item>
                                )}
                                open={!category.disabled && categoryBeingHovered === category.key}
                                onOpen={() => { 
                                    if (!category.disabled) {
                                        setCategoryBeingHovered(category.key);
                                    }
                                    if (onHoverParent) {
                                        onHoverParent(category.key);
                                    }
                                }}
                                {...(category?.options?.length ? {
                                    content: (
                                        <Menu secondary borderless vertical>
                                            {(category.options || []).map(option => {
                                                return (
                                                    <Menu.Item 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }} 
                                                        onMouseDown={(e) => {
                                                            e.stopPropagation();
                                                            onChange(e, { value: {value: option.value, name: `${category.text}/${option.text}`} });
                                                            setCategoryBeingHovered(null);
                                                            setOpen(false);
                                                        }} key={option.id} disabled={false}>
                                                            {option.text}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    )
                                } : {})} 
                            />
                        ))
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </Ref>
    );
}

export default ToggableDropdown;
