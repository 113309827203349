import React from 'react';
import { MessagingIcon, ControlledPopup } from 'dyl-components';

const MessagingIconButtons = ({isEditMode, onDisableEditMode, onUpdate, onEnableEditMode, onDelete, id, disabled }) => (
    <React.Fragment>
         <ControlledPopup 
                trigger={<MessagingIcon icon='trash' disabled={disabled}  />}
                onConfirm={() => {onDelete(id)}}
                content={'Delete'}
                inverted
            />
        {isEditMode && (
            <MessagingIcon disabled={disabled} icon='times'  onClick={onDisableEditMode} />
        )}
        <MessagingIcon
            icon={isEditMode ? 'check' : 'edit'}
            onClick={isEditMode ? onUpdate : onEnableEditMode}
            disabled={disabled}
        />
    </React.Fragment>
);

export default MessagingIconButtons;
