import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill-with-table/dist/react-quill';
import Link from 'quill/formats/link';
import 'react-quill-with-table/dist/quill.snow.css';
import QuillTableUI from 'quill-table-ui/dist/index';
import 'quill-table-ui/dist/index.css';

import { Segment, Icon, Form, TextArea, Popup, Input, Button } from 'semantic-ui-react';

import './index.scss';

Quill.register({ "modules/tableUI": QuillTableUI }, true);

const builtInFunc = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
    var val = linkValueInput;

    // do nothing, since this implies user's already using a custom protocol
    if (/^\w+:/.test(val));
    else if (!/^https?:/.test(val))
        val = "https://" + val;

    return builtInFunc.call(this, val); // retain the built-in logic
};

export const TEXT_EDITOR_MODES = {
    RICH_TEXT: 'RICH_TEXT',
    HTML: 'HTML'
}

const HTMLToolbar = ({ className, onSwitchToRichText, otherControls, text_only }) => (
    <div className={`${className} ql-toolbar ql-snow`}>
        {otherControls}
        {!text_only && <Icon name='text cursor' onClick={onSwitchToRichText} link style={{ marginLeft: '0.75em', marginTop: '0.25em' }} />}
    </div>
)

const Toolbar = ({ className, allowSwitchToHTML, onSwitchToHTML, name, value, onChange, controls, otherControls = [] }) => {

    const [rowCount, setRowCount] = useState(1);
    const [colCount, setColCount] = useState(1);

    const insertTable = () => {
        let columns = '';
        for (let i = 0; i < colCount; i++) {
            columns = `${columns}<td></td>`;
        }

        let rows = '';
        for (let i = 0; i < rowCount; i++) {
            rows = `${rows}<tr>${columns}</tr>`;
        }

        let table = `<p></p><table><tbody>${rows}</tbody></table><br><p></p>`;
        value = `${value}${table}`;

        setRowCount(1);
        setColCount(1);

        onChange(null, { name, value });
    }

    return (
        <div className={className}>
            {otherControls}
            {allowSwitchToHTML && (
                <Icon name='code' onClick={onSwitchToHTML} link style={{ float: 'left', marginLeft: '0.75em', marginTop: '0.35em' }} />
            )}
            {controls || (
                <React.Fragment>
                    <span className="ql-formats">
                        <select className="ql-font"></select>
                    </span>
                    <select className="ql-header">
                        <option value="1"></option>
                        <option value="2"></option>
                        <option selected></option>
                    </select>
                    <span className="ql-formats">
                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                    </span>
                    <span className="ql-formats">
                        <select className="ql-color"></select>
                        <button className="ql-link"></button>
                        <button className="ql-image"></button>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-list" value="ordered"></button>
                        <button className="ql-list" value="bullet"></button>
                        <button className="ql-indent" value="-1"></button>
                        <button className="ql-indent" value="+1"></button>
                        <select className="ql-align"></select>
                    </span>
                    <span className="ql-formats">
                        <button className="ql-clean"></button>
                    </span>
                    <span className="ql-formats">
                        <Popup trigger={<Icon name='table' link />} on='click' basic position='bottom le' content={
                            <div className='ql-custom-table'>
                                <Input label='Row' type='number' size='mini' min={1} value={rowCount} onChange={(_, { value }) => { setRowCount(value) }} />
                                <Input label='Col' type='number' size='mini' min={1} value={colCount} onChange={(_, { value }) => { setColCount(value) }} />
                                <Button basic onClick={insertTable}>Insert</Button>
                            </div>
                        } />
                    </span>
                </React.Fragment>
            )}
        </div>
    )
}

const RichTextEditor = ({
    name,
    value,
    onChange,
    editorRef,
    toolbarClassName,
    onSwitchToHTML,
    onSwitchToRichText,
    allowSwitchToHTML,
    otherControls = [],
    style,
    mode = TEXT_EDITOR_MODES.RICH_TEXT,
    basic,
    text_only = false,
    onInsertImage,
    controls
}) => {
    return (
        <Segment className={`RichTextEditor${basic ? ` RichTextEditor--basic` : ''}${text_only ? ` RichTextEditor--text-only` : ''}`}>
            {mode === TEXT_EDITOR_MODES.RICH_TEXT && (
                <Toolbar
                    allowSwitchToHTML={allowSwitchToHTML}
                    className={toolbarClassName}
                    otherControls={otherControls}
                    onSwitchToHTML={onSwitchToHTML}
                    name={name}
                    value={value}
                    onChange={onChange}
                    controls={controls}
                />
            )}
            {mode === TEXT_EDITOR_MODES.HTML && (
                <HTMLToolbar
                    text_only={text_only}
                    onSwitchToRichText={onSwitchToRichText}
                    className={toolbarClassName}
                    otherControls={otherControls}
                />
            )}
            {mode === TEXT_EDITOR_MODES.RICH_TEXT && (
                <ReactQuill
                    ref={editorRef}
                    theme='snow'
                    value={value}
                    onChange={(newText) => { onChange(null, { name, value: newText }); }}
                    style={style}
                    modules={{
                        toolbar: {
                            container: `.${toolbarClassName}`
                        },
                        table: true,
                        tableUI: true
                    }}
                    bounds={`.RichTextEditor`}
                />
            )}
            {mode === TEXT_EDITOR_MODES.HTML && (
                <Form>
                    <TextArea
                        onChange={onChange}
                        value={value}
                        name={name}
                        ref={editorRef}
                    />
                </Form>
            )}
        </Segment>
    );
}

export default RichTextEditor;
