import React from 'react';
import { Form, Icon } from 'semantic-ui-react';

const EmailsForm = ({ emails, onChange, onRemove, onToggleMain, size='tiny' }) => (
    <Form size={size}>
        {emails.map(({ id, email, main, deleteable }) => (
            <Form.Group key={id} widths='equal'>
                <Form.Input 
                    size={size}
                    onChange={(_, { name, value }) => {onChange('email', { name, value, id })}} 
                    inline 
                    placeholder='Email' 
                    name='email' 
                    value={email} 
                />
                <Form.Checkbox
                    size={size}
                    onChange={() => {onToggleMain('email', { id })}} 
                    label='Main' 
                    name='main' 
                    checked={main}
                />
                {deleteable && <Icon 
                    link
                    name='times' 
                    color='red'
                    onClick={() => {onRemove('email', id)}} 
                />}
            </Form.Group>   
        ))}
    </Form>
);

export default EmailsForm;
