import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { ClippedContent } from 'dyl-components';

import './index.scss';

const Stages = ({ stages, size = 'small', children, activeStage, changeStage }) => {
    const [sections, setSections] = useState([]);
    const stagesHalfLength = Math.ceil(stages.length / 2);
    const stagesCount = 5;
    const nextSiblingCount = 3;
    const previousSiblingCount = 1;

    useEffect(() => {
        generateSections(findActiveStageIndex());
    }, [size]);

    const findActiveStageIndex = () => {
        return stages.findIndex(stage => stage.key === activeStage);
    }

    const findStageIndex = (stageName) => {
        return stages.findIndex(stage => stage.key === stageName);
    }

    const getPreviousIndex = (activeIndex) => {
        if (activeIndex + nextSiblingCount + previousSiblingCount >= stages.length) {
            return stages.length - nextSiblingCount - previousSiblingCount - 1;
        }
        return activeIndex - previousSiblingCount;
    }

    const getNextIndex = (activeIndex) => {
        if (activeIndex + nextSiblingCount + previousSiblingCount >= stages.length) {
            return 0;
        }

        let tempIndex = activeIndex === 0 ? activeIndex + 1 : activeIndex;

        return stages.length - (nextSiblingCount + previousSiblingCount + tempIndex);
    }

    const generateSections = (activeIndex, view = 'default') => {

        const previousIndex = getPreviousIndex(view === 'default' ? activeIndex : view === 'previous_stages' ? activeIndex - (stagesHalfLength - (stagesHalfLength - activeIndex)) : stagesHalfLength + 1);
        const nextIndex = getNextIndex(view === 'default' ? activeIndex : view === 'next_stages' ? activeIndex + (stagesHalfLength - activeIndex) + 1 : 1);

        let tempStages = stages.slice(0).splice(previousIndex >= 1 ? previousIndex : 0, stagesCount);

        if (previousIndex >= 1) {
            tempStages = [{ key: 'previous_stages', content: `+${previousIndex}` }, ...tempStages];
        }

        if (nextIndex > 0) {
            tempStages = [...tempStages, { key: 'next_stages', content: `+${nextIndex}` }];
        }

        setSections(tempStages.map(({ key, content, link }) => {
            const finished = findStageIndex(key) === findActiveStageIndex();
            const active = findStageIndex(key) <= findActiveStageIndex();
            const isPaginationButton = key === 'previous_stages' || key === 'next_stages';
            return ({
                key,
                onClick: () => {
                    isPaginationButton ?
                        generateSections(key === 'previous_stages' ? previousIndex : nextIndex, key) :
                        changeStage(key)
                },
                content: isPaginationButton ? content : <ClippedContent>{content}</ClippedContent>,
                link,
                className: `Stage ${active && 'Stage--active'} ${finished && 'Stage--finished'} ${isPaginationButton && 'Stage--pagination'}`
            });
        }));
    }

    return (
        <React.Fragment>
            <Breadcrumb
                className='Stages'
                sections={sections}
                divider=''
                size={size}
            />
            {children}
        </React.Fragment>
    )
};

export default Stages;
