import React, { useState } from 'react';
import './index.scss';
import { Header, Icon } from 'semantic-ui-react';
import DialButton from './DialButton';
import ButtonLink from 'dyl-components/atoms/ButtonLink';
import { Controller } from 'react-hook-form';
import { PhoneUtil } from 'utils';
import DialButtonRow from './DialButtonRow';

const OfficeViewDialPad = ({ control, dialedNumber, dialDisabled, dialButtonDisabled, onDial, onKeyPress }) => {
    const [inputMode, setInputMode] = useState(false);

    const onClickValueContainer = () => {
        if (!dialDisabled) {
            if (!inputMode) {
                setInputMode(true);
            }
        }
    }

    const handleKeyDown = (event, name, value, onChange) => {
        const { key: keyPressed, ctrlKey: isCtrlPressed} = event;
        const { selectionStart, selectionEnd } = event.target;
        const isSelecting = selectionStart !== selectionEnd;
        if (/^[0-9]$/.test(keyPressed)) {
            const newValue = isSelecting ? (
                selectionStart === 0 ? (
                    `${keyPressed}${value.slice(selectionEnd)}`
                ) : (
                    `${value.slice(0, selectionStart)}${keyPressed}${value.slice(selectionEnd)}`
                )
            ) : (
                `${value.slice(0, selectionStart)}${keyPressed}${value.slice(selectionStart)}`
            )
            onChange({ target: { name, value: newValue } })
            setTimeout(() => {
                event.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
            }, 0)
        } else {
            switch(keyPressed) {
                case "Backspace": {
                    const newValue = isSelecting ? (
                        selectionStart > 0 ? (
                            `${value.slice(0, selectionStart)}${value.slice(selectionEnd)}`
                        ) : (
                            value.slice(selectionEnd)
                        )
                    ) : (
                        selectionStart > 0 ? (
                            `${value.slice(0, selectionStart - 1)}${value.slice(selectionStart)}`
                        ) : (value)
                    )
                    onChange({ target: { name, value: newValue} })
                    setTimeout(() => {
                        if (isSelecting || selectionStart === 0) {
                            event.target.setSelectionRange(selectionStart, selectionStart);
                        } else {
                            event.target.setSelectionRange(selectionStart - 1, selectionStart - 1);
                        }
                    }, 0)
                    break;
                }
                case "ArrowRight": {
                    if (selectionStart < value.length) {
                        setTimeout(() => {
                            event.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
                        }, 0)
                    }
                    break;
                }
                case "ArrowLeft": {
                    if (selectionStart > 0) {
                        setTimeout(() => {
                            event.target.setSelectionRange(selectionStart - 1, selectionStart - 1);
                        }, 0)
                    }
                    break;
                }
                case "Enter": {
                    event.target.blur();
                    break;
                }
                case "v": {
                    if (isCtrlPressed) {
                        navigator.clipboard.readText().then((result) => {
                            if (result) {
                                const filteredResult = result.replace(/\D/g, '');
                                const newValue = isSelecting ? (
                                    selectionStart === 0 ? (
                                        `${filteredResult}${value.slice(selectionEnd)}`
                                    ) : (
                                        `${value.slice(0, selectionStart)}${filteredResult}${value.slice(selectionEnd)}`
                                    )
                                ) : (
                                    `${value.slice(0, selectionStart)}${filteredResult}${value.slice(selectionStart)}`
                                )
                                onChange({ target: { name, value: newValue } })
                                setTimeout(() => {
                                    event.target.setSelectionRange(selectionStart, selectionStart);
                                }, 0)
                            }
                        });
                    }
                    break;
                }
                case "c": {
                    if (isCtrlPressed && isSelecting) {
                        navigator.clipboard.writeText(value.slice(selectionStart, selectionEnd))
                    }
                    break;
                }
                default: {
                    break;
                }
            }
            
        }
        event.preventDefault();
    }

    return (
        <div className='OfficeViewDialPad'>
            <Controller
                name='dial'
                control={control}
                render={({ field: { name, value, onChange } }) => (
                    <div className='OfficeViewDialPad__controller'>
                        <div className='OfficeViewDialPad__valueContainer' onClick={onClickValueContainer}>
                            {inputMode ? (
                                <input
                                    className="OfficeViewDialPad__input"
                                    onKeyDown={(event) => handleKeyDown(event, name, value, onChange)}
                                    value={value}
                                    onBlur={() => setInputMode(false)}
                                    autoFocus
                                />
                            ) : (
                                <Header as={"h2"}>
                                    {PhoneUtil.asYouTypeFormatter(value)}
                                </Header>
                            )}
                        </div>
                        <div className='OfficeViewDialPad__buttonsContainer'>
                            <DialButtonRow disabled={dialDisabled} onChange={onChange} name={name} value={value} onKeyPress={onKeyPress} buttons={[
                                {symbol: '1'},
                                {symbol: '2', subtext: 'ABC'},
                                {symbol: '3', subtext: 'DEF'},
                            ]} />
                            <DialButtonRow disabled={dialDisabled} onChange={onChange} name={name} value={value} onKeyPress={onKeyPress} buttons={[
                                {symbol: '4', subtext: 'GHI'},
                                {symbol: '5', subtext: 'JKL'},
                                {symbol: '6', subtext: 'MNO'},
                            ]} />
                            <DialButtonRow disabled={dialDisabled} onChange={onChange} name={name} value={value} onKeyPress={onKeyPress} buttons={[
                                {symbol: '7', subtext: 'PQRS'},
                                {symbol: '8', subtext: 'TUV'},
                                {symbol: '9', subtext: 'WXYZ'},
                            ]} />
                            <DialButtonRow disabled={dialDisabled} onChange={onChange} name={name} value={value} onKeyPress={onKeyPress} buttons={[
                                {symbol: '*'},
                                {symbol: '0', subtext: '+'},
                                {symbol: '#'},
                            ]} />
                        </div>
                    </div>
                )}
            />
            <div className='OfficeViewDialPad__finalRow'>
                <div className='OfficeViewDialPad__fill' />
                <DialButton call disabled={dialButtonDisabled || dialDisabled} onClick={onDial} />
                {!!dialedNumber ? (
                    <div className='OfficeViewDialPad__delete'>
                        <Controller
                            name='dial'
                            control={control}
                            render={({ field: { name, value, onChange } }) => (
                                <ButtonLink onClick={() => {
                                    const newValue = value.slice(0, -1);
                                    onChange({ target: { name, value: newValue } })
                                }}>
                                    <Icon size={"big"} className='fa-solid fa-delete-left blackIcon' />
                                </ButtonLink>
                            )}
                        />
                    </div>
                ) : (
                    <div className='OfficeViewDialPad__fill' />
                )}
            </div>
        </div>
    )
};

export default OfficeViewDialPad;