import React from 'react';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const FilterComponent = ({ children }) => (
    <Segment raised className='FilterComponent'>
        {children}
    </Segment>
)

export default FilterComponent;
