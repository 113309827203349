import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Header } from 'semantic-ui-react';
import { useForm, useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import LabelField from './LabelField';

const NewLabels = ({ fields = [], name, addLabel = 'Add', getPropsByType, onSave, onCheckDuplicate, onFormDirty }) => {

    const { control, formState: { isValid, isDirty, errors }, watch, trigger, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            [`new_${name}`]: [{ value: fields.map(({ defaultValue }) => defaultValue) }]
        }
    });

    const { fields: newItems, append, remove } = useFieldArray({
        control,
        name: `new_${name}`,
        keyName: 'labelId'
    });

    const watchNewItems = watch(`new_${name}`);

    const fieldDuplicateProp = {
        id: uuid(), isChecking: false, deleted: false, hasDuplicate: false, lastLabelChecked: '', duplicateMessage: '',
        checkDuplicateTimeoutHandler: null,
        startCheckDuplicateTimeout: function (checkDuplicate) {
            this.checkDuplicateTimeoutHandler = setTimeout(() => {
                checkDuplicate();
            }, 750)
        },
        clearCheckDuplicateTimeout: function () {
            clearTimeout(this.checkDuplicateTimeoutHandler)
        }
    }

    const [fieldsDuplicateProps, setFieldsDuplicateProps] = useState([fieldDuplicateProp]);

    const modifyCheckForDuplicateProps = (action = '', id, value) => {
        let index = fieldsDuplicateProps.findIndex(item => item.id === id);
        switch (action) {
            case 'update_lastLabelChecked':
                setFieldsDuplicateProps(arr => {
                    arr[index].lastLabelChecked = value;
                    return [...arr];
                });
                break;
            case 'update_isChecking':
                setFieldsDuplicateProps(arr => {
                    arr[index].isChecking = value;
                    return [...arr];
                });
                break;
            case 'update_hasDuplicate':
                setFieldsDuplicateProps(arr => {
                    arr[index].hasDuplicate = value.hasDuplicate;
                    arr[index].duplicateMessage = value.duplicateMessage;
                    return [...arr];
                });
                break;
            case 'update_checkDuplicateTimeoutHandler':
                setFieldsDuplicateProps(arr => {
                    arr[index].clearCheckDuplicateTimeout();
                    arr[index].startCheckDuplicateTimeout(value);
                    return [...arr];
                });
                break;
            case 'add':
                setFieldsDuplicateProps(arr => [...arr, fieldDuplicateProp]);
                break;
            case 'remove':
                setFieldsDuplicateProps(arr => {
                    arr[index].deleted = true;
                    arr[index].isChecking = false;
                    return [...arr];
                });
                break;
            case 'clear_timeout':
                setFieldsDuplicateProps(arr => {
                    arr[index].clearCheckDuplicateTimeout();
                    return [...arr];
                });
                break;
            default:
        }
    }

    const getFieldDuplicateProps = (itemIndex, prop = '') => {
        return fieldsDuplicateProps.filter(item => !item.deleted)[itemIndex][prop]
    }

    const checkDuplicateStatus = () => {
        return fieldsDuplicateProps.some(({ hasDuplicate, isChecking, deleted }) => isChecking === true || (hasDuplicate === true && deleted === false));
    }

    const onCheckLabelDuplicate = async (value, fieldName, itemIndex, valueIndex, checkDuplicateId) => {
        let hasDuplicate = false;
        let duplicateMessage = '';

        const updateDuplicateProps = () => {
            modifyCheckForDuplicateProps('update_lastLabelChecked', checkDuplicateId, value);
            modifyCheckForDuplicateProps('update_hasDuplicate', checkDuplicateId, { hasDuplicate, duplicateMessage });
            trigger(fieldName);
        }

        if (value && value?.trim()) {
            if (onCheckDuplicate) {
                await onCheckDuplicate(value.replace(/\s+/g, ' ').trim()).then(() => {
                    hasDuplicate = true;
                    duplicateMessage = 'This field has a duplicate in the existing record';
                    updateDuplicateProps();
                }, () => { })
            }

            if (hasDuplicate) {
                return;
            }

            let newValue = value.replace(/\s+/g, ' ').toLowerCase().trim();
            let values = watchNewItems
                .map(({ value }) => value[valueIndex].replace(/\s+/g, ' ').toLowerCase().trim())
                .filter(value => value === newValue);

            hasDuplicate = values.length > 1;
            duplicateMessage = 'This field has a duplicate in the list of items being added';

            updateDuplicateProps();
        } else {
            updateDuplicateProps();
        }
    }

    const onAddLabel = () => {
        append({ value: fields.map(({ defaultValue }) => defaultValue) });
        modifyCheckForDuplicateProps('add');
    }

    const onSubmit = (data) => {
        if (onSave) {
            onSave([...data[`new_${name}`]]);
            reset({ [`new_${name}`]: [{ value: fields.map(({ defaultValue }) => defaultValue) }] });    
        }
    }

    const isSaveButtonDisabled = () => {
        return watchNewItems.length > 1 ? !isDirty || !isValid || Object.keys(errors).length !== 0 || checkDuplicateStatus() :
            !isValid || watchNewItems[0].value[0] === ''
    }
    
    useEffect(() => {
        if( onFormDirty )onFormDirty(isDirty)
        }, [isDirty])

    return (
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    {fields.map((field, index) => (
                        index === 0 ?
                            <Grid.Column width={6}>
                                <Header>Add New {field.header}</Header>
                            </Grid.Column> :
                            <Grid.Column width={6}>
                            </Grid.Column>
                    ))}
                </Grid.Row>
                {newItems.map((item, itemIndex) => (
                    <Grid.Row key={item.labelId} style={{ paddingBottom: 0 }}>
                        {item.value.map((value, valueIndex) => (
                            <Grid.Column width={6}>
                                <LabelField
                                    {...getPropsByType(valueIndex)}

                                    itemIndex={itemIndex}
                                    valueIndex={valueIndex}
                                    value={value}
                                    name={`new_${name}`}
                                    control={control}

                                    remove={() => {
                                        remove(itemIndex);
                                        modifyCheckForDuplicateProps('remove', getFieldDuplicateProps(itemIndex, 'id'));
                                    }}

                                    hasMoreItems={newItems.length !== 1}
                                    hasMoreValues={item.value.length > 1}
                                    shouldRenderActionButton={item.value.length === valueIndex + 1}

                                    editMode={true}
                                    isFieldEditable={true}
                                    isDirty={isDirty}

                                    checkDuplicate={onCheckLabelDuplicate}
                                    getFieldDuplicateProps={getFieldDuplicateProps}
                                    modifyCheckForDuplicateProps={modifyCheckForDuplicateProps}
                                />
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                ))}
            </Grid>
            <div className='EditableLabels__addButton' onClick={onAddLabel}>
                <Icon name='plus' /> {addLabel}
            </div>
            <Button className='EditableLabels__saveButton'
                disabled={isSaveButtonDisabled()} type='button' content='Save' primary onClick={handleSubmit(onSubmit)} />
        </React.Fragment>
    )
}

export default NewLabels;
