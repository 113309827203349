import React from 'react';

import './index.scss';

const CustomDivider = () => (
    <React.Fragment>
        <div className='CustomDivider'></div>
    </React.Fragment>    
)

export default CustomDivider;
