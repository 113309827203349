import React from 'react';
import { Grid } from 'semantic-ui-react';
import GridListRow from './subcomponents/GridListRow';
import GridListBody from './subcomponents/GridListBody';
import GridListHeader from './subcomponents/GridListHeader';
import GridListHeaderCell from './subcomponents/GridListHeaderCell';
import GridListCell from './subcomponents/GridListCell';

const GridList = ({ children, className = '' }) => (
    <Grid className={className}>
        {children}
    </Grid>
);

GridList.Header = GridListHeader;
GridList.HeaderCell = GridListHeaderCell;
GridList.Body = GridListBody;
GridList.Row = GridListRow;
GridList.Cell = GridListCell;

export default GridList;
