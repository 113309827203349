import React from "react";
import { Table, Icon } from "semantic-ui-react";

import "./index.scss";

const StyledTable = props => <Table striped {...props} />;

StyledTable.CollapsibleRowContent = ({ children, indented, nested, secondCol }) => (
    <StyledTable.Row active className={`CollapsibleTableRow__subrowContent${nested ? secondCol ? ` CollapsibleTableRow__subrowContent--secondCol` : ` CollapsibleTableRow__subrowContent--nested` : ''}`}>
        {indented ? (nested ? (
            secondCol ? (
                <>
                    <Table.Cell width={1} />
                    <Table.Cell width={1}>
                        <div />
                    </Table.Cell>
                </>
            ) : (
                <Table.Cell width={1}>
                    <div />
                </Table.Cell>
            )
        ) : (
            <Table.Cell width={1} />
        )) : null}
        {children}
    </StyledTable.Row>
)

StyledTable.CollapsibleRow = ({ collapsed = true, children, toggleDisabled, toggleHidden, primary, isHeader, subrowContent, className, onToggle, isSubrow, nested, noHighlight, togglePos = 0, ...props }) => {
    const ToggleCell = isHeader ? StyledTable.HeaderCell : StyledTable.Cell;

    const toggler = !toggleHidden ? (
        <ToggleCell width={1} {...(!isHeader ? { className: "CollapsibleTableRow__fillerCell" } : {})}>
            {!isHeader && (
                <div>
                    <Icon disabled={toggleDisabled} color={!toggleDisabled ? 'primary' : 'grey'} link onClick={() => {
                        if (onToggle) {
                            onToggle();
                        }
                    }} className={`fas fa-circle-caret-${collapsed ? 'down' : 'up'}`} />
                </div>
            )}
        </ToggleCell>
    ) : (null);

    const cells = !togglePos ? [
        toggler,
        children
    ] : (() => {
        const children_copy = (children || []).slice(0);
        return [
            ...children_copy.slice(0, togglePos),
            toggler,
            ...children_copy.slice(togglePos, children_copy.length)
        ]
    })();

    return (
        [
            !isHeader && !collapsed && !isSubrow && <StyledTable.Row className="CollapsibleTableRow__divider" />,
            <StyledTable.Row
                {...props}
                className={`${className || ''}${!isHeader ? `CollapsibleTableRow${!collapsed ? ' CollapsibleTableRow--active' : ''}` : ''}${isSubrow ? ` CollapsibleTableRow__subrowContent${nested ? ' CollapsibleTableRow__subrowContent--nested' : ''}` : ""}`}
                primary={primary}
                active={!collapsed}
            >
                {cells}
            </StyledTable.Row>,
            !isHeader && !collapsed && (
                subrowContent
            ),
            !isHeader && !isSubrow && !collapsed && <StyledTable.Row className="CollapsibleTableRow__divider" />
        ]
    )
}

StyledTable.Row = ({ children, className = "", primary, active, ...props }) => (
    <Table.Row
        className={`Table__row ${active ? "Table__row--active" : ""} ${primary ? "Table__row--primary" : ""
            } ${className || ''}`}
        {...props}
    >
        {children}
    </Table.Row>
);

StyledTable.Header = ({ primary, className = "", ...props }) => (
    <Table.Header
        className={`Table__header ${primary ? "Table__header--primary" : ""
            } ${className}`}
        {...props}
    />
);

StyledTable.HeaderCell = ({ className, children, ...otherProps }) => (
    <Table.HeaderCell
        {...otherProps}
        className={`Table__header-cell ${className}`}
    >
        {children}
    </Table.HeaderCell>
)

StyledTable.Cell = props => <Table.Cell {...props} />;

StyledTable.Body = ({ className, ...props }) => <Table.Body
    className={`Table__body ${className}`}
    {...props}
/>;

StyledTable.Footer = props => <Table.Footer {...props} />;

StyledTable.Toolbar = ({ colSpan, children }) => (
    <Table.HeaderCell colSpan={colSpan} className="Table__toolbar">
        {children}
    </Table.HeaderCell>
);

export default StyledTable;
