import React from 'react';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import './index.scss';

class ClearableCheckboxDropdown extends React.Component {
    state = {
        open: false,
        all: false
    }

    onOpen = () => {
        this.setState({
            open: true
        });
    }

    onClose = () => {
        this.setState({
            open: false
        });
    }

    onUpdateItems = (e, itemValue) => {
        const selectedItems = (() => {
            if (this.isSelected(itemValue)) {
                return this.props.value.filter(item => itemValue !== item);
            }
            return [...this.props.value, itemValue];
        })();
        this.setState({
            all: selectedItems.length === this.props.options.length
        });

        this.onChange(e, selectedItems);
    };

    onChange = (e, value) => {
        const callback = async () => {
            await this.props.onChange(e, {
                name: this.props.name, value
            });

            if (this.props.onRead) {
                this.props.onRead();
            }
        }

        if (this.props.onChange) {
            if (this.props.closeOnLoad) {
                this.setState({ open: false }, callback);
            } else {
                callback();
            }
        }
    }

    isSelected = itemValue => {
        return (
            this.props.value.findIndex(
                selectedItem => selectedItem === itemValue
            ) !== -1
        );
    };

    getIndexOfItemToBeRemoved = (selectedItems, itemValue) => {
        return selectedItems.findIndex(
            selectedItem => selectedItem === itemValue
        );
    }

    toggleAllOption = (e) => {
        this.setState(prevState => ({
            all: !prevState.all
        }), () => {
            if (this.state.all) {
                const allItems = this.props.options.map(({ value, options }) => {
                    return (Boolean(options?.length) && options?.map(option => option.value)) || value;
                }).flat();
                this.onChange(e, allItems);
            } else {
                this.onChange(e, []);
            }
        })
    }

    isAllOptionsSelected = () => {
        return this.props.options.map(option => {
            if (Boolean(option.options?.length)) {
                return option.options;
            }
            return option;
        }).flat().length === this.props.value.length;
    }

    render() {
        const options = [...this.props.options];
        return (

            <Dropdown
                open={this.state.open}
                onOpen={this.onOpen}
                onClose={this.onClose}
                className='CheckboxDropdown'
                selection={this.props.selection}
                text={this.props.text}
                fluid={this.props.fluid || false}
                loading={this.props.loading}
                disabled={this.props.disabled}
            >
                <Dropdown.Menu className='CheckboxDropdown__menu'>
                    <Dropdown.Item
                        onClick={e => {
                            this.toggleAllOption(e);
                            e.stopPropagation();
                        }}
                        selected={this.isAllOptionsSelected()}
                        key={'all'}
                    >
                        <Checkbox
                            onMouseDown={e => { e.preventDefault() }}
                            checked={this.isAllOptionsSelected()}
                            label={'All'}
                        />
                    </Dropdown.Item>
                    {options.map(({ key, value, text, options: suboptions }) => {
                        const isSelected = Boolean(this.isSelected(value) || (suboptions?.length && suboptions?.some(suboption => this.isSelected(suboption.value))));
                        return [
                            <Dropdown.Item
                                onClick={e => {
                                    if (!Boolean(suboptions?.length)) {
                                        this.onUpdateItems(e, value);
                                    } else {
                                        let selectedItems = (() => {
                                            if (isSelected) {
                                                return this.props.value.slice(0).filter(selected => !Boolean(suboptions?.find(suboption => suboption.value === selected)))
                                            }
                                            return [
                                                ...this.props.value,
                                                ...suboptions.map(suboption => suboption.value)
                                            ]
                                        })();
                                        this.setState({
                                            all: selectedItems.length === this.props.options.map(option => {
                                                if (Boolean(option.options?.length)) {
                                                    return option.options;
                                                }
                                                return option;
                                            }).flat().length
                                        });
                                        this.onChange(e, selectedItems);
                                    }
                                    e.stopPropagation();
                                } }
                                selected={isSelected}
                                key={key}
                            >
                                <Checkbox
                                    onMouseDown={e => { e.preventDefault(); } }
                                    checked={isSelected}
                                    label={text} />
                            </Dropdown.Item>,
                            ...(suboptions || []).map(({ key, value, text }) => {
                                return (
                                    <Dropdown.Item
                                        onClick={e => {
                                            this.onUpdateItems(e, value);
                                            e.stopPropagation();
                                        } }
                                        selected={this.isSelected(value)}
                                        key={key}
                                        
                                    >
                                        <Checkbox
                                            onMouseDown={e => { e.preventDefault(); } }
                                            checked={this.isSelected(value)}
                                            label={text} 
                                            style={{ marginLeft: "2em" }}
                                        />
                                    </Dropdown.Item>
                                );
                            })
                        ];
                    }).flat()}
                </Dropdown.Menu>
            </Dropdown>

        );
    }
}

ClearableCheckboxDropdown.Header = ({ children }) => (
    <Dropdown.Header>{children}</Dropdown.Header>
)

ClearableCheckboxDropdown.Item = class extends React.Component {
    state = {
        checked: false
    }

    handleChange() {
        this.setState({ checked: !this.state.checked });
    }

    render() {
        return (
            <Dropdown.Item onClick={this.handleChange.bind(this)}><Checkbox name={this.props.name} label={this.props.children || this.props.label} checked={this.state.checked} /></Dropdown.Item>
        )
    }
}

ClearableCheckboxDropdown.defaultProps = {
    options: [],
    value: []
}

export default ClearableCheckboxDropdown;
