import React from 'react';
import { Icon } from 'semantic-ui-react';

import './index.scss';

const ButtonSquare = ({ onClick, text, icon, disabled, ...otherProps }) => (
    <button {...otherProps} onClick={onClick} className='ButtonSquare' disabled={disabled}>
        <div style={{marginBottom: 3}}><Icon className={icon} /></div>
        <div>{text}</div>
    </button>
)

export default ButtonSquare;
