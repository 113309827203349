import React from 'react';
import { Feed } from 'semantic-ui-react';
import FeedItem from '../../molecules/FeedItem';

const MessageFeed = ({ activeMessage, onChangeActiveMessage, messages, size='small' }) => (
    <Feed size={size}>
        {messages.map(({ id, ...otherProps }) => (
            <FeedItem 
                key={id}
                active={activeMessage === id}
                onClick={() => {onChangeActiveMessage(id)}}
                {...otherProps}
            />
        ))}
    </Feed>
);

export default MessageFeed;
