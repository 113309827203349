import ButtonLink from 'dyl-components/atoms/ButtonLink';
import React, { useState } from 'react';
import { Header, Icon, Loader } from 'semantic-ui-react';
import "./index.scss";
import { useForm } from 'react-hook-form';
import OfficeViewMiddleHeader from '../OfficeViewMiddleHeader';
import OfficeViewCallResults from '../OfficeViewCallResults';
import OfficeViewCallRecording from '../OfficeViewCallRecording';
import OfficeViewRecentItem from '../OfficeViewRecentItem';

const OfficeViewRecents = ({ 
    recents,
    count,
    onRecentCallClick,
    recentCall,
    onCloseRecentCall,
    onEmailClick,
    setIsLogActivityModalOpen,
    logActivityForm,
    hasIntegration,
    onTaskClick,
    onHotlistClick,
    isInHotlist,
    isLogActivityModalOpen,
    onEventClick,
    isReadingRecentCalls,
    isReadingRecentCall,
    onViewMoreClick,
}) => {
    const [viewCallMode, setViewCallMode] = useState(false);

    const { control } = useForm({
        mode: "onChange",
        defaultValues: {}
    })

    const formatPerson = () => {
        const person = recentCall ? recentCall.contacts?.[0] || null : null;
        return {
            name: person?.name,
            contact_id: person?.contact_id,
            account_id: person?.account_id,
            ...(person?.contact_id ? {
                contact_details: {
                    job_title: person?.job_title,
                    email: person?.email,
                    pipeline_stage: person?.pipeline,
                    phone: [{
                        main: true,
                        phone: person?.phone
                    }]
                }
            } : {}),
            ...(person?.account_id ? {
                account_details: {
                    customer: person?.is_customer,
                    email: person?.email,
                    account_type: person?.type,
                    phone: [{
                        main: true,
                        phone: person?.phone
                    }]
                }
            } : {})
        }
    }

    return (
        viewCallMode ? (
            !isReadingRecentCall ? (
                <div className='OfficeView__midContainer' style={{padding: "25px 35px 25px 30px"}}>
                    <OfficeViewMiddleHeader
                        person={formatPerson()}
                        onRemovePerson={() => {
                            setViewCallMode(false);
                            onCloseRecentCall();
                        }}
                        onEmailClick={onEmailClick}
                        setIsLogActivityModalOpen={setIsLogActivityModalOpen}
                        logActivityForm={logActivityForm}
                        unknown={{unknown: false}}
                        hasIntegration={hasIntegration}
                        onTaskClick={onTaskClick}
                        onHotlistClick={onHotlistClick}
                        isInHotlist={isInHotlist}
                        isLogActivityModalOpen={isLogActivityModalOpen}
                        onEventClick={onEventClick}
                    />
                    <div style={{marginTop: 30}}>
                        <OfficeViewCallRecording 
                            currentCallPhoneNumber={recentCall?.contacts?.[0]?.phone}
                            duration={recentCall?.length}
                            callRecording={{...recentCall?.call_recording, sound_id: recentCall?.call_uuid}}
                            voicemail={recentCall?.system_result === "Missed"}
                        />
                    </div>
                    <div>
                        <OfficeViewCallResults control={control} />
                    </div>
                </div>
            ) : (
                <div style={{ height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Loader active inline />
                </div>
            )
        ) : (
            !isReadingRecentCalls ? (
                <div style={{display: 'flex', height: '100%', padding: "35px 20px 35px 20px", flexDirection: 'column'}}>
                    {count === 0 && (
                        <div style={{display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center'}}>
                            <Header as={"h2"}>No Recent Calls</Header>
                        </div>
                    )}
                    {count > 0 && (
                        <div style={{flex: 1, display: 'flex', overflowY: "auto", flexDirection: 'column', gap: 20}}>
                            {recents.map((recent) => {
                                return <OfficeViewRecentItem recent={recent} onRecentCallClick={onRecentCallClick} setViewCallMode={setViewCallMode} />
                            })}
                        </div>
                    )}
                    <div style={{paddingTop: 15, paddingLeft: 25}}>
                        <ButtonLink onClick={onViewMoreClick}>
                            <span>View More</span>
                            <Icon className='fa-solid fa-chevron-right' />
                        </ButtonLink>
                    </div>
                </div>
            ) : (
                <div style={{ height: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <Loader active inline />
                </div>
            )
            
        )
    )
}

export default OfficeViewRecents;