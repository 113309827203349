import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import './index.scss';

const SelectedElement = ({ children, selected = false, unselectable = false, onClick = () => { }, onUnselect = () => { }, borderRadius = '25%' }) => {

    const [isHovered, setIsHovered] = useState(false);
    const onHover = () => {
        setIsHovered(true);
    }

    const onLeave = () => {
        setIsHovered(false);
    }

    return (
        <React.Fragment>
            <div className={`SelectedElement SelectedElement${selected ? '--selected' : ''}`} onClick={onClick} onMouseOver={unselectable ? onHover : () => { }} onMouseLeave={unselectable ? onLeave : () => { }}>
                <div className={`SelectedElement__border${selected ? '--selected' : ''} SelectedElement__border${selected && isHovered ? '--hovered' : ''}  `} style={{ borderRadius }}>
                    {children}
                </div>

                {selected &&
                    <Icon name={selected && isHovered ? `times` : `check`} size={selected && isHovered ? "" : "small"} circular onClick={onUnselect} />
                }
            </div>
        </React.Fragment>
    )
};

export default SelectedElement;
