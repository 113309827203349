import React from 'react';
import { Card, Grid, Icon, Header } from 'semantic-ui-react';

import './index.scss';

const NavCard = ({ fluid = true, icon, header, description, width = 300, highlight = false, hsize = 'h3', onClick, noPadding }) => (
    <Card fluid={fluid} className={`NavCard NavCard${highlight ? '--highlight' : noPadding ? '--noPadding' :'--basic'} `}>
        <Card.Content onClick={onClick} style={{ width }}>
            <Grid>
                <Grid.Column className='NavCard__Icon'>
                    <Icon name={icon} />
                </Grid.Column>
                <Grid.Column className='NavCard__Text'>
                    <Header as={hsize}>
                        {header}
                        {description && <Header.Subheader>
                            {description}
                        </Header.Subheader>}
                    </Header>
                </Grid.Column>
            </Grid>
        </Card.Content>
    </Card>
)

export default NavCard;
