import React, { useEffect, useState } from 'react';
import { Dropdown, Select, Icon, Popup, Form } from 'semantic-ui-react';
import {round} from 'lodash'
import './index.scss';
import { useFieldArray, Controller } from 'react-hook-form';
import ButtonLink from 'dyl-components/atoms/ButtonLink';


const resetOptions = [
    { key: 'dd', value: 'daily', text: 'Daily' },
    { key: 'ww', value: 'weekly', text: 'Weekly' },
    { key: 'mm', value: 'monthly', text: 'Monthly' },
]

const distributionOptions = [
    { key: 'false', value: false, text: 'Equal' },
    { key: 'true', value: true, text: 'Weighted' },
]

const RoundRobin = ({parentName, control, trigger, searchContent, onChangeField, watchedWeighted, isDistributionTeamMessageOn, totalPercent, setTotalPercent, onUniqueRoutingCheck = () => {}}) => {
    const [isSelectedEmpty, setIsSelectedEmpty] = useState(true);
    const [displayedOptions, setDisplayedOptions] = useState([]);

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const { fields: users, append, remove, update, replace } = useFieldArray({
        control,
        name: `${parentName}.users`,
        keyName: 'formId'
    });

    useEffect(() => {
        if (users.length === 0) {
            setIsSelectedEmpty(true)
            setTotalPercent(0);
        }
        else {
            if (isSelectedEmpty) {
                setIsSelectedEmpty(false)
                if (watchedWeighted) {
                    setTotalPercent(100)
                }
            }
        }
    }, [users])

    useEffect(() => {
        if (watchedWeighted) {
            recalc();
        }
    }, [users, watchedWeighted])

    const onClickSearchItem = (item) => {
        if (!users.some(index => index.user_id === item.user_id)){
            onAddUser(item.user_id, item.first_name, item.last_name, item.email)
        }
        setOpen(false);
    }

    const renderTeams = (teams) => {
        if (teams.length){
            if (teams.length > 1){
                return (
                    <Popup
                        disabled={teams.length <= 2}
                        trigger={
                        <div>
                            {teams[0].name} , 
                            <br/>
                            {teams[1].name} 
                            {teams.length > 2 ? " ..." : null}
                        </div>}
                        position='top center'>
                        <Popup.Header>All teams</Popup.Header>
                        <Popup.Content>
                            {teams.map(team => <div>{team.name}</div>)}
                        </Popup.Content>   
                    </Popup> )
            }
            else {
                return(
                    <div>
                        {teams[0].name}
                    </div>
                )
            }
        }
        else{
            return null
        }
    }
    
    const formatSearchResults = () => {
        const options = [];
        const filteredSearchContent = searchContent.filter((searchUser) => !users.some((user) => user.user_id === searchUser.user_id))
        const searchRegex = new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        filteredSearchContent.forEach(user => {
            const isItemMatch = `${user.first_name || ""} ${user.last_name || ""}`.match(searchRegex);
            let isTeamsMatch = false;

            if (user.teams) {
                user.teams.forEach(team => {
                    if (team.name.match(searchRegex)){
                        isTeamsMatch = true;
                    }
                })
            }

            if (!!isItemMatch || isTeamsMatch) {     
                let name = user.first_name ? user.last_name ? user.first_name + " " + user.last_name : user.first_name : user.last_name
                options.push({...user, name});
            }
            
        });

        return options;
    }

    const onAddUser = (user_id, first_name, last_name, email) => {
        if (!watchedWeighted) {
            append({user_id: user_id, first_name: first_name, last_name: last_name, email: email});
        } else {
            append({user_id: user_id, first_name: first_name, last_name: last_name, email: email, ratio: 5});
        }
    }

    const onUpdateUser = (index, newInfo) => {
        update(index, newInfo);
    }

    const onReplaceFields = (newFields) => {
        replace(newFields);
    }

    const onDeleteUser = (index) => {
        remove(index);
    }

    const recalc = (currentFields) => {
        let addedPercentages = 0;
        currentFields ? currentFields.forEach(item => addedPercentages += item.ratio) : users.forEach(item => addedPercentages += item.ratio);
        setTotalPercent(addedPercentages)
    }

    const emptySelection = () => {
        setTotalPercent(0);
        remove();
    }

    const handleDistributionChange = (value) => {
        let currentFields = users;
        if (!value) {
            setTotalPercent(100);
            for (let i = 0; i < currentFields.length; i++) {
                let userFields = currentFields[i];
                delete userFields.ratio
            }
        }
        else {
            onChangeField("daily", "period");
            for (let i = 0; i < users.length; i++) {
                const percentage = 100 / currentFields.length
                const roundedPercentage = round(percentage / 5) * 5
                currentFields[i].ratio = roundedPercentage;
            }
        }
        onReplaceFields(currentFields)
    }

    const getIncrements = () => {
        const result = []
        for (let index = 5; index <= 100; index+= 5) {
            result.push({key: index, text: `${index}%`, value: index});
        }
        return result;
    }

    const percentageOptions = getIncrements();

    const renderContentUser = (item, index) => {
        return (
            <div style={{display:'flex', alignItems: 'center'}}>
                <div className='roundRobin__selectionBox__content__user'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Icon bordered name='user' size='large' 
                            className='roundRobin__selectionBox__content__user__icon' style={{marginInline: '20px'}}/>
                        <div>
                            <div
                                className={`roundRobin__selectionBox__content__user__text${watchedWeighted ? "--weighted" : "--equal"} roundRobin__latoBlack`}
                                style={{padding:0}}
                            >
                                {item.first_name + " " + item.last_name}
                            </div>
                            <div
                                className={`roundRobin__selectionBox__content__user__text${watchedWeighted ? "--weighted" : "--equal"} roundRobin__latoLight`}
                                style={{padding:0}}
                            >
                                    {item.email}
                            </div>
                        </div>
                    </div>
                    {watchedWeighted && (
                        <Controller 
                            name={`users[${index}].ratio`}
                            control={control}
                            render={({ field: { name, onChange }, fieldState: { error} }) => (
                                <Form.Select
                                    name={name}
                                    value={users[index].ratio}
                                    onChange={async (_, { value }) => { 
                                        onUpdateUser(index, {...users[index], ratio: Number(value)});
                                        onChange({ target: { name, value } }) 
                                    }}
                                    placeholder='Select percentage'
                                    options={percentageOptions}
                                    style={{height: '40px', width: '200px'}}
                                />
                            )}  
                        />
                    )}
                </div>
                <Icon 
                    onClick={() => {
                        onDeleteUser(index);
                    }} 
                    name='minus circle'
                    size='large'
                    style={{marginLeft: '20px'}}
                    className='roundRobin__selectionBox__content__user__remove'
                />
            </div>
        )
    }

    const contentUsers = () => {
        return users.map((item, index) => {return renderContentUser(item, index)})
    }

    const renderSearchBarUsers = (item, index) => {
        return (
        <div className='roundRobin__selectionBox__header__user'>
            <div className='roundRobin__latoLight' style={{padding: 0}}>{item.first_name} {item.last_name}</div>
            <Icon  name='x' color='grey' style={{marginLeft: '10px'}} onClick={() => {
                onDeleteUser(index);
                }}/>
        </div>
        )
    }

    const searchBarUsers = () => {
        return users.map((item, index) => {return renderSearchBarUsers(item, index)})
    }

    useEffect(() => {
        const searchResults = formatSearchResults();
        setDisplayedOptions(searchResults);
    }, [search, users, searchContent])

    return (
        <div className='roundRobin' style={{whiteSpace: 'normal'}}>
            <div className='roundRobin__table'>
                <div style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: isDistributionTeamMessageOn ? "center" : 'flex-end',
                    flex: 1,
                    marginBottom: isDistributionTeamMessageOn ? 15 : 0
                }}>
                    <div className='roundRobin__latoBlack' style={{padding: 0, margin: 0}} >Distribution Type:</div>
                    <div className='roundRobin__latoBlack'>Round Robin</div> 
                </div>
                <div>
                    <Controller
                        name={`${parentName}.name`}
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: {error} }) => (
                            <Form.Input style={{height: '40px', width:'400px'}}
                                name={name}
                                label="Distribution Team Name:"
                                value={value}
                                onChange={(_, { value }) => {
                                    onChange({ target: { name, value } })
                                    onChangeField(value, "name")
                                }}
                                required
                                error={
                                    error && error?.message && {
                                        content: error.message,
                                        pointing: 'above',
                                    }
                                }
                                placeholder='Enter Distribution Team Name'
                                onBlur={onUniqueRoutingCheck}
                            />
                        )}
                    />
                </div>
                <div style={{minWidth: 200}}>
                    {watchedWeighted && (
                        <Controller 
                            name={`${parentName}.period`}
                            control={control}
                            render={({ field: { name, value, onChange }, fieldState: { error }}) => (
                                <Form.Dropdown
                                    control={Select}
                                    options={resetOptions} 
                                    value={value} 
                                    style={{height: '40px'}}
                                    placeholder='Select'
                                    label="Reset Distribution:"
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                        onChangeField(value, "period")
                                    }}
                                    error={
                                        error && error?.message && ({
                                            content: error.message,
                                            pointing: 'below'
                                        })
                                    }
                                
                                />
                            )}
                        />
                    )}
                </div>     
            </div>


            <div className='roundRobin__selectionBox'>
                <div className='roundRobin__selectionBox__header'>
                    {!isSelectedEmpty ? <div className='roundRobin__selectionBox__header__selectedSection'>
                        {searchBarUsers()}
                    </div> : null}

                    <div className='roundRobin__selectionBox__header__searchSection' >
                        <div className='roundRobin__selectionBox__header__searchSection__box'>
                            <Dropdown
                                fluid
                                selection
                                open={open}
                                search
                                iconPosition='left'
                                placeholder='Add by searching for users, teams, or territories'
                                trigger={
                                    <div>
                                      <Icon name='search plus' className='roundRobin__grey' style={{ marginLeft: '5px', marginRight: '5px' }} />
                                      {!search && <span className='roundRobin__placeholder'>Add by searching for users, teams, or territories</span>}
                                    </div>
                                  }
                                onClick={() => { setOpen(true) }}
                                onBlur={() => { setOpen(false); setSearch(''); }}
                                style={{ borderWidth: 0 }}
                                onSearchChange={(e, { searchQuery }) => {
                                    setSearch(searchQuery)
                                }}
                            >
                                <Dropdown.Menu>
                                    {displayedOptions.map((user) => (
                                        <Dropdown.Item className='DYLFieldsDropdown__Item' value={user.name} onClick={() => onClickSearchItem(user)}>
                                            <div className='roundRobin__dropDownItem'>
                                                <div style={{width: "70%"}}>{user.name}</div>
                                                <div>{renderTeams(user.teams)}</div>
                                            </div>
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        { !isSelectedEmpty  ? <ButtonLink className='roundRobin__latoBlack roundRobin__pink' style={{padding: 0}} onClick={()=> emptySelection()}> 
                            Remove all selected
                        </ButtonLink> : null }
                    </div>
                </div>


                {!isSelectedEmpty ? <div className='roundRobin__selectionBox__content'>
                    {contentUsers()}
                </div> : 
                <div className='roundRobin__selectionBox__content -empty'> 
                    <h5>No Users Added</h5>
                    <div className='roundRobin__latoLight'> Add a User by using the search bar above</div>
                </div>}


                <div className='roundRobin__selectionBox__footer'>
                    <Controller 
                        name={`${parentName}.weighted`}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <Select
                                name={name}
                                value={value}
                                onChange={(_, { value }) => { 
                                    onChange({ target: { name, value } }) 
                                    onChangeField(value, "weighted");
                                    handleDistributionChange(value)
                                    trigger("routings")
                                }}
                                options={distributionOptions}
                                style={{height: '40px', marginRight: '20px'}}
                            />
                        )} 
                    />
                    {!!watchedWeighted && (
                        <div className='roundRobin__selectionBox__footer__total'>
                            <div className='roundRobin__latoBlack' style={{marginRight: '30px'}}>Total</div>

                            <div className='roundRobin__selectionBox__footer__total__container'>
                                <div className={(totalPercent >  100 || totalPercent < 100) && users.length > 0 ? 'roundRobin__selectionBox__footer__total__box -error'
                                : 'roundRobin__selectionBox__footer__total__box'}>
                                    <div>{totalPercent}</div>
                                    <Icon name='percent' color='grey'/>
                                </div>
                                {(totalPercent > 100 || totalPercent < 100) && users.length > 0 ? 
                                    <div className='roundRobin__latoBold roundRobin__selectionBox__footer__total__errorText'> 
                                    Distribution sum must equal 100% 
                                </div> : null}
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
};

export default RoundRobin;
