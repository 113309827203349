import React from 'react';
import { List, Popup } from 'semantic-ui-react';

import './index.scss';

const HoverList = ({ list, position='bottom left', size='small', scroll=false }) => {
    return (
        <Popup
            className='HoverListComponent'
            basic
            on='hover'
            hoverable={true}
            size={size}
            position={position}
            trigger={<div className='HoverListComponent__Trigger'>{list.map((item, index) => <span key={index}>{item}{index < list.length - 1 && ','} </span>)}</div>}
            content={
                <div className={scroll? 'HoverListComponent__Container__scroll' : 'HoverListComponent__Container'}>
                    <List className='Hover_Items'>
                        {list?.map((item, index) => (
                            <List.Item key={index}>{item}</List.Item>
                        ))}
                    </List>
                </div>
            }
        />
    );
}

export default HoverList;
