import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Dropdown, Form } from "semantic-ui-react";
import { Controller, useForm } from "react-hook-form";
import { generateResolver, yup } from "dyl-components/atoms/ValidationUtils";

import "./index.scss";
import { ClippedContent } from "dyl-components/atoms/ClippedContent";

const validator = yup.string().minlength(2).maxlength(64).simple_alphanumeric_w_dividers().no_excessive_whitespaces().no_whitespace_only();

const Option = ({
    isEditingOption,
    option,
    onEditOptionName,

    optionsFieldName,
    index,

    control,
    removeOption,

    duplicateCheck,
    categories,

    onUpdateOption,
    isOnBlurReset,
    setIsOnBlurReset
}) => {
    const {
        formState: {
            isDirty: isEditOptionFormDirty,
            isValid: isEditOptionFormValid,
        },
        control: editOptionControl,
        reset: optionEditReset,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            name: option.name,
        },
        resolver: generateResolver({
            name: validator.test(
                "no_repeating_category",
                "Parent value already in the list",
                duplicateCheck("name", categories, async (value) => {
                    return (
                        value === "" ||
                        value === option.name ||
                        !(await validator.isValid(value))
                    );
                })
            ),
        }),
    });

    useEffect(() => {
        if (isOnBlurReset) {
            optionEditReset({
                name: option.name,
            });
            onEditOptionName(null);
            setIsOnBlurReset(false)
        }
    }, [isOnBlurReset])

    return (
        <Controller
            name={`${optionsFieldName}[${index}].name`}
            control={control}
            render={({
                field: { name, value, onChange: onConfirmEditOptionName },
            }) => (
                <Controller
                    name="name"
                    control={editOptionControl}
                    defaultValue={value}
                    render={({
                        field: {
                            name: categoryEditFieldName,
                            value: categoryEditFieldValue,
                            onChange: categoryEditOnChange,
                        },
                        fieldState: { error },
                    }) => (
                        <Dropdown.Item
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Grid columns="equal" verticalAlign="middle">
                                <Grid.Column>
                                    {isEditingOption ? (
                                        <Form.Input
                                            value={categoryEditFieldValue}
                                            onChange={(_, { value }) => {
                                                categoryEditOnChange({
                                                    target: {
                                                        name: categoryEditFieldName,
                                                        value,
                                                    },
                                                });
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            error={error?.message}
                                            onKeyDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    ) : (
                                        <ClippedContent>{value}</ClippedContent>
                                    )}
                                </Grid.Column>
                                <Grid.Column textAlign="right">
                                    {!isEditingOption && (
                                        <Icon
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEditOptionName(option.id);
                                            }}
                                            className="fas fa-pencil EditableOptions__edit-control"
                                            link
                                            color="blue"
                                        />
                                    )}
                                    {isEditingOption && (
                                        <React.Fragment>
                                            <Icon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    optionEditReset({
                                                        name: value,
                                                    });
                                                    onEditOptionName(null);
                                                }}
                                                color="red"
                                                className="fas fa-times EditableOptions__edit-control"
                                                link
                                            />
                                            <Icon
                                                disabled={
                                                    !isEditOptionFormDirty ||
                                                    !isEditOptionFormValid
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onConfirmEditOptionName({
                                                        target: {
                                                            name,
                                                            value: categoryEditFieldValue,
                                                        },
                                                    });
                                                    onUpdateOption(index, {
                                                        ...option,
                                                        name: categoryEditFieldValue,
                                                    });
                                                    optionEditReset({
                                                        name: categoryEditFieldValue,
                                                    });
                                                    onEditOptionName(null);
                                                }}
                                                color="blue"
                                                className="fas fa-check EditableOptions__edit-control"
                                                link
                                            />
                                        </React.Fragment>
                                    )}
                                    {!isEditingOption && (
                                        <Icon
                                            color="red"
                                            className="fas fa-trash EditableOptions__edit-control"
                                            link
                                            onClick={e => {
                                                e.stopPropagation();
                                                removeOption(index);
                                            }}
                                        />
                                    )}
                                </Grid.Column>
                            </Grid>
                        </Dropdown.Item>
                    )}
                />
            )}
        />
    );
};

const EditableOptions = ({
    control,
    optionsFieldName,
    fieldName,
    optionsToEdit,
    addOption,
    removeOption,
    updateOption,
    error
}) => {
    const [optionBeingEdited, setOptionBeingEdited] = useState(null);
    const [isOnBlurReset, setIsOnBlurReset] = useState(false);

    const onEditOptionName = (id) => {
        setOptionBeingEdited(id);
    };

    const duplicateCheck = (property, list, checkIfExempted) =>
        async function (value) {
            const uniqueData = Array.from(
                new Set(
                    [...list, { [property]: value }].map((item) =>
                        item[property].toLowerCase()
                    )
                )
            );
            const isUnique = list.length + 1 === uniqueData.length;
            if (isUnique) {
                return true;
            }
            if (checkIfExempted && (await checkIfExempted(value))) {
                return true;
            }
            return this.createError({
                path: property,
                message: "Already in the list",
            });
        };

    const {
        formState: {
            isDirty: isAddOptionFormDirty,
            isValid: isAddOptionFormValid,
        },
        control: addOptionControl,
        reset: addOptionReset,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            name: "",
        },
        resolver: generateResolver({
            name: validator.test(
                "no_new_repeating_category",
                "Value already in the list",
                duplicateCheck("name", optionsToEdit, async (value) => {
                    return value === "" || !(await validator.isValid(value));
                })
            ),
        }),
    });

    const onAddOption = (value) => {
        addOption({ id: Math.random(), name: value, new: true });
        addOptionReset({ name: "" });
    };

    return (
        <Form.Dropdown
            placeholder={fieldName || "Field Name"}
            selection
            fluid
            label={`${fieldName || "Field"} Values`}
            onBlur={() => {
                setIsOnBlurReset(true);
            }}
            error={error}
        >
            <Dropdown.Menu>
                <Dropdown.Item
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <Controller
                        name="name"
                        control={addOptionControl}
                        render={({
                            field: { name, value, onChange },
                            fieldState: { error },
                        }) => (
                            <Form.Input
                                onChange={(_, { value }) => {
                                    onChange({
                                        target: { name, value },
                                    });
                                }}
                                placeholder="Type value"
                                basic
                                icon={
                                    <Button
                                        disabled={
                                            !isAddOptionFormValid ||
                                            !isAddOptionFormDirty
                                        }
                                        icon={
                                            <Icon
                                                color="blue"
                                                className="fas fa-plus-circle"
                                            />
                                        }
                                        onClick={() => {
                                            onAddOption(value);
                                        }}
                                        basic
                                    />
                                }
                                iconPosition="right"
                                value={value}
                                error={error?.message}
                                disabled={
                                    optionBeingEdited ||
                                    optionBeingEdited
                                }
                                onKeyDown={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        )}
                    />
                </Dropdown.Item>
                {optionsToEdit.map((option, index) => (
                    <Option
                        optionsFieldName={optionsFieldName}
                        option={option}
                        index={index}
                        isEditingOption={
                            optionBeingEdited === option.id
                        }
                        onEditOptionName={onEditOptionName}
                        updateOption={updateOption}
                        control={control}
                        removeOption={removeOption}
                        key={option.id}
                        duplicateCheck={duplicateCheck}
                        categories={optionsToEdit}
                        onUpdateOption={updateOption}
                        isOnBlurReset={isOnBlurReset}
                        setIsOnBlurReset={setIsOnBlurReset}
                    />
                ))}
            </Dropdown.Menu>
        </Form.Dropdown>
    );
};

export default EditableOptions;
