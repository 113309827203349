import React from 'react';
import { Button } from 'semantic-ui-react';

import './index.scss';

const CustomModalButton = ({ children, className, active, ...otherProps }) => (
    <Button {...otherProps} fluid className={`CustomModalButton ${active ? 'CustomModalButton--active' : ''} ${className || ''}`}>
        {children}
    </Button>
);

export default CustomModalButton;
