import React from 'react';

const withSorting = (WrappedTable) => class extends React.Component {
    state = {
        sortDirection: null,
        column: null
    }

    generateSortingProperties = (column) => {
        return {
            onClick: this.onSort(column),
            sorted: this.getSortDirectionForColumn(column)
        }
    }

    onSort = (clickedColumn) => () => {
        const { column, sortDirection } = this.state;
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                sortDirection: 'ascending',
            }, () => {
                if (this.props.onSort) {
                    this.props.onSort(clickedColumn, this.state.sortDirection);
                }
            });
        } else {
            this.setState({
                sortDirection: sortDirection === 'ascending' ? 'descending' : sortDirection === null ? 'ascending' : null
            }, () => {
                if (this.props.onSort) {
                    this.props.onSort(clickedColumn, this.state.sortDirection);
                }
            })
        }
    }

    getSortDirectionForColumn = (column) => {
        return column === this.state.column ? this.state.sortDirection : null;
    }

    render() {
        return (
            <WrappedTable 
                {...this.props}
                generateSortingProperties={this.generateSortingProperties}
            />
        )
    }
}

export default withSorting;
