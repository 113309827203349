import React, { useState } from 'react';
import { EditableContent } from 'dyl-components';
import { Grid, Icon, Segment, Dropdown } from 'semantic-ui-react';
import { ControlledPopup } from 'dyl-components';

import './index.scss';

const HEX_COLORS = {
    '#FFF4D3': 'yellow',
    '#F2FCF7': 'green',
    '#F6F1FB': 'purple',
    '#FAE9EC': 'pink',
}

const CHARACTER_LIMIT = 25;

const StickyNote = ({
    onChangeColor,
    onSave,
    onDelete,
    isLoading = false,
    isEditing,
    id,
    title = '',
    content = '',
    color = '#FFF4D3',
    timestamp = '',
    size = '',
    isDeleteable = true,
    existing = false,
    collapsed = false
}) => {

    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const [noteTitle, setTitle] = useState(existing ? title || 'No Title' : '');
    const [noteContent, setContent] = useState(content);
    const [noteColor, setColor] = useState(HEX_COLORS[color]);
    const [noteTimestamp] = useState(timestamp);

    const [onEditMode, setOnEditMode] = useState(!existing);

    const getHexByColor = (color) => {
        return Object.keys(HEX_COLORS).find(key => HEX_COLORS[key] === color);
    }

    const onChangeNoteColor = (color) => {
        if (onChangeColor && existing && !onEditMode) {
            onChangeColor({ id, color: getHexByColor(color), note: noteContent, title: noteTitle });
        } else {
            setColor(color);
        }
    }

    const onEdit = () => {
        setOnEditMode(true);
        setIsCollapsed(false);
        setTitle(existing ? noteTitle !== 'No Title' ? noteTitle : '' : '');
    }

    const onConfirmEdit = () => {
        if (onSave) {

            const formattedTitle = noteTitle.replace(/\s+/g, ' ').trim();
            const note = {
                id,
                title: formattedTitle || 'No Title',
                content: noteContent || 'No Notes',
                color: getHexByColor(noteColor),
                existing
            }

            onSave(note);
        }
    }

    const onCancelEdit = () => {
        setOnEditMode(false);
        setTitle(title);
        setContent(content);
        if (!existing && onDelete) {
            onDelete(id, existing);
        }
    }

    const onDeleteNote = () => {
        if (onDelete) {
            onDelete(id, existing);
        }
    }

    return (
        <Segment size={size} tertiary basic inverted color={noteColor} className="StickyNote" loading={isLoading} >
            <div className={`StickyNote__note StickyNote__note--${isEditing ? 'edit' : 'display'}`}>
                <Grid>
                    <Grid.Row columns='equal' verticalAlign='middle' className='StickyNote__titleRow'>
                        <Grid.Column width={onEditMode ? 12 : 11} stretched onClick={() => { !onEditMode ? setIsCollapsed(!isCollapsed) : () => { }; }}>
                            <EditableContent
                                control={'input'}
                                isEditMode={onEditMode}
                                value={noteTitle}
                                placeholder={onEditMode ? 'No Title' : ''}
                                onChange={(e) => {
                                    if (e.target.value.length <= CHARACTER_LIMIT) {
                                        setTitle(e.target.value);
                                    }
                                }
                                }
                                autoFocus={onEditMode}
                                size={size}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign='right' verticalAlign='top'>
                            <div className={`StickyNote__controls StickyNote__controls${onEditMode ? '--edit' : ''}`}>
                                {
                                    !onEditMode &&
                                    <Dropdown icon={<Icon className='fa-solid fa-palette' size={size} color='black' link />}>
                                        <Dropdown.Menu>
                                            <Dropdown.Item selected={noteColor === 'yellow'} onClick={() => { onChangeNoteColor('yellow') }}>Yellow</Dropdown.Item>
                                            <Dropdown.Item selected={noteColor === 'green'} onClick={() => { onChangeNoteColor('green') }}>Green</Dropdown.Item>
                                            <Dropdown.Item selected={noteColor === 'purple'} onClick={() => { onChangeNoteColor('purple') }}>Purple</Dropdown.Item>
                                            <Dropdown.Item selected={noteColor === 'pink'} onClick={() => { onChangeNoteColor('pink') }}>Pink</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }

                                {onEditMode ? (
                                    <React.Fragment>
                                        <Icon size={size} name='times' color='red' link onClick={onCancelEdit} />
                                        <Icon size={size} name='check' color='blue' link onClick={onConfirmEdit} />
                                    </React.Fragment>
                                ) :
                                    <Icon size={size} name='pencil' color='black' link onClick={onEdit} />
                                }
                                {isDeleteable && !onEditMode &&
                                    <ControlledPopup
                                        trigger={<Icon size={size} name='trash' color='black' link />}
                                        onConfirm={onDeleteNote}
                                    />
                                }
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    {!isCollapsed &&
                        <React.Fragment>
                            <Grid.Row columns='equal' verticalAlign='middle' className={`StickyNote__noteRow StickyNote__noteRow${onEditMode ? '--edit' : ''}`}>
                                <Grid.Column width={16}>
                                    <textarea
                                        readOnly={!onEditMode}
                                        value={noteContent}
                                        placeholder={onEditMode ? 'No Notes' : ''}
                                        onChange={(e) => { setContent(e.target.value); }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='StickyNote__controlsRow'>
                                <Grid.Column textAlign='left'>
                                    <span className="StickyNote__timestamp"> {noteTimestamp ? `Last edited ${noteTimestamp}` : ''}</span>
                                </Grid.Column>
                            </Grid.Row>
                        </React.Fragment>
                    }
                </Grid>
            </div>
        </Segment>
    )
};

export default StickyNote;
