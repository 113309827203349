import React from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';

import { ClearableCheckboxDropdown, ListThumbnailViewer, EmptyListPlaceholder, SearchBar } from "dyl-components";

import TextTemplateTable from './subcomponents/TextTemplateTable';

import './index.scss';
import TextTemplateThumbnail from './subcomponents/TextTemplateThumbnail';

const TextTemplates = ({
    templates,

    search,
    onChangeSearch,
    onSearchSubmit,
    onSearchCancel,
    filters,
    filterOptions = [
        { key: 'My Templates', value: 'My Templates', text: 'My Templates' },
        { key: 'Shared Templates', value: 'Shared Templates', text: 'Shared Templates' },
        { key: 'My Favorites', value: 'My Favorites', text: 'My Favorites' }
    ],
    onChangeSelectedFilters,
    categories,
    categoryOptions = [
        { key: 1, value: 1, text: 'Sales' },
        { key: 2, value: 2, text: 'Marketing' },
        { key: 3, value: 3, text: 'Service' },
        { key: 4, value: 4, text: 'General' },
        { key: 5, value: 5, text: 'Billing' }
    ],
    onChangeSelectedCategories,

    onFavorite = () => { },

    onPreview = () => { },
    onEdit = () => { },
    onCopy = () => { },
    onDelete = () => { },
    onOpenAddTemplateModal,

    page,
    count,
    isReading
}) => (
    <ListThumbnailViewer
        header={
            <React.Fragment>
                <Grid.Column width={5} style={{ padding: 0 }}>
                    <SearchBar
                        addClassNames='TextTemplate__SearchBar'
                        search={search}
                        isSearching={isReading}
                        searchFcn={onSearchSubmit}
                        cancelFcn={onSearchCancel}
                        onChange={onChangeSearch}
                        placeholder='Search by Text Template Name'
                    />
                </Grid.Column>
                <Grid.Column width={3} style={{ padding: 0, marginLeft: 15 }}>
                    <ClearableCheckboxDropdown
                        options={filterOptions}
                        name='filters'
                        onChange={onChangeSelectedFilters}
                        value={filters}
                        selection
                        text={<span>Filters{filters && filters.length ? <b className='TextTemplate__FilterDisplay'> {filters.length} Selected</b> : ''}</span>}
                        fluid={true}
                    />
                </Grid.Column>
                <Grid.Column width={3} style={{ padding: 0, marginLeft: 10 }}>
                    <ClearableCheckboxDropdown
                        options={categoryOptions}
                        name='categories'
                        onChange={onChangeSelectedCategories}
                        value={categories}
                        selection
                        text={<span>Category{categories && categories.length ? <b className='TextTemplate__FilterDisplay'> {categories.length} Selected</b> : ''}</span>}
                        fluid={true}
                    />
                </Grid.Column>
                <Grid.Column width={3} floated='right' style={{ padding: 0 }}>
                    <Button onClick={onOpenAddTemplateModal} floated='right' className='TextTemplate__NewTemplateButton'>+ New Text Template</Button>
                </Grid.Column>
            </React.Fragment>
        }
        listView={
            <TextTemplateTable
                count={count}
                isReading={isReading}
                templates={templates}
                isSearchEmpty={!search}
                onOpenAddTemplateModal={onOpenAddTemplateModal}
                onPreview={onPreview}
                onEdit={onEdit}
                onCopy={onCopy}
                onDelete={onDelete}
                onFavorite={onFavorite}
            />
        }
        thumbnailView={
            <React.Fragment>
                {!isReading && count === 0 ?
                    <Grid.Column width={16} textAlign='center'>
                        <EmptyListPlaceholder name='Text Template' onClick={onOpenAddTemplateModal} />
                    </Grid.Column>
                    :
                    <TextTemplateThumbnail
                        key={page}
                        isReading={isReading}
                        templates={templates}
                        onPreview={onPreview}
                        onEdit={onEdit}
                        onCopy={onCopy}
                        onDelete={onDelete}
                        onFavorite={onFavorite}
                    />
                }
            </React.Fragment>
        }
    />
)

export default TextTemplates;

