import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import './index.scss';

const tagOptions = [

    {
        key: 'Busy',
        text: 'Busy',
        value: 'Busy',
        label: { color: 'yellow', empty: true, circular: true },
    },
    {
        key: 'Away',
        text: 'Away',
        value: 'Away',
        label: { color: 'red', empty: true, circular: true },
    }
]

const HeaderGrid = () => (
    <Menu className="Chat__NavHeader">
        <Menu.Header>Carlson's Inc.</Menu.Header>
        <Menu.Menu position='right'>
            <Dropdown item icon='setting' className="Chat__StatusIcon">
                <Dropdown.Menu>
                    {tagOptions.map((option) => (
                        <Dropdown.Item key={option.value} {...option} />
                    ))}
                    <Dropdown.Divider label="Custom status" />
                    <Dropdown.Item text='Custom' />
                    <Dropdown.Item text='Edit' />
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Menu>
    </Menu>
)

class NavHeader extends React.Component {


    render() {
        return (
            <HeaderGrid />
        )
    }
}

export default NavHeader;