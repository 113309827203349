import React from 'react';
import { Icon } from 'semantic-ui-react';

import './index.scss';

const RECORD_ICON = {
    person: 'male',
    household: 'home',
    business: 'building'
}

const RecordType = ({
    record_type
}) => (
    <React.Fragment>
        <div className='RecordType__label'>
            <span>
                Record Type
            </span>
        </div>
        <div className='RecordType__type'>
            <Icon name={RECORD_ICON[record_type]} color='grey' className='RecordType__icon' />
            <span>
                {record_type}
            </span>
        </div>
    </React.Fragment>
);

export default RecordType;
