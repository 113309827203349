import React from 'react';
import { Modal, Transition } from 'semantic-ui-react';

import './index.scss';
import { Button, CloseableContent } from 'dyl-components';

const StyledModal = ({ open, onClose, children, animated = true, trigger, size, className, onOpen = () => { }, closeIcon = true }) => (
    <Transition visible={open} animation={animated ? 'slide down' : ''}>
        <Modal onOpen={onOpen} open={open} onClose={onClose} className={`Modal ${className}`} size={size} trigger={trigger} closeOnDimmerClick={false}>
            <CloseableContent position='static' onClose={onClose} closeIcon={closeIcon}>
                {children}
            </CloseableContent>
        </Modal>
    </Transition>
)

StyledModal.Header = ({ children, basic }) => (
    <Modal.Header className={`Modal__header Modal__header${basic ? '--basic' : ''}`} >
        {children}
    </Modal.Header>
)

StyledModal.Content = ({ className, children, ...props }) => (
    <Modal.Content className={`Modal__content${className ? ` ${className}` : ''}`} {...props}>
        {children}
    </Modal.Content>
)

StyledModal.Description = ({ children }) => (
    <Modal.Description>
        {children}
    </Modal.Description>
)

StyledModal.Actions = ({ children, hasSaveButton = false, onSave = () => {}, saveDisabled = false, saveOptions = {}, primaryChildren = [] }) => {
    return (
        <Modal.Actions>
            <div className='Modal__container'>
                <div className='Modal__tertiary'>
                    {children}
                </div>
                <div className={`Modal__primary${hasSaveButton && children ? ' Modal__primary--border' : ''}`}>
                    {primaryChildren.map((component) => component)}
                    {hasSaveButton && (
                        <Button 
                            primary
                            onClick={onSave}
                            disabled={saveDisabled}
                            {...saveOptions} 
                        >
                            {saveOptions.label ? saveOptions.label : 'Save'}
                        </Button>
                    )}
                </div>
            </div>
        </Modal.Actions>
    )
}

export default StyledModal;
