import React, { Component } from "react";
import { Form, TextArea, Menu, Icon, Button } from "semantic-ui-react";

export default class MessageInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
        };
    }

    onInput = (event, data) => {
        this.setState({
            message: data.value,
        });
    };

    handleEnter = (e) => {

        if (e.keyCode === 13 && !e.shiftKey) {
            this.clickSend();
        }

    };
    clickSend = () => {
        const newMessage = this.state.message.trim();

        if (newMessage.length > 0) {
            this.props.sendMessage(newMessage);
            this.setState({ message: "" });
        }
    };

    render() {

        const placeHolder = `Message in ${this.props.channelName}`;

        return (
            <Menu borderless className="MessageInput">
                <Menu.Item as={Button}>
                    <Icon name="paperclip" />
                </Menu.Item>

                <Form style={{ width: "-webkit-fill-available" }}>
                    <TextArea
                        placeholder={placeHolder}
                        style={{ minHeight: 100, border: "none" }}
                        onInput={this.onInput}
                        onKeyDown={this.handleEnter}
                        value={this.state.message}
                    />
                </Form>
                <Menu.Item
                    as={Button}
                    style={{ marginRight: "20px" }}
                    onClick={this.clickSend}
                >
                    <Icon name="plus" />
                </Menu.Item>
            </Menu>
        );
    }
}
