import React from 'react';

import './index.scss';
import STATUS_TYPES from '../../constants/StatusTypes';

const ButtonLink = ({ onClick, children, status = STATUS_TYPES.PRIMARY, className, noPadding, ...otherProps }) => (
    <button {...otherProps} onClick={onClick} className={`ButtonLink${noPadding ? ` ButtonLink--noPadding` : ''} ButtonLink--${status} ${className}`} type='button' >
        {children}
    </button>
)

export default ButtonLink;
