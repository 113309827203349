import React, { Component } from 'react';
import { Grid, Accordion, Icon, List } from 'semantic-ui-react';

import AddChannel from './addChannel';
import './index.scss';

const Channel = ({
    activeChannel,
    feedList,
    channelGroupInfo
}) => {
   
    const onToggleChannel = ()=> {
        channelGroupInfo.toggleChannelGroup()
     };

    const getName = (name) => {
      
        /**TODO:  hookup to state value  */
        console.log(`|+| Click on the ${name} channel`);
        activeChannel.toggleChannel(name)
    }
 

    feedList.sort((a, b) => (a.name > b.name ? 1 : -1));
    //figure out if we want to sort automatically 

    return (
        <Grid.Column>
            <Accordion className='Chat__NavLeftMargin'>
                <Accordion.Title
                    onClick={onToggleChannel}
                    className='Chat__AccordTitleInline'
                >
                    <Icon name={channelGroupInfo.isChannelGroupOpen ? 'chevron down' : 'chevron right'} className='Chat__ChannelArrow' />
                    <span className='Chat__ChannelTitle'>Channels</span>
                </Accordion.Title>
                <span className='Chat__AddChannel'>
                    <AddChannel />
                </span>
                <span className='Chat__Count'>{feedList.length}</span>
                <Accordion.Content active={channelGroupInfo.isChannelGroupOpen}>
                    <List className="Chat__ChannelList">
                        {feedList !== undefined && (
                            feedList.map((channel, i) => (
                                <List.Item key={i}>
                                    <List.Icon name={(channel.isPrivate) ? 'lock' : 'hashtag'} verticalAlign='middle' className='Chat__Hashtag' />
                                    <List.Content
                                        className={(activeChannel.currentChannelId === channel.name ? 'Chat__ChannelName active' : 'Chat__ChannelName')}
                                        onClick={() => getName(channel.name)}
                                    >{channel.name}</List.Content>
                                </List.Item>
                            )))}
                    </List>
                </Accordion.Content>
            </Accordion>
        </Grid.Column>
    )
};


export default class Channels extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            toggleChannelGroup: this.toggleChannelGroup,
            isChannelGroupOpen: true,
        }
    }

    toggleChannelGroup = () => {
        this.setState(prevState => ({
            isChannelGroupOpen: !prevState.isChannelGroupOpen
        }))
    }

    render() {
    
        const feedList  = this.props.feed
        const activeChannel = this.props.channelInfo
        const channelGroupInfo = {
            isChannelGroupOpen: this.state.isChannelGroupOpen,
            toggleChannelGroup: this.state.toggleChannelGroup
     
        }

        return (
            <Channel
                activeChannel={activeChannel}
                feedList={feedList.channels}
                channelGroupInfo={channelGroupInfo}
            />
        )
    }
}
