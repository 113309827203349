import React from 'react';
import { Table, TableLoader, EmptyListPlaceholder } from 'dyl-components';
import { Segment, Header } from 'semantic-ui-react';
import EmailTemplatesRow from './Row';

import './EmailTemplateTable.scss';

let TemplatesTable = ({ isReading, templates, onPreview, onEdit, onCopy, onDelete, onFavorite }) => (
    <Table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell width={1}></Table.HeaderCell>
                <Table.HeaderCell width={3}>Email Template</Table.HeaderCell>
                <Table.HeaderCell width={8}>Subject/Body Copy</Table.HeaderCell>
                <Table.HeaderCell width={3}>Created</Table.HeaderCell>

                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {isReading ? <TableLoader isLoading colspan={6} /> : (
                templates.map((email_template) => (
                    <EmailTemplatesRow
                        key={email_template.id}
                        email_template={email_template}
                        onPreview={onPreview}
                        onEdit={onEdit}
                        onCopy={onCopy}
                        onDelete={onDelete}
                        onFavorite={onFavorite} />
                ))
            )}
        </Table.Body>
    </Table>
);

const EmailTemplateTable = ({
    templates,
    isSearchEmpty,
    isReading,
    count,
    onPreview,
    onEdit,
    onCopy,
    onDelete,
    onFavorite,

    onOpenAddTemplateModal
}) => {
    return (
        <div className='EmailTemplates'>
            <Segment.Group vertical>
                <Segment style={{ padding: 0 }}>
                    <TemplatesTable
                        isReading={isReading}
                        templates={templates}
                        onPreview={onPreview}
                        onEdit={onEdit}
                        onCopy={onCopy}
                        onDelete={onDelete}
                        onFavorite={onFavorite}
                    />
                </Segment>

                {!isReading && count === 0 && (!isSearchEmpty ? <Header as='h3'>No Results Found</Header> :
                    <Segment>
                        <EmptyListPlaceholder name='Email Template' onClick={onOpenAddTemplateModal} />
                    </Segment>)}
            </Segment.Group>
        </div>
    )
}

export default EmailTemplateTable;
