import React, { useEffect, useRef, useState } from 'react';
import {Icon, Popup} from 'semantic-ui-react';
import './index.scss';

const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };
  
    useEffect(() => {
      document.addEventListener("click", handleClick);
  
      return () => {
        document.removeEventListener("click", handleClick);
      };
    });
};

const OnHoverOnClick = ({trigger, hoverContent, clickContent, position = 'bottom center', onHoverTimeout = 1500, invertedHover=false, onClick, display='block', basic=true, pinned=false, open, className='' }) => {

    const [isClickOpen, setIsClickOpen] = useState(false)
    const [isHoverOpen, setIsHoverOpen] = useState(false)
    
    const ref = useRef();

    //Used for onClick and open props only
    if(onClick && !open && isClickOpen){
        setIsClickOpen(false);
    }

    useOutsideClick(ref, () => {
        if (isClickOpen && !onClick) {
            setIsClickOpen(false);
        }
      });

    const emptyPersonAvatar = (
        <div className='onHoverOnClick__roundAvatar'>
            <Icon name="user" size='big' style={{marginLeft: "3px"}} className='onHoverOnClick__white'/>
        </div>
    )

    return (
        <div ref={ref} style={{ width: "fit-content", display: display}}>
            <Popup
                className={className}
                trigger={
                    <div 
                        style={{ width: "fit-content", display: display }} 
                        onClick={() => {
                            setIsClickOpen(prevState => (
                                !prevState
                            ));
                            onClick && onClick();
                        }}
                    >
                        {trigger ? trigger : emptyPersonAvatar}
                    </div>
                    }
                content={
                    <div>
                        {isClickOpen ? clickContent : hoverContent}
                    </div>}
                position={position}
                open={onClick ? (open || isHoverOpen)  : (isClickOpen || isHoverOpen)}
                onOpen={() => {
                    if (!isClickOpen) {
                        setIsHoverOpen(true);
                    }
                }}
                hoverable
                onClose={() => {
                    if (!isClickOpen) {
                        setIsHoverOpen(false);
                    }
                }}
                basic={basic}
                inverted={(invertedHover && !isClickOpen)}
                pinned={pinned}
            />
        </div>
    )
}
export default OnHoverOnClick;