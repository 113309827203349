import React, { useState } from 'react';
import { Dropdown, Header, Input } from 'semantic-ui-react';

import './index.scss';

const commonFields = [
    {
        name: "Name",
        isSubCategory: true,
        fields: [
            { name: 'First Name' },
            { name: 'Last Name' }
        ]
    },
    {
        name: 'Phone',
        isSubCategory: true,
        fields: [
            { name: 'Home' },
            { name: 'Mobile' },
            { name: 'Fax' },
            { name: 'Other' }
        ]
    },
    { name: 'Primary Email' },
    { name: 'Secondary Email' },
    {
        name: 'Address 1',
        isSubCategory: true,
        fields: [
            { name: '1- Address Label' },
            { name: '1- Street' },
            { name: '1- Apt/Suite/No' },
            { name: '1- City' },
            { name: '1- State' },
            { name: '1- Zip Code' }
        ]
    },
    {
        name: 'Address 2',
        isSubCategory: true,
        fields: [
            { name: '2- Address Label' },
            { name: '2- Street' },
            { name: '2- Apt/Suite/No' },
            { name: '2- City' },
            { name: '2- State' },
            { name: '2- Zip Code' }
        ]
    },
    {
        name: 'Address 3',
        isSubCategory: true,
        fields: [
            { name: '3- Address Label' },
            { name: '3- Street' },
            { name: '3- Apt/Suite/No' },
            { name: '3- City' },
            { name: '3- State' },
            { name: '3- Zip Code' }
        ]
    },
    {
        name: 'Social Media',
        isSubCategory: true,
        fields: [
            { name: 'Facebook' },
            { name: 'LinkedIn' },
            { name: 'Twitter' }
        ]
    },
    { name: 'Date of Birth' },
    { name: 'Interests' },
    { name: 'Job Title' },
    { name: 'Department' },
    { name: 'School/Degree' },
    { name: 'Website' },
    { name: 'Created By' },
    { name: 'Assigned To' },
    { name: 'Household Name' },
    { name: 'Household Type' },
    {
        name: 'Household Income',
        isSubCategory: true,
        fields: [
            { name: 'Monthly Household Income' },
            { name: 'Annual Household Income' }
        ]
    },
    { name: 'Number of Children' },
    { name: 'Business Name' },
    { name: 'State of Org' },
    { name: 'Annual Revenue' },
    { name: 'Number of Employees' }
];

const leadFields = [
    ...commonFields,
    { name: 'Prduct Interests' },
    { name: 'Master Source' },
    { name: 'Lead Stage' },
    { name: 'Campaign Source' },
    { name: 'Exit Reason' },
    { name: 'Lead Priority' },
    { name: 'Lead Rating' }
]

const FieldsDropdown = ({ isModuleLeads = true }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [text, setText] = useState('');
    const [search, setSearch] = useState('');

    const onClickItem = (_, { value, children }) => {
        setText(children);
        setValue(value);
        setOpen(false);
    }

    const renderStandardFields = () => {
        const options = [];

        (isModuleLeads ? leadFields : commonFields).forEach(index => {
            const searchRegex = new RegExp(search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');

            if (index.isSubCategory) {
                const isSubCategoryMatch = index.name.match(searchRegex);
                const fieldSearchIndex = index.fields.findIndex(index => index.name.match(searchRegex) !== null);

                if (isSubCategoryMatch !== null || fieldSearchIndex !== -1) {
                    options.push(
                        <Dropdown.Header className='DYLFieldsDropdown__Subcategory'>
                            <Header color='grey'>{index.name}</Header>
                        </Dropdown.Header>
                    );

                    index.fields.forEach(index => {
                        if (index.name.match(searchRegex) !== null) {
                            options.push(
                                <Dropdown.Item className='DYLFieldsDropdown__SubItem' value={index.value} onClick={onClickItem}>{index.name}</Dropdown.Item>
                            );
                        }
                    });
                }
            } else {
                const isItemMatch = index.name.match(searchRegex);
                if (isItemMatch !== null) {
                    options.push(<Dropdown.Item className='DYLFieldsDropdown__Item' value={index.value} onClick={onClickItem}>{index.name}</Dropdown.Item>);
                }
            }
        });

        return options;
    }

    return (
        <div className='DYLFieldsDropdown'>
            <Dropdown
                fluid
                selection
                open={open}
                text={text}
                value={value}
                onClick={() => { setOpen(true) }}
                onBlur={() => { setOpen(false); setSearch(''); }}
            >
                <Dropdown.Menu>
                    <Input icon='search'
                        iconPosition='left'
                        placeholder='Search for a field'
                        value={search}
                        onChange={(_, { value }) => { setSearch(value); }}
                    />
                    <Dropdown.Header className='DYLFieldsDropdown__CategoryHeader'><Header as='h6'>STANDARD FIELDS</Header></Dropdown.Header>
                    {renderStandardFields()}
                    <Dropdown.Header className='DYLFieldsDropdown__CategoryHeader'><Header as='h6'>CUSTOM FIELDS</Header></Dropdown.Header>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default FieldsDropdown;
