import React from 'react';
import './index.scss';

const DividingHeader = ({ content, primary, compact, capitalized, noline, style }) => (
    <div className={`
        DividingHeader
        ${primary ? " DividingHeader--primary" : ""}
        ${compact ? " DividingHeader--compact" : ""}
        ${capitalized ? " DividingHeader--capitalized": ""}
        ${noline ? "": " DividingHeader hasLine"}`}
        style={style}
    >
        <span className="content">{content}</span>
    </div>
)

export default DividingHeader;
