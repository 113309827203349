import React from 'react';
import { Button, Grid, Form, Divider, Header, Icon } from 'semantic-ui-react';
import { Controller } from 'react-hook-form';
import './index.scss';

const GeneralNotes = ({
    onAddNote,
    isCreatingNote,
    header,
    subHeader='General Notes',
    disabled,
    control,
}) => {
    return (
        <Grid className='NoteFormBody'>
            <Grid.Row style={{paddingBottom: 0}}>
                <Grid.Column>
                    {header && 
                        <>
                            <Header as='h3'>{header}</Header>
                            <Divider />
                        </>
                    }       
                    <Header as='h4' style={{marginTop: 0}}><Icon size='small' name="fa-sharp fa-solid fa-pen-clip" style={{marginRight: 6}}/>{subHeader}</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Controller
                        control={control}
                        name='note'
                        defaultValue={''}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Form.TextArea
                                name={name}
                                rows={4}
                                value={value}
                                onChange={(_, { value }) => { onChange({ target: { name, value } }) }}
                                error={error?.message}
                                placeholder='Type note'
                            />
                        )}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingTop: 0}}>
                <Grid.Column textAlign='right'>
                    <Button loading={isCreatingNote} onClick={onAddNote} width={2} primary content='Save' className="Notes__Button" disabled={disabled} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default GeneralNotes;