import React from 'react';
import * as d3 from 'd3';
import D3Funnel from 'd3-funnel';

import './index.scss';

class ChartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.props.draw(this.getChart());
    }

    componentDidUpdate() {
        if (this.props.update && !this.props.isUpdateDisabled) {
            this.props.update(this.getChart());
        }
    }

    destroyChart() {
        let chart = this.getChart();
        chart.remove();
        return Promise.resolve();
    }

    getChart() {
        if (!this.props.useRef) {
            return d3.select(this.ref.current);
        }
        return this.ref.current;
    }

    render() {
        return (
            <g
                ref={this.ref}
                style={this.props.style}
            />
        );
    }
}

export const Chart = ({ viewBoxWidth, viewBoxHeight, children }) => (
    <svg
        width={'100%'}
        height={'100%'}
        preserveAspectRatio={'xMinYMin'}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    >
        {children}
    </svg>
)

export const XAxis = ({ xScale, height }) => {
    function draw(chart) {
        chart.attr('class', 'Axis__x')
            .attr('transform', `translate(0, ${height})`)
            .call(d3.axisBottom(xScale));
    }

    return (
        <ChartComponent
            draw={(chart) => {
                draw(chart);
            }}
        />
    )
}

export const YAxis = ({ yScale, tickFormat }) => {
    function draw(chart) {
        const axis = d3.axisLeft(yScale);
        if (tickFormat) {
            axis.tickFormat(tickFormat);
        }
        chart.attr('class', 'Axis__y')
            .call(axis);
    }

    return (
        <ChartComponent
            draw={draw}
        />
    )
}

export const Line = ({ data, line, curve, area, animationDuration, strokeWidth = 1, color = 'black', pointConfig = {} }) => {
    function draw(chart) {
        if (curve) {
            line.curve(curve);
        }

        let graph = chart.append('path')
            .datum(data)
            .attr('class', `Line`)
            .attr('d', line)
            .attr('stroke', color)
            .attr('stroke-width', strokeWidth);

        let fill = area ? color : 'none';

        graph.attr('fill', fill);

        return graph;
    }

    function animate(graph) {
        let totalLength = graph.node().getTotalLength();
        graph.attr("stroke-dasharray", totalLength + " " + totalLength)
            .attr("stroke-dashoffset", totalLength)
            .transition()
            .duration(animationDuration * data.length)
            .attr("stroke-dashoffset", 0);
    }

    let { pointSize, cx, cy } = pointConfig;

    function plotPoints(chart) {
        if (pointSize && cx && cy) {
            data.forEach((d, i) => {
                chart.append('circle')
                    .attr('class', `Line__dot`)
                    .attr('fill', color)
                    .attr('stroke', color)
                    .attr('cx', cx(d, i))
                    .attr('cy', cy(d, i))
                    .on('mouseover', function () {
                        d3.select(this)
                            .transition()
                            .duration(100)
                            .attr('r', pointSize * 2);
                    })
                    .on('mouseout', function () {
                        d3.select(this)
                            .transition()
                            .duration(100)
                            .attr('r', pointSize);
                    })
                    .transition()
                    .delay(animationDuration * i)
                    .duration(animationDuration)
                    .attr('r', pointSize);
            });
        }
    }

    return (
        <ChartComponent
            draw={(chart) => {
                let graph = draw(chart);
                animate(graph);
                if (pointSize) {
                    plotPoints(chart);
                }
            }}
        />
    )
}

export const Transformation = ({ transform, children }) => (
    <g transform={transform}>
        {children}
    </g>
)

export const Area = ({ area, width, height, data, curve, stroke, fill, opacity, name, animationDuration }) => {
    if (curve) {
        area.curve(curve);
    }

    function draw(chart) {
        const rectClipId = `${name}-rect-clip`;
        let rectClip = chart.append('clipPath')
            .attr('id', rectClipId)
            .append('rect')
            .attr('width', 0)
            .attr('height', height);
        chart.append('path')
            .datum(data)
            .attr('fill', fill)
            .attr('opacity', opacity)
            .attr('stroke', stroke)
            .attr('d', area)
            .attr('clip-path', `url(#${rectClipId})`);
        rectClip.transition()
            .duration(animationDuration * data.length)
            .attr('width', width);
    }

    return (
        <ChartComponent
            draw={draw}
        />
    )


}

export const Pie = ({ innerRadius, outerRadius, sorted, data, animation, isUpdateDisabled, colorFunction, expanding = true, onMouseOver = () => { }, onMouseOut = () => { } }) => {
    let diameter = (outerRadius + innerRadius) * 2;

    let arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

    let pie = d3.pie().value(d => d.value);

    let _current = null;

    function arcTween(a) {
        let i = d3.interpolate(_current, a);
        _current = i(0);
        return (t) => arc(i(t));
    }

    function update(chart) {
        chart.selectAll("path").data(pie(data)).transition().duration(250)
            .attrTween("d", arcTween);
    }

    function draw(chart) {
        let g = chart.append('g')
            .attr('width', diameter)
            .attr('height', diameter)
            .attr('transform', `translate(${diameter / 2}, ${diameter / 2})`);

        if (!sorted) {
            pie.sort(null);
        }

        let slices = pie(data);

        let color = d3.scaleOrdinal(d3.schemeCategory10);

        let path = g.selectAll('path.slice')
            .data(slices).enter()
            .append('path')
            .attr('class', `PieChart__slice`)
            .attr('fill', d => {
                if (colorFunction === undefined) {
                    return color(d.value);
                }
                return colorFunction(d.data.name);
            })
            .on('mouseover', function (d, data) {
                let mouseOveredSlice = this;
                d3.selectAll(`.PieChart__slice`)
                    .attr('class', function () {
                        return this === mouseOveredSlice ? `PieChart__slice PieChart__slice--active` : `PieChart__slice PieChart__slice--inactive`;
                    });
                d3.select(this).transition(50)
                    .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(outerRadius * (expanding ? 1.1 : 1)));
                onMouseOver(d, data);
            })
            .on('mouseout', function () {
                d3.select(this).transition(50)
                    .attr('d', arc);
                d3.selectAll(`.PieChart__slice`)
                    .attr('class', `PieChart__slice`);
                onMouseOut();
            });



        function animate(path) {
            let enterClockwise = {
                startAngle: 0,
                endAngle: 0
            };

            let enterAntiClockwise = {
                startAngle: Math.PI * 2,
                endAngle: Math.PI * 2
            };

            if (animation) {
                let entryAngles = animation.type === 'clockwise' ? enterClockwise : enterAntiClockwise;
                path.attr('d', arc(entryAngles))
                    .each(d => {
                        _current = {
                            data: d.data,
                            value: d.value,
                            startAngle: entryAngles.startAngle,
                            endAngle: entryAngles.endAngle
                        }
                    });
                path.transition()
                    .duration(animation.duration)
                    .attrTween('d', arcTween)
            } else {
                path.attr('d', arc);
            }
        }

        animate(path);
    }

    return (
        <ChartComponent
            draw={(chart) => {
                draw(chart);
            }}
            update={update}
            isUpdateDisabled={isUpdateDisabled}
        />
    )
}


export const Marker = ({ height, radius, animationDuration, fill = 'black', stroke = 'none', textColor = 'black', texts, xPos, yPosEnd, delay }) => {
    let yPosStart = height - radius - 3;

    function draw(chart) {
        setTimeout(() => {
            let marker = chart.append('g')
                .attr('transform', `translate(${xPos}, ${yPosStart})`)
                .attr('opacity', 0);

            marker.transition()
                .duration(animationDuration)
                .attr('transform', `translate(${xPos}, ${yPosEnd})`)
                .attr('opacity', 1);

            marker.append('path')
                .attr('d', `M${radius},${height - yPosStart}L${radius},${height - yPosStart}`)
                .attr('stroke', fill)
                .attr('stroke-width', 3)
                .transition()
                .duration(animationDuration)
                .attr('d', `M${radius},${height - yPosEnd}L${radius},${radius * 2}`);

            marker.append('circle')
                .attr('cx', radius)
                .attr('cy', radius)
                .attr('r', radius)
                .attr('fill', fill)
                .attr('stroke', stroke)
                .attr('stroke-width', 3);

            texts.forEach((text, i) => {
                marker.append('text')
                    .attr('font-size', radius / 2)
                    .attr('x', radius / 3.5)
                    .attr('y', radius * (i + 0.75))
                    .attr('fill', textColor)
                    .text(text);
            })
        }, delay)
    }

    return (
        <ChartComponent
            draw={draw}
        />
    )
}

export const VerticalGridLines = ({ yScale, numberOfTicks, width, color = 'lightgrey', opacity = 0.25 }) => {
    function makeGridLines() {
        return d3.axisLeft(yScale).ticks(numberOfTicks).tickSize(-width).tickFormat("");
    }

    function draw(chart) {
        chart.append('g')
            .attr('stroke', color)
            .attr('opacity', opacity)
            .call(makeGridLines());
    }

    return (
        <ChartComponent
            draw={draw}
        />
    )
}

export const HorizontalGridLines = ({ xScale, numberOfTicks, height, color = 'lightgrey', opacity = 0.25 }) => {
    function makeGridLines() {
        return d3.axisBottom(xScale).ticks(numberOfTicks).tickSize(-height).tickFormat("");
    }

    function draw(chart) {
        chart.append('g')
            .attr('stroke', color)
            .attr('opacity', opacity)
            .attr('transform', `translate(0, ${height})`)
            .call(makeGridLines());
    }

    return (
        <ChartComponent
            draw={draw}
        />
    )
}

export const Legend = ({ data, colorFunction = d => {
    const color = d3.scaleOrdinal(d3.schemeCategory10);
    return color(d.name);
}  }) => {
    function getLegend(d, aD) { // Utility function to compute percentage.
        const sum = d3.sum(aD.map(function (v) { return v.value; }));
        const percentage = sum !== 0 ? d.value / sum : 0;
        return d3.format(".0%")(percentage);
    }

    function draw(chart) {
        // create table for legend.
        const legend = chart.append("table").attr('class', 'legend');

        // create one row per segment.
        const row = legend.append("tbody").selectAll("tr").data(data).enter().append("tr");

        // create the first column for each segment.
        row.append("td").append("svg").attr("width", '16').attr("height", '16').append("rect")
            .attr("width", '16').attr("height", '16').attr('fill', colorFunction)

        // create the second column for each segment.
        row.append("td").text(function (d) { return d.name; });

        // create the third column for each segment.
        row.append("td").attr("class", 'legendValue')
            .text(function (d) { return d3.format(",")(d.value); });

        // create the fourth column for each segment.
        row.append("td").attr("class", 'legendPerc')
            .text(function (d) { return getLegend(d, data); });

        
    }

    function update(chart) {
        let l = chart.select("tbody").selectAll("tr").data(data);

        // update the frequencies.
        l.select(".legendValue").text(function (d) { return d3.format(",")(d.value); });

        // update the percentage column.
        l.select(".legendPerc").text(function (d) { return getLegend(d, data); });
    }

    return (
        <ChartComponent
            draw={draw}
            update={update}
        />
    )
}

export const Histogram = ({ data, showText = true, barColor = 'black', xScale, yScale, height, onMouseOver = () => { }, onMouseOut = () => { } }) => {
    function draw(chart) {
        const bars = chart.selectAll(".Bar").data(data).enter()
            .append("g").attr("class", "Bar");

        //create the rectangles.
        bars.append("rect")
            .attr("x", function (d) { return xScale(d[0]); })
            .attr("y", function (d) { return yScale(d[1]); })
            .attr("width", xScale.bandwidth())
            .attr("height", function (d) { return height - yScale(d[1]); })
            .attr('fill', barColor)
            .on("mouseover", onMouseOver)
            .on("mouseout", onMouseOut);

        if (showText) {
            bars.append("text").text(function (d) { return d3.format(",")(d[1]) })
                .attr("x", function (d) { return xScale(d[0]) + xScale.bandwidth() / 2; })
                .attr("y", function (d) { return yScale(d[1]) - 5; })
                .attr("text-anchor", "middle");
        }
    }

    function update(chart) {
        // update the domain of the y-axis map to reflect change in frequencies.
        yScale.domain([0, d3.max(data, function (d) { return d[1]; })]);

        // Attach the new data to the bars.
        const bars = chart.selectAll(".Bar").data(data);

        // transition the height and color of rectangles.
        bars.select("rect").transition().duration(500)
            .attr("y", function (d) { return yScale(d[1]); })
            .attr("height", function (d) { return height - yScale(d[1]); })
            .attr("fill", barColor);

        // transition the frequency labels location and change value.
        bars.select("text").transition().duration(500)
            .text(function (d) { return d3.format(",")(d[1]) })
            .attr("y", function (d) { return yScale(d[1]) - 5; });
    }

    return (
        <ChartComponent
            draw={draw}
            update={update}
        />
    )
}

export const Funnel = ({
    data,
    options,
    height,
    width,
    curved,
    pinched,
    gradient,
    inverted,
    highlightOnHover,
    showToolTips,
    dynamicHeight,
    barOverlay,
    animationSpeed,
    onClick = () => { },
}) => {

    function draw(chart) {
        const funnel = new D3Funnel(chart);
        funnel.draw(data, {
            ...options,
            chart: {
                ...options.chart,
                curve: { enabled: curved },
                ...(pinched ? { bottomPinch: 1 } : {}),
                inverted,
                ...(animationSpeed ? { animate: animationSpeed } : {})
            },
            block: {
                ...options.block,
                ...(gradient ? {
                    fill: {
                        type: 'gradient',
                    }
                } : {}),
                highlight: highlightOnHover,
                dynamicHeight,
                barOverlay,

            },
            tooltip: {
                enabled: showToolTips
            },
            events: {
                click: {
                    block(d) {
                        onClick(d);
                    }
                }
            }
        });
    }

    function update(chart) {
        const funnel = new D3Funnel(chart);
        funnel.draw(data, {
            ...options,
            chart: {
                ...options.chart,
                curve: { enabled: curved },
                ...(pinched ? { bottomPinch: 1 } : {}),
                inverted,
                ...(animationSpeed ? { animate: animationSpeed } : {})
            },
            block: {
                ...options.block,
                ...(gradient ? {
                    fill: {
                        type: 'gradient',
                    }
                } : {}),
                highlight: highlightOnHover,
                dynamicHeight,
                barOverlay,

            },
            tooltip: {
                enabled: showToolTips
            },
            events: {
                click: {
                    block(d) {
                        onClick(d);
                    }
                }
            }
        });
    }

    return <ChartComponent style={{ height, width }} useRef draw={draw} update={update} />
}

export const DivergingBarChart = ({ data, width, xScale, yScale, topMargin, metric = 'relative' }) => {
    function draw(chart) {
        const format = d3.format(metric === "absolute" ? "+,d" : "+,.0%");
        const tickFormat = metric === "absolute" ? d3.formatPrefix("+.1", 1e6) : format;

        chart.append("g")
            .selectAll("rect")
            .data(data)
            .join("rect")
            .attr("fill", d => d3.schemeSet1[d.value > 0 ? 1 : 0])
            .attr("x", d => xScale(Math.min(d.value, 0)))
            .attr("y", (_, i) => yScale(i))
            .attr("width", d => Math.abs(xScale(d.value) - xScale(0)))
            .attr("height", yScale.bandwidth());

        chart.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .selectAll("text")
            .data(data)
            .join("text")
            .attr("text-anchor", d => d.value < 0 ? "end" : "start")
            .attr("x", d => xScale(d.value) + Math.sign(d.value - 0) * 4)
            .attr("y", (_, i) => yScale(i) + yScale.bandwidth() / 2)
            .attr("dy", "0.35em")
            .text(d => format(d.value));

        const xAxis = g => g.attr("transform", `translate(0,${topMargin})`)
            .call(d3.axisTop(xScale).ticks(width / 80).tickFormat(tickFormat))
            .call(g => g.select(".domain").remove())

        const yAxis = g => g.attr("transform", `translate(${xScale(0)},0)`)
            .call(d3.axisLeft(yScale).tickFormat(i => data[i].name).tickSize(0).tickPadding(6))
            .call(g => g.selectAll(".tick text").filter(i => data[i].value < 0)
                .attr("text-anchor", "start")
                .attr("x", 6))

        chart.append("g")
            .call(xAxis);

        chart.append("g")
            .call(yAxis);
    }

    return <ChartComponent draw={draw} />;
}
