import React from 'react';
import { List, Icon, Popup, Table } from 'semantic-ui-react';
import { UserAvatar, ButtonLink } from 'dyl-components';
import './index.scss';


const AvatarList = ({ user_id=null, usersLimit, usersList, showAllUsersList, toggleShowAll }) => {
    return(    
        <React.Fragment>
            <div className='UserAvatarListFlex'>
                <UserAvatar
                    user_id={user_id}
                    showAllUsersList={showAllUsersList}
                    usersList={usersList}
                    usersLimit={usersLimit}
                />
            </div>
            <div className='UserButtonAvatar'>
                {(usersList.length > usersLimit && <ButtonLink onClick={() =>  toggleShowAll() }>
                    <span style={{ fontWeight: "bold" }}>{`Show ${showAllUsersList  ? "less" : "more"}`}</span>
                </ButtonLink>)}
            </div>
        </React.Fragment>
    )
}
export default class UserAvatarList extends React.Component {
    state = {
        showAllUsersList: false
    }

    toggleShowAll = () => {
        this.setState(prevState => ({
            showAllUsersList : !prevState.showAllUsersList
        }));
    }

    render(){
        const { user_id, usersList, usersLimit } = this.props;
        return (
            <AvatarList
                user_id={user_id}
                showAllUsersList={this.state.showAllUsersList}
                usersList={usersList}
                usersLimit={usersLimit}
                toggleShowAll={this.toggleShowAll}
            />
        )
    }
}





