import React from 'react';
import { Breadcrumb, Statistic } from 'semantic-ui-react';

import './index.scss';

const TabStages = ({ isActive, onToggleStage, stages = [], size = 'small' }) => (
    <Breadcrumb
        className='StatisticStages'
        sections={stages.map(({ key, content }) => ({
            key,
            content,
            className: `StatisticStage ${isActive(key) && 'StatisticStage--active'}`,
            onClick: () => {
                onToggleStage(key)
            }
        }))}
        divider=''
        size={size}
    />
);

class TabStagesContainer extends React.Component {
    render() {
        return (
            <TabStages
                isActive={this.props.isStageActive}
                onToggleStage={this.props.onToggleStage}
                stages={this.props.stages.map(stage => ({
                    key: stage.id,
                    content: (
                        <Statistic size='mini'>
                            <Statistic.Label className='StatisticStage__name'>{stage.name}</Statistic.Label>
                            <Statistic.Value>{stage.count}</Statistic.Value>
                        </Statistic>
                    )
                }))}
            />
        )
    }
}

export default TabStagesContainer;
