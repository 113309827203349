import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, Icon, Label, Menu, Popup, Input, Ref } from 'semantic-ui-react';

import './index.scss';

const MultipleToggableDropdown = ({
    onChange,
    nested_options,
    loading,

    values,
    placeholder,
    onHoverParent,
    searchValue,
    onSearchChange
}) => {
    const [open, setOpen] = useState(false);
    const [categoryBeingHovered, setCategoryBeingHovered] = useState(null);
    const labelsRef = useRef(null);
    const inputRef = useRef(null);
    const [labelsHeight, setLabelsHeight] = useState(0);

    const getLabelsHeight = () => {
        if (labelsRef.current) {
            const height = labelsRef.current.offsetHeight;
            setLabelsHeight(height);
        }
    }

    const isSelected = (id) => {
        return values.find(({ value, id: valueId }) => value ? id === value : id === valueId)
    };

    const blurInput = () => {
        if (inputRef.current) {
            const childNodes = inputRef.current.childNodes;
            let inputNode;
            for (let i = 0; i < childNodes.length; i++) {
                if (childNodes[i].nodeName === 'INPUT') {
                    inputNode = childNodes[i];
                    break;
                }
            }
            inputNode.blur();
        }
    }

    useEffect(() => {
        getLabelsHeight();
    })

    return (
        <div style={{ position: 'relative', minWidth: 250 }}>
            <Dropdown
                style={{ height: values.length > 0 ? labelsHeight + 30 : 35 }}
                scrolling
                className='MultipleToggableDropdown'
                onClose={() => { setOpen(false); setCategoryBeingHovered(null) }}
                value={values}
                open={open}
                loading={loading}
                multiple
                icon={<Icon size='small' className='fa-sharp fa-solid fa-caret-down NestedDropdown__icon' />}
                closeOnBlur={false}
            >
                <Dropdown.Menu
                    style={{ maxHeight: 150, overflowY: 'auto' }}
                    onScroll={() => {
                        if (categoryBeingHovered !== null) {
                            setCategoryBeingHovered(null);
                        }
                    }}
                >
                    {nested_options.filter(option => !searchValue?.trim() || option.text.toLowerCase().includes(searchValue?.toLowerCase())).map(category => {
                        const isCategorySelected = isSelected(category.value);

                        return (searchValue ? (
                            <Dropdown.Item
                                text={category.text}
                                value={category.value}
                                key={category.key}
                                disabled={isCategorySelected}
                                onMouseDown={(e) => {
                                    e.preventDefault();
                                    if (!values.includes(category.value)) {
                                        onChange(e, { value: [...values, { value: category.value, name: category.parent ? `${category.parent}/${category.text}` : category.text }] });
                                        setOpen(false);
                                        blurInput();
                                    }
                                }}
                            />
                        ) : (
                            <Popup
                                className='MultipleToggableDropdown__child-options'
                                style={{ position: 'absolute', top: '-1em', right: '-5em', maxHeight: 150 }}
                                position='right center'
                                trigger={(
                                    <Dropdown.Item
                                        onMouseDown={e => {
                                            e.stopPropagation();
                                            if (!isCategorySelected && !values.includes(category.value)) {
                                                onChange(e, { value: [...values, { value: category.value, name: category.text }] });
                                                setCategoryBeingHovered(null);
                                                setOpen(false);
                                            }
                                        }}
                                        selected={categoryBeingHovered === category.key}
                                        key={category.key}
                                        disabled={category.hasOptions ? false : isCategorySelected}
                                    >
                                        {category.hasOptions ? (
                                            <Dropdown
                                                simple
                                                fluid
                                                key={category.key}
                                                text={category.text}
                                                open={false}
                                                loading={category.loading}
                                                disabled={isCategorySelected}
                                            />
                                        ) : category.text}
                                    </Dropdown.Item>
                                )}
                                open={!category.disabled && categoryBeingHovered === category.key}
                                onOpen={() => {
                                    if (!category.disabled) {
                                        setCategoryBeingHovered(category.key);
                                    }
                                    if (onHoverParent) {
                                        onHoverParent(category.key);
                                    }
                                }}
                                {...(category?.options?.length ? {
                                    content: (
                                        <Menu secondary borderless vertical>
                                            {(category.options || []).map(option => {
                                                const isOptionSelected = isSelected(option.value);

                                                return (
                                                    <Menu.Item
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                        onMouseDown={(e) => {
                                                            e.stopPropagation();
                                                            if (!isOptionSelected && !values.includes(option.value)) {
                                                                onChange(e, { value: [...values, { value: option.value, name: `${category.text}/${option.text}` }] });
                                                                setCategoryBeingHovered(null);
                                                                setOpen(false);
                                                            }
                                                        }} key={option.id} disabled={isOptionSelected}>
                                                        {option.text}
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu>
                                    )
                                } : {})}
                            />
                        ))
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <Ref innerRef={inputRef}>
                <Input
                    className="ToggableDropdown__input"
                    iconPosition='left'
                    placeholder={placeholder}
                    value={searchValue}
                    onChange={onSearchChange}
                    onFocus={() => setOpen(true)}
                    onBlur={() => {
                        setCategoryBeingHovered(null);
                        setOpen(false);
                    }}
                />
            </Ref>
            <Icon name='search plus' className='ToggableDropdown__inputIcon' style={{ top: values.length > 0 ? labelsHeight + 5 : 10 }} />
            <div ref={labelsRef} className="ToggableDropdown__labelsContainer">
                {values.map((value, idx) => {
                    return (
                        <Label key={idx} className="ToggableDropdown__label">
                            {value.name}
                            <Icon style={{ marginLeft: '0px 0px 0px 5px' }} className='fas fa-times' link onClick={(e) => {
                                onChange(e, { value: values.filter((selected) => selected !== value) })
                                getLabelsHeight();
                            }}
                            />
                        </Label>
                    )
                })}
            </div>
        </div>
    );
}

export default MultipleToggableDropdown;
