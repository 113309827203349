import React from 'react';
import { Icon, Segment } from 'semantic-ui-react';

import './index.scss';

export const AttachableItem = ({
    active,
    label,
    onToggle,
    size = 'small',
    disabled = false,
    description='',
}) => (
    <Segment textAlign='left' {...(size ? { size } : {})} disabled={disabled}>
        <strong style={{display: 'block'}}>{label} <Icon link size='large' disabled={disabled} style={{ float: 'right' }} onClick={onToggle} className={`fas fa-${active ? 'minus' : 'plus'}`} /></strong>
        <i className='AttachableItem__Description'>{description}</i>
    </Segment>
)
