import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { NumberedContainer, AndOrToggle } from 'dyl-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './index.scss';

const DragAndDrop = ({ children, parentSegments = [] }) => {

  return (
    <DragDropContext>
      <Droppable droppableId="numbered-segments">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {Array.from({ length: parentSegments }).map((segment, index) => (
              <Draggable key={index} draggableId={`segment-${index}`} index={index}>
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    {children}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDrop;
