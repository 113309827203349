import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { RecordType } from 'dyl-components';

import './index.scss';

const BadgeComponent = ({ type = 'Contact', record_type = 'person' }) => (
    <Grid columns='equal' verticalAlign='middle'>
        <Grid.Column>
            <div style={{ display: "inline-block", paddingRight: "1.5em" }}>
                <RecordType record_type={record_type} />
            </div>
            <div style={{ display: "inline-block" }}>
                <div className='RecordType__label'>
                    <span>
                        Stage
                </span>
                </div>
                <div className='RecordType__type'>
                    <Icon name='address book outline' color='grey' className='RecordType__icon' />
                    <span>
                        {type}
                    </span>
                </div>
            </div>
        </Grid.Column>
    </Grid>
);

export default BadgeComponent;
