import React from 'react';
import { Icon } from 'semantic-ui-react';

const CloseableContent = ({ closeIcon = true, ...props }) => (
    <React.Fragment>
        {React.Children.map(props.children, child => (
            React.cloneElement(child, { style: { position: 'relative', ...child.props.style }, className: props.className })
        ))}
        <div className={props.className} style={{ position: "absolute", top: '1.2em', right: '0.6em', ...props.style }}>
            {!props.uncloseable && (
                closeIcon ? <Icon name='times' link onClick={props.onClose} /> : null
            )}
        </div>
    </React.Fragment>
)

export default CloseableContent;
