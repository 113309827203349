import React from 'react';
import { Table } from 'dyl-components';
import { Table as SemanticTable } from 'semantic-ui-react';

export default function CalendarTableHeader() {
    return (
        <Table.CollapsibleRow isHeader>
            <SemanticTable.HeaderCell>Date</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>Activity Type</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>Task/Event Name and Label</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell>Note</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell style={{"text-align":"center"}}>Contacts</SemanticTable.HeaderCell>
            <SemanticTable.HeaderCell style={{"text-align":"center"}}>Actions</SemanticTable.HeaderCell>
        </Table.CollapsibleRow>
    );
}