import React from 'react';
import { BlueCheckBox } from 'dyl-components';
import { Grid, GridColumn } from 'semantic-ui-react';
import './index.scss';



class CheckboxControlSettings extends React.Component {
    state = {
        permissions: this.props.permissions,
        outlierPermissions: this.props.outlierPermissions,
        allSelected: this.props.allSelected
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.permissions !== this.props.permissions) {
            this.checkOtherDependencies(this.state.permissions, this.props.allSelected)
        }

    }

    componentDidMount(){
        this.checkOtherDependencies(this.state.permissions, this.props.allSelected) 
    }

    onClick = (_, type, disabledBox = false) => {

        if (disabledBox) { return; }
        const { permissions } = this.state;

        let checkPermission = permissions.find(permission => permission.type === type);
        let previousChecked = !checkPermission.checked;
        checkPermission.checked = previousChecked;
        
        let addPermission = permissions.map(checkedPermission => permissions.find(permissions => permissions.type === checkedPermission.type)) || [];
        this.checkSelectedDependencies(type, permissions, addPermission, checkPermission.checked );
        this.setState({ permissions: addPermission });

        const {groupID, countSelectedGroupBoxes} = this.props;
        countSelectedGroupBoxes(groupID);        
    }

    checkSelectedDependencies = (type, permissions, addPermission, toggleValue = false) => {

        let checkPermission = permissions.find(permission => permission.type === type);

        if(toggleValue){
            //autoSelect predecessors  SHOULD probably be in the BE json file 
            const defaultChecks = {
                "assign" :["read"],
                "create": ["read", "assign"],
                "update": ["read", "assign"],
                "delete": ["read", "assign", "create", "update"],
                "activatedeactivate": ["read", "assign", "create", "update"]
            };

            const getAutoSelectedTypes = () => {
                let autoCheckedTypes = defaultChecks[type.split('.').pop()] || [];
                let section = type.split('.').slice(0 ,-1).join('.');
                let autoCheckedList = autoCheckedTypes.map(item => {
                    return `${section}.${item}`
                })

                //loop through the permission and check items in the autoCheckList
                addPermission.map(item => {
                    if(autoCheckedList.includes(item.type)){
                        item.checked = true;
                    }
                });
            }

            getAutoSelectedTypes();

        }

        if (checkPermission.requires) {

            for (const [idx, required] of checkPermission.requires.entries()) {

                let checkPerPermission = permissions.find(permission => permission.type === required);

                if (checkPerPermission === undefined) { continue; }

                if (toggleValue) {
                    checkPerPermission.checked = true;
                    checkPerPermission.disabled = true;
                } else {
                    checkPerPermission.disabled = false;
                }

            }

            return;
        }

        this.checkOtherDependencies(addPermission, toggleValue);
    }

 

    checkOtherDependencies(permissions, toggleValue) {

        let rawreqs = [], required;

        for (const [idx, permission] of permissions.entries()) {
            if (permission.checked && permission.requires) {
                rawreqs.push(...permission.requires)
            }
        }

        required = [...new Set(rawreqs)];

        for (const [idx, permission] of permissions.entries()) {

            permissions[idx].disabled = false;
            if (required.includes(permission.type) && permission.disabled === false) {
                permissions[idx].disabled = true;
                permissions[idx].checked = true;
            }
        }

        const getDefaultType = (type) =>{
            return type.split('.').pop();
        }
        const uncheckedBoxes =[], checkedBoxes =[], checkedTypes =[], uncheckedTypes =[]; 
        
        permissions.map(permission => {
            if(permission.checked) {
                checkedBoxes.push(permission);
                checkedTypes.push(getDefaultType(permission.type));
            } else {
                uncheckedBoxes.push(permission);
                uncheckedTypes.push(getDefaultType(permission.type));
            }
        });
        
        this.setState({ permissions });
    }


    render() {
        const { permissionOrder, countSelectedGroupBoxes, groupID, allSelected } = this.props;
        const { permissions } = this.state;
        const {disabled } = this.props;
        const totalPermissions = permissions.length || 0 ;
        let currentPermission = 0;
    
        return (
            <Grid columns='equal'>
                <Grid.Row>
                    {(permissionOrder && Object.values(permissionOrder)) &&
                    
                    Object.values(permissionOrder).map((orderName, idx) => {

                        let hasPermission = permissions.find((one) => { return one.name === orderName || null });
                        const disabledBox = (disabled) ? disabled : hasPermission?.disabled || false;
                        if (hasPermission?.name) { currentPermission++ };
                        return ((hasPermission?.name) ?

                            <Grid.Column width={2} key={hasPermission.type}>
                                <BlueCheckBox
                                    id={hasPermission.type}
                                    topLabel={hasPermission.name}
                                    disabled={disabledBox}
                                    onClick={(e) => {
                                        this.onClick(e, hasPermission.type, disabledBox);
                                        countSelectedGroupBoxes}}
                                    checked={hasPermission.checked}
                                />
                            </Grid.Column>
                            :
                            <Grid.Column width={2} key={idx} />

                        )
                    }
                    )}


                    {(currentPermission < totalPermissions) &&

                        permissions.slice(currentPermission).map((permission, idx) => {
                            let currentConfigId = currentPermission + idx;
                            const disabledBox  = (disabled) ? disabled : permissions[currentConfigId]?.disabled || false;
                            return (
                                <Grid.Column width={3} key={permissions[currentConfigId].type}>
                                    <BlueCheckBox
                                        id={permissions[currentConfigId].type}
                                        topLabel={permissions[currentConfigId].name}
                                        disabled={disabledBox}
                                        onClick={(e) => this.onClick(e, permissions[currentConfigId].type, disabledBox)}
                                        checked={permissions[currentConfigId].checked}
                                    />
                                </Grid.Column>
                            )
                        })
                    }

                </Grid.Row>
            </Grid>
        );
    }
}

export default CheckboxControlSettings;
