import React from 'react';
import { Form, Icon } from 'semantic-ui-react';

const MultipleField = ({ items, name, placeholder, onChange, onRemove, onAdd, hasMain, onToggleMain }) => (
    items.map(({ id, value, main }, index) => (
        <Form.Group key={id} >
            <Form.Input
                onChange={(e, { value }) => { onChange(e, { name, value, id }) }}
                inline
                placeholder={placeholder}
                value={value}
                action={{
                    onClick: (e) => { onAdd(e, { name }) },
                    icon: <Icon name='plus circle' color='blue' />,
                    basic: true,
                    size: 'mini',
                    type: 'button'
                }}
                width={!hasMain && index === 0 ? 16 : 12}
            />
            <Form.Group grouped>
                {hasMain && <Form.Checkbox
                    onChange={(e) => { onToggleMain(e, { name, id }) }}
                    label='Main'
                    checked={main}
                />}
                {index !== 0 && <Form.Field>
                    <Icon
                        link
                        name='times'
                        color='red'
                        onClick={(e) => { onRemove(e, { name, id }) }}
                    /> Delete
                    </Form.Field>}
            </Form.Group>
        </Form.Group>
    ))
);

export default MultipleField;
