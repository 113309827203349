import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

import Icons from '../../constants/Icons';

import './index.scss';

export const StatusLabel = ({ type, text }) => {
    return (
        <Header className={`Label StatusLabel--${type}`}>
            <Icon name={Icons[type]} />
            {text}
        </Header>
    )
}

export const MessageLabel = ({ header, content, time }) => (
    <div className='Label MessageLabel'>
        <div>{header} <span className='MessageLabel__time'>{time}</span></div>
        <div>{content}</div>
    </div>
)
