import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Grid, Button, Header, Popup, Segment, Icon } from 'semantic-ui-react';


import './index.scss';

const ColorPickerPanel = ({ color, onChange, onAdd, onCancel }) => {
    return (
        <React.Fragment>
            <Grid>
                <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column>
                        <Header size='small' content='COLOR PICKER' textAlign='center' />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={'equal'} >
                    <Grid.Column>
                        <ChromePicker color={color} disableAlpha onChange={onChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button content='Cancel' basic onClick={onCancel} />
                        <Button content='Add' primary onClick={onAdd} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>


    )
}

const ColorPicker = ({ color = '#FFFFFF', onAdd = () => { } }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [currentColor, setCurrentColor] = useState(color);

    const onChange = (color, _) => {
        setCurrentColor(color.hex);
    }

    const onAddColor = () => {
        if (onAdd) {
            onAdd(currentColor);
        }
        setIsOpen(false);
    }

    return (
        <Popup
            trigger={
                <Segment className='ColorPicker' >
                    <Icon name='fa-solid fa-eye-dropper' />
                </Segment>
            }
            content={
                <ColorPickerPanel color={currentColor} onAdd={onAddColor} onChange={onChange} onCancel={() => { setIsOpen(false); setCurrentColor("#FFFFFF") }} />
            }
            basic
            on='click'
            open={isOpen}
            onOpen={() => { setIsOpen(true) }}
            onClose={() => { setIsOpen(false) }}
        />
    )
}

export default ColorPicker;
