import React from 'react';
import { Segment } from 'semantic-ui-react';

import './index.scss';

const RuleContainer = ({ content, isActive = true, onClick = () => {} }) => {

    return (
        <Segment onClick={onClick} basic className={`Rule__Container${isActive ? ' Rule__Container--active' : ''}`}>
            {content}
        </Segment>
    );
}

export default RuleContainer;
