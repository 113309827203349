import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

import Avatar from 'react-avatar';

import './index.scss';

const Person = ({ username, subtitleLabel = 'Email', email, removable, onRemove, popup = true, isUsernameOnly = false, unsetFontSize = false }) => {
    const details = (
        <div className='Person'>
            {username && <Avatar name={username} maxInitials={2} round size='3em' className={unsetFontSize && "Person__avatar--unsetFontSize"}/>}
            <div className={`Person__information${isUsernameOnly ? ' Person__information--single-line' : ''}`}>
                {username && <div><span className="Person__name"><b>{username}</b></span></div>}
                {!isUsernameOnly && <div><span className="Person__email">{email}</span></div>}
            </div>
            {removable && (
                <div className="Person__remove">
                    <Icon
                        name='delete'
                        link
                        onClick={onRemove}
                    />
                </div>
            )}
        </div>
    )
    return (
        popup ? (
            <Popup
                inverted
                content={(
                    <div>
                        <p>Name: {username}</p>
                        {!isUsernameOnly && <p>{subtitleLabel}: {email}</p>}
                    </div>
                )}
                trigger={(details)}
            />
        ) : details
    )
};

export default Person;
