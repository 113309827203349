import React from 'react';
import { GridTimeline } from 'dyl-components';

import './index.scss';



const TimelineTabDisplay = ({ itemComponent, titles, expanded, dateIcon1, dateIcon2, selectedRow }) => {
    return(
        <div className='TimelineTabDisplay__Timeline' >
            <GridTimeline
                titles={titles}
                dateIcon1={dateIcon1}
                dateIcon2={dateIcon2}
                items={itemComponent}
                selectedRow={selectedRow}
                expanded={expanded}
            />
        </div>
    )
};

export default TimelineTabDisplay;