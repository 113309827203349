import React from 'react';
import { Form } from 'semantic-ui-react';

const HouseholdContactForm = ({
    name,
    title,
    main,
    main_link,
    role,
    role_options = [
        { key: 'none', value: 'none', text: 'None' },
        { key: 'decision_maker', value: 'decision_maker', text: 'Decision Maker' },
        { key: 'influencer', value: 'influencer', text: 'Influencer' }
    ],
    contact_options,

    onChange
}) => (
    <React.Fragment>
        <Form.Group widths="equal">
            <Form.Input
                name="name"
                value={name}
                onChange={onChange}
                label="Household Contact Name"
                placeholder="Enter household name"
            />
            <Form.Input
                name="title"
                value={title}
                onChange={onChange}
                label="Title"
                placeholder="Enter household type"
            />
            <Form.Group grouped>
                <Form.Checkbox
                    name='main'
                    checked={main}
                    label="Set as Main Contact"
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                />
            </Form.Group>
        </Form.Group>
        <Form.Group widths="equal">
            <Form.Select
                name="role"
                value={role}
                onChange={onChange}
                label="Role of Contact"
                options={role_options}
                placeholder="Select role"
            />
            <Form.Select
                name="existing_contact"
                value={role}
                onChange={onChange}
                label="Link a Contact to this Household"
                options={contact_options}
                placeholder="Search contacts"
                search
            />
            <Form.Group grouped>
                <Form.Checkbox
                    name='main_link'
                    checked={main_link}
                    label="Set as Main Contact"
                    onChange={(e, { name, checked }) => { onChange(e, { name, value: checked }) }}
                />
            </Form.Group>
        </Form.Group>
    </React.Fragment>
);

export default HouseholdContactForm;
