import React from 'react';

const GridListBody = ({ children }) => (
    <React.Fragment>
        {children}
    </React.Fragment>
);

export default GridListBody;


