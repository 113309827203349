import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

import MessageInput from './MessageInput';
import ChatFeed from './Feed';
import ChatHeader from "./ChatHeader";

import './index.scss';
export class Messages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            stream: props.messages.channelFeed[0].feed
        }

        this.sendMessage = this.sendMessage.bind(this);
    }

    sendMessage = (e) => {
        const msg = {
            "author": "Current User",
            "message": e,
            "timestamp": Date.now(),
            "avatar": "https://robohash.org/optiomaioresin.jpg?size=50x50&set=set1"
        };

        this.setState(prevState => ({
            stream: [...prevState.stream, msg]
        }));

    }

    render() {

        const chatWindowData = this.props.messages.channelFeed[0];
        const header = {
            channelName: chatWindowData.channelName,
            isFavorite: chatWindowData.isFavorite,
            hasUnreadMsgs: chatWindowData.hasUnreadMsgs,
            onToggleFavorites: chatWindowData.onToggleFavorites,
            onClickNotiifications: chatWindowData.onClickNotiifications
        }

        return (
            <Segment className="Chat__Window">

                <ChatHeader headerData={header} />

                <ChatFeed
                    stream={this.state.stream}
                    color={this.props.messages.defaultAvatarColor} />

                <MessageInput
                    sendMessage={this.sendMessage}
                    channelName={header.channelName}
                />

            </Segment>
        );
    }
}
