import React from 'react';
import { Card, Segment } from 'semantic-ui-react';

import './index.scss';

const ItemCard = ({ meta, title, description, bordered = false, size = 'tiny', actions = null }) => (
    <>
        <Card fluid className={`ItemCard ${!bordered && 'ItemCard--no-border'}`} as={Segment} size={size}>
            <Card.Content>
                <Card.Meta>{meta}</Card.Meta>
                <Card.Header as='h4' className='ItemCard__title'>{title}</Card.Header>
                <Card.Description className='ItemCard__description'>{description}</Card.Description>
                {actions}
            </Card.Content>
        </Card>
    </>
);

export default ItemCard;
