import React from 'react';
import { Modal, Notification, STATUS_TYPES } from 'dyl-components';
import { Loader, Button } from 'semantic-ui-react';
import * as XLSX from 'xlsx';

import './index.scss';

const CSVViewer = ({ title = '', csv, isPreviewing = false, isReadingCSV = false, onClosePreview = () => { } }) => {
    const buildTable = () => {
        let entries = [];

        const getHeaders = () => {
            return entries[0];
        }

        const getEntries = () => {
            return entries.slice(1);
        }

        const createTableRow = (row) => {
            const cells = [];
            row.forEach((column, index) => {
                cells.push(<td key={index}>{column}</td>)
            });
            for (let x = 1; x <= getHeaders().length - row.length; x++) {
                cells.push(<td></td>)
            }

            return cells;
        }

        if (csv) {
            const sheet = csv.Sheets[csv.SheetNames]
            entries = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false });
        }

        return (
            isReadingCSV ? <Loader active /> : entries.length ? (
                <table className='CSVViewer__table'>
                    <thead>
                        <tr>
                            {entries[0].map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {getEntries().map(row => (
                            <tr key={row[0]}>
                                {createTableRow(row)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : null
        );
    }

    const downloadCSV = () => {
        try {
            XLSX.writeFile(csv, title);
        } catch (error) {
            console.log(error);
            Notification.alert('Failed to download CSV file', STATUS_TYPES.ERROR, true);
        }
    }

    return (
        <React.Fragment>
            <Modal open={isPreviewing} onClose={onClosePreview} className={`CSVViewer CSVViewer${isReadingCSV || !csv ? '--no-result' : ''}`}>
                <Modal.Header>
                    {title}
                </Modal.Header>
                <Modal.Content>
                    {buildTable()}
                </Modal.Content>
                <Modal.Actions>
                    <Button primary disabled={isReadingCSV || !csv} onClick={downloadCSV}>Download</Button>
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    )
}

export default CSVViewer;
