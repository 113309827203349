import React from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, Dropdown, Grid } from "semantic-ui-react";

export const PowerSelector = ({ label, name, multiple, options }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={`filters[${name}]`}
            render={({ field: { name, value, onChange } }) => (
                <Dropdown
                    text={label}
                    button
                    value={value}
                    selectOnBlur={false}
                    onChange={(_, { value }) => {
                        onChange({ target: { name, value } });
                    }}
                    style={{ marginBottom: "1em" }}
                >
                    <Dropdown.Menu>
                        {[
                            ...(multiple
                                ? (() => {
                                      const allSelected =
                                          !Boolean(value) ||
                                          options.every((option) =>
                                              value?.includes(option.value)
                                          );
                                      return [
                                          {
                                              key: "all",
                                              value: "all",
                                              text: "All",
                                              onClick: () => {
                                                  onChange({
                                                      target: {
                                                          name,
                                                          value: allSelected
                                                              ? []
                                                              : options.map(
                                                                    (option) =>
                                                                        option.value
                                                                ),
                                                      },
                                                  });
                                              },
                                              content: (
                                                  <Grid
                                                      verticalAlign="middle"
                                                      columns={"equal"}
                                                  >
                                                      <Grid.Column width={1}>
                                                          <Checkbox
                                                              checked={
                                                                  allSelected
                                                              }
                                                          />
                                                      </Grid.Column>
                                                      <Grid.Column>
                                                          All
                                                      </Grid.Column>
                                                  </Grid>
                                              ),
                                          },
                                      ];
                                  })()
                                : []),
                            ...options.map((option) => ({
                                ...option,
                                selected: multiple
                                    ? value?.includes(option.value)
                                    : value === option.value,
                                onClick: () => {
                                    onChange({
                                        target: {
                                            name,
                                            value: multiple
                                                ? value?.includes(option.value)
                                                    ? value.filter(
                                                          (selected) =>
                                                              selected !==
                                                              option.value
                                                      )
                                                    : [...value, option.value]
                                                : option.value,
                                        },
                                    });
                                },
                                ...(multiple
                                    ? {
                                          content: (
                                              <Grid columns={"equal"}>
                                                  <Grid.Column
                                                      verticalAlign="middle"
                                                      width={1}
                                                  >
                                                      <Checkbox
                                                          checked={value?.includes(
                                                              option.value
                                                          )}
                                                      />
                                                  </Grid.Column>
                                                  <Grid.Column>
                                                      {option.content ||
                                                          option.text}
                                                  </Grid.Column>
                                              </Grid>
                                          ),
                                      }
                                    : {}),
                            })),
                        ].map((option) => (
                            <Dropdown.Item {...option} />
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        />
    );
};
